import React from "react";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import EmployeeIdInput from "./employeeIdInput";

import { formatUTCDate } from "../helpers";

class CreateTimesheetModal extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [function] renews token
	show: [bool] whether modal is showing or not
	handleClose: [function] callback on close

	STATE:
	loading: [Bool] whether or not loading is occuring
	success: [String] success message if any
	error: [String] error message if any
	employeeId: [Int] employeeId
	date: [Date Time] should always be a date with T00:00:00.000Z

	*/

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      success: null,
      error: null,
      employeeId: null,
      date: "",
    };

    this.handleChangeEmployeeId = this.handleChangeEmployeeId.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeEmployeeId(employeeId) {
    this.setState({
      employeeId: employeeId,
    });
  }

  handleChangeDate(e) {
    this.setState({
      error: null,
    });
    if (e.target.valueAsDate?.getUTCDay() !== 5) {
      //user didn't select a friday
      this.setState({
        error: "You must select a Friday",
      });
    } else {
      this.setState({
        date: e.target.valueAsDate,
      });
    }
  }

  handleClose() {
    this.setState(
      {
        loading: false,
        sucess: null,
        error: null,
        employeeId: null,
      },
      this.props.handleClose()
    );
  }

  async handleSubmit() {
    this.setState({
      loading: true,
      error: null,
    });

    try {
      let result = await this.createNewTimesheet();
      if (result.status === 401) {
        await this.props.renewAuthenticationToken();
        result = await this.createNewTimesheet();
      }
      switch (result.status) {
        case 201:
          //happy path
          this.setState(
            {
              loading: false,
              success: `Timesheet ${result.data.id} created!`,
            },
            () => {
              setTimeout(this.handleClose, 1500);
            }
          );
          break;

        case 400:
        case 404:
          console.log(result.data.error.message);
          this.setState({
            loading: false,
            error: result.data.error.message,
          });
          break;

        default:
          console.log(result.data.error.message);
          this.setState({
            loading: false,
            error: "An unexpected error occurred",
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
        loading: false,
      });
    }
  }

  async createNewTimesheet() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify({
        weekEndingDateTime: this.state.date.toISOString(),
        employeeId: this.state.employeeId,
      }),
    };

    const url = `${process.env.REACT_APP_BASE_URL}/v2/timesheets`;
    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error ocurred",
      });
    }
  }

  disableSubmit() {
    //returns true or false
    //controls whether the submit button is disabled or not (true is disabled)
    if (
      !this.state.employeeId ||
      this.state.loading ||
      this.state.date === ""
    ) {
      return true;
    }
    if (this.state.date?.getUTCDay() !== 5) {
      return true;
    }
    return false;
  }

  render() {
    const modalProps = {
      size: "md",
    };
    if (this.state.loading) {
      modalProps.backdrop = "static";
      modalProps.keyboard = false;
    }
    return (
      <Modal {...modalProps} show={this.props.show} onHide={this.handleClose}>
        <Modal.Header closeButton={!this.state.loading}>
          <Modal.Title>New Timesheet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={this.state.success}>
            {this.state.success}
          </Alert>
          <Alert variant="danger" show={this.state.error}>
            {this.state.error}
          </Alert>
          <EmployeeIdInput
            renewAuthenticationToken={this.props.renewAuthenticationToken}
            handleEmployeeIdConfirmed={this.handleChangeEmployeeId}
            asCol={false}
            employeeId={null}
            tSheets={false}
          />
          <Form.Group>
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              value={formatUTCDate(this.state.date, false)}
              onChange={this.handleChangeDate}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.handleClose}
            disabled={this.state.loading}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={this.handleSubmit}
            disabled={this.disableSubmit()}
          >
            {this.state.loading ? "Loading..." : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CreateTimesheetModal;
