import React from "react";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

import InfiniteScroll from "react-infinite-scroll-component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import MaterialSaleListItem from "./materialSaleListItem";
import InventoryItemSelectorModal from "./inventoryItemSelectorModal";
import ReceptionSignatureModal from "./receptionSignatureModal";
import getWarehouses from "../warehouses";

const LIST_LIMIT = 25; //number to retrieve per query
const WAIT_INTERVAL = 300; //ms to wait between queries

class MaterialSaleList extends React.Component {
  /*

	PROPS:
	renewAuthenticationToken: [Function] renews token

	STATE:
	error:[String] error message if any
	loading: [Bool] whether loading is occurring
	customerName: [String] customer name in form filters
	customerPO: [String] customer PO in form filters
	jobName: [String] jobname in form filters
	warehouse: [Int] warehosue location selected filter
	checked: [Bool] whether or not material sale has been checked
	received: [Bool] received checkbox in form filters
	items: [Array [Object]] array of material sales
	nextPage: [Int] index for what the next page of the paginated list to load is (indexes from 0)
	hasMore: [Bool] whether or not there are more sales to be loaded
	inventorySelectedCallback: [Function] function to call when inventory selection modal has confirmed
	receivedCallback: [Function] callback when received flow has completed
	receiveModalNumber: [Int] sale number of order the receiving modal applies to

	TODO:
	*/

  constructor(props) {
    super(props);

    this.timer = null; //used for delaying queries

    this.state = {
      error: null,
      loading: false,
      customerName: "",
      customerPO: "",
      jobName: "",
      warehouse: -1,
      checked: false,
      received: false,
      items: [],
      nextPage: 0,
      hasMore: true,
      inventorySelectedCallback: null,
      receivedCallback: null,
      receiveModalNumber: null,
    };

    this.handleChangeCustomerName = this.handleChangeCustomerName.bind(this);
    this.handleChangeCustomerPO = this.handleChangeCustomerPO.bind(this);
    this.handleChangeJobName = this.handleChangeJobName.bind(this);
    this.handleChangeWarehouse = this.handleChangeWarehouse.bind(this);
    this.handleChangeChecked = this.handleChangeChecked.bind(this);
    this.handleChangeReceived = this.handleChangeReceived.bind(this);
    this.handleFetchMaterialSales = this.handleFetchMaterialSales.bind(this);
    this.handleFetchMoreMaterialSales =
      this.handleFetchMoreMaterialSales.bind(this);
    this.handleReceivedModalClose = this.handleReceivedModalClose.bind(this);
    this.setReceivedModal = this.setReceivedModal.bind(this);

    this.warehouses = getWarehouses();
  }

  componentDidMount() {
    this.handleFetchMaterialSales();
  }

  handleChangeCustomerName(e) {
    clearTimeout(this.timer);

    this.setState(
      {
        customerName: e.target.value,
        nextPage: 0,
      },
      () => {
        this.timer = setTimeout(this.handleFetchMaterialSales, WAIT_INTERVAL);
      }
    );
  }

  handleChangeCustomerPO(e) {
    clearTimeout(this.timer);

    this.setState(
      {
        customerPO: e.target.value,
        nextPage: 0,
      },
      () => {
        this.timer = setTimeout(this.handleFetchMaterialSales, WAIT_INTERVAL);
      }
    );
  }

  handleChangeJobName(e) {
    clearTimeout(this.timer);

    this.setState(
      {
        jobName: e.target.value,
        nextPage: 0,
      },
      () => {
        this.timer = setTimeout(this.handleFetchMaterialSales, WAIT_INTERVAL);
      }
    );
  }

  handleChangeWarehouse(e) {
    clearTimeout(this.timer);

    this.setState(
      {
        warehouse: e.target.value,
        nextPage: 0,
      },
      () => {
        this.timer = setTimeout(this.handleFetchMaterialSales, WAIT_INTERVAL);
      }
    );
  }

  handleChangeChecked(e) {
    clearTimeout(this.timer);

    this.setState(
      {
        checked: e.target.checked,
        nextPage: 0,
      },
      () => {
        this.timer = setTimeout(this.handleFetchMaterialSales, WAIT_INTERVAL);
      }
    );
  }

  handleChangeReceived(e) {
    clearTimeout(this.timer);

    this.setState(
      {
        received: e.target.checked,
        nextPage: 0,
      },
      () => {
        this.timer = setTimeout(this.handleFetchMaterialSales, WAIT_INTERVAL);
      }
    );
  }

  async handleFetchMaterialSales() {
    this.setState({
      error: null,
      loading: true,
    });

    try {
      let response = await this.fetchMaterialSales();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchMaterialSales();
      }

      switch (response.status) {
        case 200:
          //happy path
          this.setState({
            items: response.data.materialSales,
            hasMore: response.data.more,
            nextPage: 1,
            loading: false,
          });
          break;
        case 404:
          this.setState({
            hasMore: false,
            loading: false,
            items: [],
            error: response.data.error.message,
          });
          break;
        default:
          console.log(response.data?.error?.message);
          this.setState({
            hasMore: false,
            loading: false,
            items: [],
            error: "An unexpected error occurred",
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        hasMore: false,
        loading: false,
        items: [],
        error: "An unexpected error occurred",
      });
    }
  }

  async handleFetchMoreMaterialSales() {
    this.setState({
      loading: true,
    });

    try {
      let response = await this.fetchMaterialSales();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchMaterialSales();
      }

      switch (response.status) {
        case 200:
          //happy path
          this.setState(function (state, props) {
            let items = state.items.concat(response.data.materialSales);
            return {
              items: items,
              hasMore: response.data.more,
              nextPage: state.nextPage + 1,
              loading: false,
            };
          });
          break;
        case 404:
          this.setState({
            hasMore: false,
            loading: false,
            error: response.data?.error?.message,
          });
          break;
        default:
          console.log(response.data.error.message);
          this.setState({
            hasMore: false,
            loading: false,
            error: "An unexpected error occurred",
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        hasMore: false,
        loading: false,
        error: "An unexpected error occurred",
      });
    }
  }

  handleInventoryModalClose() {
    this.setState({
      inventorySelectedCallback: null,
    });
  }

  handleReceivedModalClose() {
    this.setState({
      receivedCallback: null,
      receiveModalNumber: null,
    });
  }

  setReceivedModal(callbackFunction, number) {
    this.setState({
      receivedCallback: callbackFunction,
      receiveModalNumber: number,
    });
  }

  async fetchMaterialSales() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    let queryParams = `?limit=${LIST_LIMIT}&offset=${
      LIST_LIMIT * this.state.nextPage
    }`;
    if (this.state.customerName.length > 0) {
      queryParams += `&customerName=${this.state.customerName}`;
    }
    if (this.state.customerPO.length > 0) {
      queryParams += `&customerPO=${this.state.customerPO}`;
    }
    if (this.state.jobName.length > 0) {
      queryParams += `&jobName=${this.state.jobName}`;
    }
    if (this.state.warehouse > -1) {
      queryParams += `&locationId=${parseInt(this.state.warehouse)}`;
    }
    queryParams += `&received=${this.state.received}&checked=${this.state.checked}`;
    let url =
      process.env.REACT_APP_BASE_URL + "/v2/material-sales" + queryParams;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error ocurred",
      });
    }
  }

  render() {
    return (
      <div>
        <h1>Sales</h1>
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Customer Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Filter by customer name..."
                value={this.state.customerName}
                onChange={this.handleChangeCustomerName}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Customer PO</Form.Label>
              <Form.Control
                type="text"
                placeholder="Filter by customer PO..."
                value={this.state.customerPO}
                onChange={this.handleChangeCustomerPO}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Job Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Filter by job name..."
                value={this.state.jobName}
                onChange={this.handleChangeJobName}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Warehouse</Form.Label>
              <Form.Control
                as="select"
                value={this.state.warehouse}
                onChange={this.handleChangeWarehouse}
              >
                <option key={-1} value={-1} disabled>
                  Select warehouse...
                </option>
                {this.warehouses.map((warehouse) => (
                  <option key={warehouse.id} value={warehouse.id}>
                    {warehouse.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Check
                type="checkbox"
                label="Checked"
                checked={this.state.checked}
                onChange={this.handleChangeChecked}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Check
                type="checkbox"
                label="Received"
                checked={this.state.received}
                onChange={this.handleChangeReceived}
              />
            </Form.Group>
          </Form.Row>
        </Form>
        <Alert variant="danger" show={this.state.error}>
          {this.state.error}
        </Alert>
        {this.state.items.length > 0 ? (
          <InfiniteScroll
            dataLength={this.state.items.length}
            next={this.handleFetchMoreMaterialSales}
            hasMore={this.state.hasMore}
            loader={<FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
            height={1000}
          >
            <Accordion>
              {this.state.items.map((item) => (
                <MaterialSaleListItem
                  key={item.number}
                  sale={item}
                  renewAuthenticationToken={this.props.renewAuthenticationToken}
                  setReceivedModal={this.setReceivedModal}
                />
              ))}
            </Accordion>
          </InfiniteScroll>
        ) : null}
        {this.state.inventorySelectedCallback ? (
          <InventoryItemSelectorModal
            handleClose={this.handleInventoryModalClose}
            callback={(inventoryItem) => {
              this.state.inventorySelectedCallback(inventoryItem);
              this.handleInventoryModalClose();
            }}
          />
        ) : null}
        {this.state.receiveModalNumber ? (
          <ReceptionSignatureModal
            show={this.state.receiveModalNumber ? true : false}
            urlExtension="material-sales"
            title="Receive Material sale"
            objectId={this.state.receiveModalNumber}
            onClose={this.handleReceivedModalClose}
            onSuccess={this.state.receivedCallback}
            renewAuthenticationToken={this.props.renewAuthenticationToken}
          />
        ) : null}
      </div>
    );
  }
}

export default MaterialSaleList;
