import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import JobNumberInput from "./jobNumberInput";

import { formatUTCDate } from "../helpers";
import getWarehouses from "../warehouses";

class CreateMaterialOrderModal extends React.Component {
  /*
	PROPS:
	show: [Bool] whether or not to show
	handleClose: [Function] function to call back when closing
	onSuccess: [Function] function to call back on successful submit
	renewAuthenticationToken: [Function]

	STATE:
	error: [String] error message if any
	jobNumber: [String] jobNumber of form
	locationId: [Int] locationId of warehouse
  pickup: [Bool] whether or not the order is a pickup
	dateDesired: [Date] date desired
	noteOne: [String] note 1
	noteTwo: [String] note 2
	loading: [Bool] whether or not loading is in progress
	success: [String] true when a new material order is successfully created and is the number of the new order
	*/

  constructor(props) {
    super(props);

    this.state = {
      error: "",
      jobNumber: "",
      locationId: -1,
      pickup: false,
      dateDesired: "",
      noteOne: "",
      noteTwo: "",
      loading: false,
      success: null,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleChangeJobNumber = this.handleChangeJobNumber.bind(this);
    this.handleChangeLocationId = this.handleChangeLocationId.bind(this);
    this.handleChangePickupOrDelivery =
      this.handleChangePickupOrDelivery.bind(this);
    this.handleChangeDateDesired = this.handleChangeDateDesired.bind(this);
    this.handleChangeNoteOne = this.handleChangeNoteOne.bind(this);
    this.handleChangeNoteTwo = this.handleChangeNoteTwo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.warehouses = getWarehouses();
  }

  handleClose() {
    this.setState({
      error: "",
      jobNumber: "",
      locationId: -1,
      pickup: false,
      dateDesired: "",
      noteOne: "",
      noteTwo: "",
      loading: false,
      success: null,
    });

    //reset state and call close callback

    this.props.handleClose();
  }

  handleSuccess(orderNumber) {
    this.props.onSuccess({
      number: orderNumber,
      jobNumber: this.state.jobNumber,
      locationId: parseInt(this.state.locationId),
      pickup: this.state.pickup,
      dateEntered: new Date(),
      dateDesired: this.state.dateDesired,
      noteOne: this.state.noteOne !== "" ? this.state.noteOne : null,
      noteTwo: this.state.noteTwo !== "" ? this.state.noteTwo : null,
      checkedBy: null,
      receivedBy: null,
    });
    this.handleClose();
  }

  handleChangeJobNumber(jobNumber) {
    this.setState({
      jobNumber: jobNumber,
    });
  }

  handleChangeLocationId(e) {
    this.setState({
      locationId: e.target.value,
    });
  }

  handleChangePickupOrDelivery(e) {
    this.setState({
      pickup: e.target.value,
    });
  }

  handleChangeDateDesired(e) {
    this.setState({
      dateDesired: e.target.valueAsDate,
    });
  }

  handleChangeNoteOne(e) {
    this.setState({
      noteOne: e.target.value,
    });
  }

  handleChangeNoteTwo(e) {
    this.setState({
      noteTwo: e.target.value,
    });
  }

  async handleSubmit() {
    this.setState({
      error: null,
      loading: true,
    });

    try {
      let response = await this.createNewMaterialOrder();

      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.createNewMaterialOrder();
      }

      switch (response.status) {
        case 201:
          //happy path
          this.setState({
            loading: false,
            success: response.data.number,
          });
          setTimeout(() => this.handleSuccess(response.data.number), 1500);
          break;
        case 404:
          this.setState({
            loading: false,
            error: response.data.error.message,
          });
          break;
        default:
          this.setState({
            loading: false,
            error: "An unexpected error occurred",
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        loading: false,
        error: error.message,
      });
    }
  }

  async createNewMaterialOrder() {
    let body = {
      jobNumber: this.state.jobNumber,
      locationId: parseInt(this.state.locationId),
      pickup: this.state.pickup,
      dateDesired: this.state.dateDesired,
      noteOne: this.state.noteOne,
      noteTwo: this.state.noteTwo,
    };

    //removes any null or undefind fields or empty string fields
    Object.keys(body).forEach(
      (key) =>
        (body[key] === undefined || body[key] === null || body[key] === "") &&
        delete body[key]
    );

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify(body),
    };

    let url = process.env.REACT_APP_BASE_URL + "/v2/material-orders/";

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: error.message,
      });
    }
  }

  render() {
    var modalProps = {
      size: "lg",
    };
    if (this.state.loading) {
      modalProps.backdrop = "static";
      modalProps.keyboard = false;
    }
    return (
      <Modal {...modalProps} show={this.props.show} onHide={this.handleClose}>
        <Modal.Header closeButton={!this.state.loading}>
          <Modal.Title>New Material Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={this.state.success}>
            {this.state.success} Added!
          </Alert>
          <Alert variant="danger" show={this.state.error}>
            {this.state.error}
          </Alert>
          <Form>
            <JobNumberInput
              renewAuthenticationToken={this.props.renewAuthenticationToken}
              handleJobNumberChange={this.handleChangeJobNumber}
              asCol={false}
            />
            <Form.Group>
              <Form.Label>Date Desired</Form.Label>
              <Form.Control
                type="date"
                value={formatUTCDate(this.state.dateDesired, false)}
                onChange={this.handleChangeDateDesired}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Warehouse</Form.Label>
              <Form.Control
                as="select"
                value={this.state.locationId}
                onChange={this.handleChangeLocationId}
              >
                <option key={-1} value={-1} disabled>
                  Select warehouse...
                </option>
                {this.warehouses.map((warehouse) => (
                  <option key={warehouse.id} value={warehouse.id}>
                    {warehouse.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Delivery or Pickup</Form.Label>
              <Form.Control
                as="select"
                value={this.state.pickup}
                onChange={this.handleChangePickupOrDelivery}
              >
                <option key={0} value={false}>
                  Delivery
                </option>
                <option key={1} value={true}>
                  Pickup
                </option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Note One</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter notes here"
                rows={3}
                value={this.state.noteOne}
                onChange={this.handleChangeNoteOne}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Note Two</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter notes here"
                rows={3}
                value={this.state.noteTwo}
                onChange={this.handleChangeNoteTwo}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.handleClose}
            disabled={this.state.loading}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={this.handleSubmit}
            disabled={
              this.state.jobNumber === "" ||
              this.state.locationId === -1 ||
              this.state.dateDesired === "" ||
              this.state.loading ||
              this.state.success
            }
          >
            {this.state.loading ? "Loading..." : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CreateMaterialOrderModal;
