import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Alert from "react-bootstrap/alert";
import Form from "react-bootstrap/form";
import Col from "react-bootstrap/col";
import Button from "react-bootstrap/button";
import Table from "react-bootstrap/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";

import getWarehouses from "../warehouses";
import InventoryItemSelectorModal from "./inventoryItemSelectorModal";
import MaterialOrderItem from "./materialOrderItem";
import ReceptionSignatureModal from "./receptionSignatureModal";
import ConfirmDeleteModal from "./confirmDeleteModal";
import { renewAuthenticationToken } from "../authenticationUtils";
import { formatDate, formatUTCDate } from "../helpers";

const MaterialOrder = () => {
  const params = useParams();
  const navigate = useNavigate();

  const warehouses = getWarehouses();

  //STATE
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState(null);
  const [jobName, setJobName] = useState(null);
  const [changedDateDesired, setChangedDateDesired] = useState(null);
  const [changedCheckedBy, setChangeCheckedBy] = useState(false);
  const [changedWarehouse, setChangedWarehouse] = useState(null);
  const [changedPickupOrDelivery, setChangedPickupOrDelivery] = useState(null); //0 for delivery, 1 for pickup
  const [changedNoteOne, setChangedNoteOne] = useState(null);
  const [changedNoteTwo, setChangedNoteTwo] = useState(null);
  const [deviatedItems, setDeviatedItems] = useState([]); //collection of id/qtyMatch(bool) objects that represent items that have deviated from initial state
  const [checkedByName, setCheckedByName] = useState(null);
  const [createdByName, setCreatedByName] = useState(null);
  const [showAddNewItemModal, setShowAddNewItemModal] = useState(false);
  const [showReceptionModal, setShowReceptionModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemCallback, setDeleteItemCallback] = useState(null);

  useEffect(() => {
    async function handleFetchMaterialOrder() {
      setLoading(true);
      setError(null);

      const id = params.id;

      try {
        let result = await fetchMaterialOrder(id);
        if (result.status === 401) {
          await renewAuthenticationToken();
          result = await fetchMaterialOrder(id);
        }

        switch (result.status) {
          case 200:
            setOrder(result.data);
            handleFetchJobName(result.data.jobNumber);
            if (result.data.createdBy) {
              handleFetchName(result.data.createdBy, setCreatedByName);
            }
            if (result.data.checkedBy) {
              handleFetchName(result.data.checkedBy, setCheckedByName);
            }
            break;
          case 404:
            setError("No material order found");
            break;
          default:
            setError("An unexpected error occurred");
            break;
        }
      } catch (error) {
        console.log(error);
        setError("An unexpected error occurred");
      }
      setLoading(false);
    }
    handleFetchMaterialOrder();
  }, [params]); //on first render

  function allQtyMatch() {
    //checks to make sure the qtyPicked is equal to the requested qty for all items
    for (let i = 0; i < order.items.length; i++) {
      //first see if this item is in the collection of items that have deviated
      const deviatedItem = deviatedItems.find(
        (item) => item.id === order.items[i].id
      );
      if (deviatedItem?.qtyMatch) {
        continue;
      } else if (deviatedItem) {
        //there was a deviatedItem that matched but the qtyMatch was false
        return false;
      }
      if (order.items[i].qty !== order.items[i].qtyPicked) {
        return false;
      }
    }
    return true;
  }

  function checkDisabled() {
    if (order.items.length === 0) {
      return true;
    }
    return !allQtyMatch();
  }

  function orderHasChanged() {
    if (
      changedDateDesired ||
      changedCheckedBy ||
      changedWarehouse ||
      changedPickupOrDelivery ||
      changedNoteOne ||
      changedNoteTwo
    ) {
      return true;
    }
    return false;
  }

  async function handleFetchName(id, callback) {
    try {
      let result = await fetchUser(id);
      if (result.status === 401) {
        await renewAuthenticationToken();
        result = await fetchUser(id);
      }

      if (result.status === 200) {
        callback(result.data.name);
      } else {
        //not going to do much error handling for fetching a user's name in the background
        console.log(result);
      }
    } catch (error) {
      //not going to do anything in the UX
      console.log(error.message);
    }
  }

  async function handleFetchJobName(jobNumber) {
    try {
      let result = await fetchJob(jobNumber);
      if (result.status === 401) {
        await renewAuthenticationToken();
        result = await fetchJob(jobNumber);
      }
      if (result.status === 200) {
        setJobName(result.data.name);
      } else {
        console.log(new Error(`handleFetchJob returnd a ${result.status}`));
      }
    } catch (error) {
      console.log(error.message); //not going to do anything in the UX
    }
  }

  async function handlePrintLabel() {
    //if we already have a label availble for this particular order then just show it, else generate it and show the new one
    let pdfId = order.pdfs?.find((pdf) => pdf.subType === 1)?.id; //will return the id of the FIRST shipping pdf

    if (!pdfId) {
      //we couldn't find an existing one, so we need to have the server create a new pdf
      try {
        let result = await createPDF(order.number, 1);
        if (result.status === 401) {
          await renewAuthenticationToken();
          result = await createPDF(order.number, 1);
        }
        switch (result.status) {
          case 201:
            pdfId = result.data.id;
            break;
          case 400:
          case 404:
            setError(result.data.error.message);
            return; //so it doesn't try to open a new tab
          default:
            setError("An unexpected error occurred");
            return;
        }
      } catch (error) {
        console.log(error);
        setError(error.message);
      }
    }

    //now try to show the pdf in a new tab
    const save = document.createElement("a");
    save.href = process.env.REACT_APP_BASE_URL + `/v2/pdfs/${pdfId}`;
    save.click();
    save.remove();
  }

  function handleItemDeviated(deviatedItem) {
    const newDeviatedItems = [...deviatedItems];
    const index = newDeviatedItems.findIndex(
      (item) => deviatedItem.id === item.id
    );
    if (index !== -1) {
      newDeviatedItems[index] = deviatedItem;
    } else {
      newDeviatedItems.push(deviatedItem);
    }
    setDeviatedItems(newDeviatedItems);
  }

  async function handleAddNewItemSelected(inventoryItem) {
    //takes an inventory item from the selected item in the modal and coordinates turning it into a new order item
    setLoading(true);
    setError(null);

    const orderItem = {
      inventoryId: inventoryItem.id,
      inventoryCode: inventoryItem.code,
      measured: inventoryItem.measured,
      ...(inventoryItem.description
        ? { description: inventoryItem.description }
        : {}), //description could be null
      ...(inventoryItem.type ? { type: inventoryItem.type } : {}), //type could be null
      ...(inventoryItem.pipeSize ? { pipeSize: inventoryItem.pipeSize } : {}),
      ...(inventoryItem.thickness
        ? { thickness: inventoryItem.thickness }
        : {}),
      qty: 0,
      qtyPicked: 0,
      ...(inventoryItem.costEach ? { costEach: inventoryItem.costEach } : {}),
      ...(inventoryItem.qtyPerPiece
        ? { qtyPerPiece: inventoryItem.qtyPerPiece }
        : {}),
    };

    try {
      let result = await createNewMaterialOrderItem(orderItem, order.number);
      if (result.status === 401) {
        await renewAuthenticationToken();
        result = await createNewMaterialOrderItem(orderItem, order.number);
      }

      switch (result.status) {
        case 201:
          navigate(0); //reload page
          break;
        case 400:
          setError(result.data.error.message);
          break;
        default:
          console.log(result.data.error.message);
          setError("An unexpected error occurred");
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
    setLoading(false);
    setShowAddNewItemModal(false);
  }

  async function handleReceivedOrder(receivedByName) {
    //the modal does all of the hard work of updating the order
    const updatedOrder = Object.assign(order);
    updatedOrder.receivedBy = receivedByName;
    setOrder(updatedOrder);
  }

  async function handleUpdateOrder() {
    //build the object
    const updatedObject = {};
    if (changedWarehouse) {
      updatedObject.locationId = changedWarehouse;
    }
    if (changedPickupOrDelivery !== null) {
      updatedObject.pickup = changedPickupOrDelivery;
    }
    if (changedDateDesired) {
      updatedObject.dateDesired = changedDateDesired;
    }
    if (changedNoteOne) {
      updatedObject.noteOne = changedNoteOne;
    }
    if (changedNoteTwo) {
      updatedObject.noteTwo = changedNoteTwo;
    }
    if (changedCheckedBy) {
      updatedObject.checked = true;
    }

    setLoading(true);
    setError(null);

    try {
      let result = await updateMaterialOrder(updatedObject, order.number);
      if (result.status === 401) {
        await renewAuthenticationToken();
        result = await updateMaterialOrder(updatedObject, order.number);
      }
      if (result.status === 200) {
        navigate(0); //reload page
      } else {
        console.log(result.data.error.message);
        setError(result.data.error.message);
      }
    } catch (error) {
      console.log(error.message);
      setError("An unexpected error occurred");
    }
    setLoading(false);
  }

  function handleItemDeleteConfirmed() {
    setShowDeleteModal(false);
    deleteItemCallback();
  }

  function handleTrashIconPressed(callback) {
    setDeleteItemCallback(() => callback);
    setShowDeleteModal(true);
  }

  return (
    <div>
      <h1>Material Order #{params.id}</h1>
      {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
      {error && <Alert variant="danger">{error}</Alert>}
      {order && (
        <div>
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Job Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={order.jobNumber}
                  readOnly
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Job Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={jobName ?? "Loading name..."}
                  readOnly
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Order Date</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={formatDate(order.dateEntered)}
                  readOnly
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Date Desired</Form.Label>
                <Form.Control
                  type="date"
                  value={formatUTCDate(
                    changedDateDesired ?? order.dateDesired,
                    false
                  )}
                  onChange={(e) => setChangedDateDesired(e.target.valueAsDate)}
                  disabled={order.checkedBy ?? changedCheckedBy}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Ordered By</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={createdByName ?? "Unknown"}
                  readOnly
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Warehouse</Form.Label>
                <Form.Control
                  as="select"
                  value={changedWarehouse ?? order.locationId}
                  onChange={(e) =>
                    setChangedWarehouse(parseInt(e.target.value))
                  }
                >
                  <option key={-1} value={-1} disabled>
                    Select warehouse...
                  </option>
                  {warehouses.map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group>
                <Form.Label>Delivery or Pickup</Form.Label>
                <Form.Control
                  as="select"
                  value={changedPickupOrDelivery ?? order.pickup ?? -1}
                  onChange={(e) => setChangedPickupOrDelivery(e.target.value)}
                >
                  {order.pickup === null && (
                    <option key={-1} value={-1}>
                      Select option
                    </option>
                  )}
                  <option key={0} value={false}>
                    Delivery
                  </option>
                  <option key={1} value={true}>
                    Pickup
                  </option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <Form.Label>Note One</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={changedNoteOne ?? order.noteOne ?? ""}
                onChange={(e) => setChangedNoteOne(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Note Two</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={changedNoteTwo ?? order.noteTwo ?? ""}
                onChange={(e) => setChangedNoteTwo(e.target.value)}
              />
            </Form.Group>
            {!order.checkedBy && (
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Checked"
                  disabled={checkDisabled()}
                  checked={changedCheckedBy}
                  onChange={(e) => setChangeCheckedBy(e.target.checked)}
                />
              </Form.Group>
            )}
            {order.checkedBy && (
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Checked By</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={checkedByName ?? "Loading name..."}
                    readOnly
                  />
                </Form.Group>
              </Form.Row>
            )}
            {order.receivedBy && (
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Received By</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={order.receivedBy}
                    readOnly
                  />
                </Form.Group>
              </Form.Row>
            )}
          </Form>
          <Button disabled={!orderHasChanged()} onClick={handleUpdateOrder}>
            Update
          </Button>
          {order.checkedBy && (
            <Button
              variant="secondary"
              style={{ marginLeft: "5px" }}
              onClick={handlePrintLabel}
            >
              Print Label
            </Button>
          )}
          <hr></hr>
          <div>
            <h2>Items</h2>
            <Table striped>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Description</th>
                  <th>Size</th>
                  <th>Unit</th>
                  <th>#</th>
                  <th># Picked</th>
                  {!order.checkedBy && !changedCheckedBy && (
                    <th>
                      <FontAwesomeIcon icon={faTrash} />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {order.items.map((item) => (
                  <MaterialOrderItem
                    key={item.id}
                    item={item}
                    checked={order.checkedBy ?? changedCheckedBy}
                    itemDeviatedCallback={handleItemDeviated}
                    handleDeleteIconPressed={handleTrashIconPressed}
                  />
                ))}
              </tbody>
            </Table>
            {!order.checkedBy && !changedCheckedBy && (
              <Button onClick={() => setShowAddNewItemModal(true)}>
                Add New Item
              </Button>
            )}
            {!order.receivedBy && order.checkedBy && (
              <Button
                variant="secondary"
                onClick={() => setShowReceptionModal(true)}
              >
                Receive Order
              </Button>
            )}
            {showAddNewItemModal && (
              <InventoryItemSelectorModal
                handleClose={() => setShowAddNewItemModal(false)}
                callback={(inventoryItem) => {
                  handleAddNewItemSelected(inventoryItem);
                  setShowAddNewItemModal(false);
                }}
              />
            )}
            <ReceptionSignatureModal
              show={showReceptionModal}
              urlExtension="material-orders"
              title="Receive Material Order"
              objectId={order.number}
              onClose={() => setShowReceptionModal(false)}
              onSuccess={handleReceivedOrder}
              renewAuthenticationToken={renewAuthenticationToken}
            />
            {showDeleteModal && (
              <ConfirmDeleteModal
                handleClose={() => setShowDeleteModal(false)}
                handleDelete={handleItemDeleteConfirmed}
              />
            )}
          </div>
          <hr></hr>
          {order.pdfs?.map((pdf) => {
            if (pdf.subType === 0) {
              return (
                <div key={pdf.id}>
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}/v2/pdfs/${pdf.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {pdf.fileName}
                  </a>
                </div>
              );
            }
          })}
        </div>
      )}
      {!order && loading && <p>Loading material order...</p>}
    </div>
  );
};

async function fetchMaterialOrder(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
    credentials: "include",
  };

  const url = `${process.env.REACT_APP_BASE_URL}/v2/material-orders/${id}`;
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  return { status: response.status, data: data };
}

async function updateMaterialOrder(updatedObject, orderNumber) {
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
    credentials: "include",
    body: JSON.stringify(updatedObject),
  };

  const url = `${process.env.REACT_APP_BASE_URL}/v2/material-orders/${orderNumber}`;
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  return { status: response.status, data: data };
}

async function createNewMaterialOrderItem(orderItem, orderNumber) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
    credentials: "include",
    body: JSON.stringify(orderItem),
  };

  const url = `${process.env.REACT_APP_BASE_URL}/v2/material-orders/${orderNumber}/items`;
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  return { status: response.status, data: data };
}

async function createPDF(orderNumber, type = 0) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
    credentials: "include",
    body: JSON.stringify({ type: type }),
  };

  const url = `${process.env.REACT_APP_BASE_URL}/v2/material-orders/${orderNumber}/pdfs`;
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  return { status: response.status, data: data };
}

async function fetchUser(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
    credentials: "include",
  };

  const url = `${process.env.REACT_APP_BASE_URL}/v2/users/${id}`;
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  return { status: response.status, data: data };
}

async function fetchJob(jobNumber) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
    credentials: "include",
  };

  const response = await fetch(
    process.env.REACT_APP_BASE_URL + `/v2/jobs/${jobNumber}`,
    requestOptions
  );
  const data = await response.json();
  return { status: response.status, data: data };
}

export default MaterialOrder;
