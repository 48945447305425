import React from "react";
import Alert from "react-bootstrap/Alert";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import InfiniteScroll from "react-infinite-scroll-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faPlus } from "@fortawesome/free-solid-svg-icons";

import TimesheetListItem from "./timesheetListItem";
import CreateTimesheetModal from "./createTimesheetModal";

import { formatUTCDate } from "../helpers";

const LIST_LIMIT = 25; //number to retrieve per query
const WAIT_INTERVAL = 300; //ms to wait before sending request to re-filter

class TimesheetList extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [Function]

	STATE:
	weekEndingDateTime: [Date Time] defaults to the Friday before now at 0000Z
	error: [String] error message if any
	loading: [Bool] whether loading is occurring or not
	timesheets: [Array [Object]] array of timesheet objects if any
	nextPage: [Int] next page for pagination
	hasMore: [Bool] there are more objects that could be retrieved
	showAddTimesheetModal: [Bool] whether to show the add timesheet modal

	TODO:
	Only allow datepicker to select fridays
	*/

  constructor(props) {
    super(props);

    this.timer = null; //we're going to use this timer to set timeouts for updates

    let lastFriday = new Date();
    lastFriday.setDate(lastFriday.getDate() - ((lastFriday.getDay() + 2) % 7));
    lastFriday.setUTCHours(0);
    lastFriday.setUTCMinutes(0);
    lastFriday.setUTCSeconds(0);
    lastFriday.setUTCMilliseconds(0);

    this.state = {
      weekEndingDateTime: lastFriday,
      error: null,
      loading: false,
      timesheets: [],
      nextPage: 0,
      hasMore: true,
      showAddTimesheetModal: false,
    };

    this.handleChangeWeekEndingDateTime =
      this.handleChangeWeekEndingDateTime.bind(this);
    this.handleFetchTimesheets = this.handleFetchTimesheets.bind(this);
    this.handleFetchMoreTimesheets = this.handleFetchMoreTimesheets.bind(this);
    this.handleFetchSuggestedTimesheets =
      this.handleFetchSuggestedTimesheets.bind(this);
    this.handleAddNew = this.handleAddNew.bind(this);
    this.handleTimesheetModalClose = this.handleTimesheetModalClose.bind(this);
  }

  componentDidMount() {
    this.handleFetchTimesheets();
  }

  handleChangeWeekEndingDateTime(e) {
    clearTimeout(this.timer);

    this.setState(
      {
        weekEndingDateTime: e.target.valueAsDate,
        nextPage: 0,
        hasMore: true,
      },
      () => {
        this.timer = setTimeout(this.handleFetchTimesheets, WAIT_INTERVAL);
      }
    );
  }

  handleAddNew() {
    this.setState({ showAddTimesheetModal: true });
  }

  handleTimesheetModalClose() {
    this.setState(
      {
        showAddTimesheetModal: false,
        nextPage: 0,
        hasMore: true,
      },
      this.handleFetchTimesheets
    );
  }

  async handleFetchTimesheets() {
    this.setState({
      loading: true,
      error: null,
    });
    try {
      let response = await this.fetchTimesheets();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchTimesheets();
      }
      switch (response.status) {
        case 200:
          this.setState(function (state, props) {
            return {
              timesheets: response.data.timesheets,
              hasMore: response.data.more,
              nextPage: state.nextPage + 1,
              loading: false,
            };
          });
          break;
        case 404:
          this.setState({
            hasMore: false,
            loading: false,
            error: response.data?.error?.message,
            timesheets: [],
          });
          break;
        default:
          console.log(response.data?.error?.message);
          this.setState({
            hasMore: false,
            loading: false,
            error: "An unexpected error occurred",
            timesheets: [],
          });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        hasMore: false,
        loading: false,
        error: "An unexpected error occurred",
        timesheets: [],
      });
    }
  }

  async handleFetchMoreTimesheets() {
    this.setState({
      error: null,
      loading: true,
    });

    try {
      let response = await this.fetchTimesheets();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchTimesheets();
      }

      switch (response.status) {
        case 200:
          //happy path
          this.setState(function (state, props) {
            let timesheets = state.timesheets.concat(response.data.timesheets);
            return {
              timesheets: timesheets,
              hasMore: response.data.more,
              nextPage: state.nextPage + 1,
              loading: false,
            };
          });
          break;
        default:
          console.log(response.data?.error?.message);
          this.setState({
            hasMore: false,
            loading: false,
            error: "An unexpected error occurred",
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        hasMore: false,
        loading: false,
        error: "An unexpected error occurred",
      });
    }
  }

  async handleFetchSuggestedTimesheets() {
    this.setState({
      loading: true,
      error: null,
    });

    try {
      let response = await this.fetchTimesheets(true);
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchTimesheets(true);
      }

      switch (response.status) {
        case 200:
          this.setState(function (state, props) {
            let suggestedTimesheets = [];
            response.data.timesheets.forEach((timesheet) => {
              let matchingTimesheet = state.timesheets.find((ts) => {
                return ts.employeeId === timesheet.employeeId;
              });
              if (matchingTimesheet) {
                //there's already a matching timesheet
                matchingTimesheet.suggestedItems = timesheet.items;
              } else {
                //no matching timesheet was already found for this employee for this week
                timesheet.suggestedItems = [...timesheet.items];
                timesheet.suggested = true;
                suggestedTimesheets.push(timesheet);
              }
            });

            let timesheets = state.timesheets.concat(suggestedTimesheets);
            return {
              timesheets: timesheets,
              loading: false,
            };
          });
          break;

        case 404:
          this.setState({
            loading: false,
            error: response.data.error.message,
          });
          break;

        default:
          this.setState({
            loading: false,
            error: "An unexpected error occurred",
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        loading: false,
        error: "An unexpected error occurred",
      });
    }
  }

  async fetchTimesheets(suggested = false) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    const url = `${
      process.env.REACT_APP_BASE_URL
    }/v2/timesheets?weekEndingDateTime=${this.state.weekEndingDateTime.toISOString()}
		&limit=${LIST_LIMIT}&offset=${
      LIST_LIMIT * this.state.nextPage
    }&tSheets=${suggested}`;
    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error ocurred",
      });
    }
  }

  calculateScrollHeight() {
    //Calculates height of infinite scroll, MAX: 1000, MIN:300
    let height = this.state.timesheets.length * 50 + 300;
    if (height > 1000) {
      height = 1000;
    }
    return height;
  }

  renderSuggestedTimesheetsButton() {
    if (!this.state.hasMore) {
      return (
        <Button
          variant="primary"
          disabled={this.state.loading}
          onClick={this.handleFetchSuggestedTimesheets}
        >
          {this.state.loading ? "Loading..." : "Suggest Timesheets"}
        </Button>
      );
    }
  }

  render() {
    return (
      <div>
        <h1>Timesheets</h1>
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Week Ending</Form.Label>
              <Form.Control
                type="date"
                value={formatUTCDate(this.state.weekEndingDateTime, false)}
                onChange={this.handleChangeWeekEndingDateTime}
              />
            </Form.Group>
            <Form.Group style={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon
                icon={faPlus}
                size="3x"
                onClick={this.handleAddNew}
              />
            </Form.Group>
          </Form.Row>
        </Form>
        <Alert variant="danger" show={this.state.error}>
          {this.state.error}
        </Alert>
        {this.state.timesheets.length > 0 ? (
          <InfiniteScroll
            dataLength={this.state.timesheets.length}
            next={this.handleFetchMoreTimesheets}
            hasMore={this.state.hasMore}
            loader={<FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
            height={this.calculateScrollHeight()}
          >
            <Accordion>
              {this.state.timesheets.map((timesheet) => (
                <TimesheetListItem
                  key={timesheet.id}
                  renewAuthenticationToken={this.props.renewAuthenticationToken}
                  timesheet={timesheet}
                />
              ))}
            </Accordion>
            {this.renderSuggestedTimesheetsButton()}
          </InfiniteScroll>
        ) : (
          this.renderSuggestedTimesheetsButton()
        )}
        {this.state.showAddTimesheetModal ? (
          <CreateTimesheetModal
            renewAuthenticationToken={this.props.renewAuthenticationToken}
            show={this.state.showAddTimesheetModal}
            handleClose={this.handleTimesheetModalClose}
            onSuccess={this.handleNewTimesheetSuccess}
          />
        ) : null}
      </div>
    );
  }
}

export default TimesheetList;
