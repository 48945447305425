import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faCheckSquare,
  faSpinner,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { formatUTCDate } from "../helpers";

const StatusIcons = {
  Good: "Good",
  Loading: "Loading",
  Error: "Error",
};

const WAIT_INTERVAL = 1000; //time in ms to wait before sending update api request

class TimesheetItemListItem extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [Function]
	handleDeleteItem: [Function] callback when an item is deleted
	item: [Object] timesheetItem object
	editable: [Bool] whether or not the item can be edited / deleted

	STATE:
	hours: [Float] number of regular hours worked
	overtimeHours: [Float] number of overtime hours worked
	travel: [Float] travel allotment
	bonus: [Float] bonus
	^^ previous 4 only are used when the object is editable
	statusIcon: [String] can either be null, good, loading, or error

	TODO:
	Do some input validation on the floats
	*/
  constructor(props) {
    super(props);

    this.timer = null; //this timer is used to space out update api requests

    this.state = {
      hours: this.props.item.hours,
      overtimeHours: this.props.item.overtimeHours,
      travel: this.props.item.travel,
      bonus: this.props.item.bonus,
      statusIcon: null,
    };

    this.handleHoursChanged = this.handleHoursChanged.bind(this);
    this.handleOvertimeHoursChanged =
      this.handleOvertimeHoursChanged.bind(this);
    this.handleTravelChanged = this.handleTravelChanged.bind(this);
    this.handleBonusChanged = this.handleBonusChanged.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
    this.handleUpdateItem = this.handleUpdateItem.bind(this);
  }

  handleHoursChanged(e) {
    clearTimeout(this.timer);
    this.setState(
      {
        hours: e.target.value,
      },
      () => {
        this.timer = setTimeout(this.handleUpdateItem, WAIT_INTERVAL);
      }
    );
  }

  handleOvertimeHoursChanged(e) {
    this.setState(
      {
        overtimeHours: e.target.value,
      },
      () => {
        this.timer = setTimeout(this.handleUpdateItem, WAIT_INTERVAL);
      }
    );
  }

  handleTravelChanged(e) {
    this.setState(
      {
        travel: e.target.value,
      },
      () => {
        this.timer = setTimeout(this.handleUpdateItem, WAIT_INTERVAL);
      }
    );
  }

  handleBonusChanged(e) {
    this.setState(
      {
        bonus: e.target.value,
      },
      () => {
        this.timer = setTimeout(this.handleUpdateItem, WAIT_INTERVAL);
      }
    );
  }

  async handleUpdateItem() {
    this.setState({
      statusIcon: StatusIcons.Loading,
    });

    try {
      let result = await this.updateItem();
      if (result.status === 401) {
        await this.props.renewAuthenticationToken();
        result = await this.updateItem();
      }
      if (result.status === 200) {
        this.setState({
          statusIcon: StatusIcons.Good,
        });
      } else {
        console.log(result.data.error.message);
        this.setState({
          statusIcon: StatusIcons.Error,
        });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        statusIcon: StatusIcons.Error,
      });
    }
  }

  async handleDeleteItem() {
    this.setState({
      statusIcon: StatusIcons.Loading,
    });

    try {
      let result = await this.deleteItem();
      if (result.status === 401) {
        await this.props.renewAuthenticationToken();
        result = await this.deleteItem();
      }
      if (result.status === 204) {
        //happy path
        this.props.handleDeleteItem(this.props.item.id);
      } else {
        console.log(result.data.error.message);
        this.setState({
          statusIcon: StatusIcons.Error,
        });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        statusIcon: StatusIcons.Error,
      });
    }
  }

  async updateItem() {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify({
        hours: this.state.hours || 0,
        overtimeHours: this.state.overtimeHours || 0,
        travel: this.state.travel || 0,
        bonus: this.state.bonus || 0,
      }),
    };

    const url = `${process.env.REACT_APP_BASE_URL}/v2/timesheets/items/${this.props.item.id}`;
    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        statusIcon: StatusIcons.Error,
      });
    }
  }

  async deleteItem() {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    const url = `${process.env.REACT_APP_BASE_URL}/v2/timesheets/items/${this.props.item.id}`;
    try {
      let response = await fetch(url, requestOptions);
      let data = null;
      if (response.status !== 204) {
        data = await response.json();
      }
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        statusIcon: StatusIcons.Error,
      });
    }
  }

  renderStatusIcon() {
    switch (this.state.statusIcon) {
      case StatusIcons.Good:
        return (
          <FontAwesomeIcon icon={faCheckSquare} style={{ color: "green" }} />
        );
      case StatusIcons.Loading:
        return <FontAwesomeIcon icon={faSpinner} className="fa-spin" />;
      case StatusIcons.Error:
        return (
          <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red" }} />
        );
      default:
        return;
    }
  }

  render() {
    if (this.props.editable) {
      return (
        <tr>
          <td>
            {formatUTCDate(this.props.item.dateTime)} {this.renderStatusIcon()}
          </td>
          <td>{this.props.item.jobNumber}</td>
          <td>
            <input
              type="text"
              value={this.state.hours || 0}
              onChange={this.handleHoursChanged}
              inputMode="numeric"
              size="5"
            />
          </td>
          <td>
            <input
              type="text"
              value={this.state.overtimeHours || 0}
              onChange={this.handleOvertimeHoursChanged}
              inputMode="numeric"
              size="5"
            />
          </td>
          <td>
            $
            <input
              type="text"
              value={this.state.travel || 0}
              onChange={this.handleTravelChanged}
              inputMode="numeric"
              size="3"
            />
          </td>
          <td>
            $
            <input
              type="text"
              value={this.state.bonus || 0}
              onChange={this.handleBonusChanged}
              inputMode="numeric"
              size="4"
            />
          </td>
          <td>
            <FontAwesomeIcon icon={faTrash} onClick={this.handleDeleteItem} />
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td>{formatUTCDate(this.props.item.dateTime)}</td>
          <td>{this.props.item.jobNumber}</td>
          <td>{this.props.item.hours || 0}</td>
          <td>{this.props.item.overtimeHours || 0}</td>
          <td>${this.props.item.travel || 0}</td>
          <td>${this.props.item.bonus || 0}</td>
        </tr>
      );
    }
  }
}

export default TimesheetItemListItem;
