import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";

import JobNumberInput from "./jobNumberInput";
import MaterialCreditItemList from "./materialCreditItemList";
import getWarehouses from "../warehouses";

class CreateMaterialCreditModal extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [function] renews token
	show: [bool] whether modal is showing or not
	handleClose: [function] callback on close

	STATE:
	loading: [Bool] whether or not loading is occuring
	success: [String] success message if any
	error: [String] error message if any
	jobNumber: [String] jobNumber entered
	locationId: [Int] warehouse location id
	items: [Array [Object]] array of items that get displayed, this is calculated from order and credit items

	*/

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      success: null,
      error: null,
      jobNumber: "",
      locationId: -1,
      items: [],
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChangeJobNumber = this.handleChangeJobNumber.bind(this);
    this.handleIncreaseQty = this.handleIncreaseQty.bind(this);
    this.handleDecreaseQty = this.handleDecreaseQty.bind(this);
    this.handleQtyTyped = this.handleQtyTyped.bind(this);
    this.handleLoadInventoryItems = this.handleLoadInventoryItems.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.warehouses = getWarehouses();
  }

  handleClose() {
    this.setState({
      loading: false,
      success: null,
      error: null,
      jobNumber: "",
      locationId: -1,
      items: [],
    });

    this.props.handleClose();
  }

  handleChangeJobNumber(jobNumber) {
    //jobNumber effects the inventory items, so we have to load them AFTER a change in job number
    this.setState({ jobNumber: jobNumber }, () =>
      this.handleLoadInventoryItems(jobNumber)
    );
  }

  handleChangeLocationId(locationId) {
    this.setState({ locationId: locationId });
  }

  handleIncreaseQty(itemId) {
    /* NOTE
		materialOrderItems have id's as PK
		creditItems index on inventoryId and credit number so itemId = inventoryId[String]creditNumber[String]
		*/
    this.setState(function (state, props) {
      let items = [...state.items]; //create a copy to get rid of references
      for (let i = 0; i < items.length; i++) {
        if (
          items[i].id === itemId ||
          `${items[i].inventoryId}${items[i].creditNumber}` === itemId
        ) {
          //MATCH
          if (items[i].qty > items[i].selectedQty) {
            //you cant select more than the qty available
            let item = { ...items[i] }; //create a copy to get rid of references
            item.selectedQty++;
            items[i] = item;
            return { items: items };
          }
        }
      }
    });
  }

  handleDecreaseQty(itemId) {
    /* NOTE
		materialOrderItems have id's as PK
		creditItems index on inventoryId and credit number so itemId = inventoryId[String]creditNumber[String]
		*/
    this.setState(function (state, props) {
      let items = [...state.items]; //create a copy to get rid of references
      for (let i = 0; i < items.length; i++) {
        if (
          items[i].id === itemId ||
          `${items[i].inventoryId}${items[i].creditNumber}` === itemId
        ) {
          //MATCH
          if (items[i].selectedQty > 0) {
            //you cant select less than 0
            let item = { ...items[i] }; //create a copy to get rid of references
            item.selectedQty--;
            items[i] = item;
            return { items: items };
          }
        }
      }
    });
  }

  handleQtyTyped(itemId, selectedQty) {
    /* NOTE
		materialOrderItems have id's as PK
		creditItems index on inventoryId and credit number so itemId = inventoryId[String]creditNumber[String]
		*/
    this.setState(function (state, props) {
      let items = state.items;
      for (let i = 0; i < items.length; i++) {
        if (
          items[i].id === itemId ||
          `${items[i].inventoryId}${items[i].creditNumber}` === itemId
        ) {
          //MATCH
          if (selectedQty >= 0 && selectedQty <= items[i].qty) {
            //you cant type a selectedQty less than 0 or more than qty
            items[i].selectedQty = selectedQty;
            return { items: items };
          } else {
            //if conditions above are broken set to 0
            items[i].selectedQty = 0;
            return { items: items };
          }
        }
      }
    });
  }

  async handleLoadInventoryItems(jobNumber) {
    this.setState({
      loading: true,
    });

    try {
      let [responseOrders, responseCredits] = await Promise.all([
        this.fetchMaterialOrderItems(jobNumber),
        this.fetchMaterialCreditItems(jobNumber),
      ]);
      if (responseOrders.status === 401 || responseCredits.status === 401) {
        await this.props.renewAuthenticationToken();
        [responseOrders, responseCredits] = await Promise.all([
          this.fetchMaterialOrderItems(jobNumber),
          this.fetchMaterialCreditItems(jobNumber),
        ]);
      }

      //cant support case where no order items exist for job
      if (responseOrders.status === 404) {
        console.log(responseOrders.data.error.message);
        this.setState({
          loading: false,
          error: "No inventory to return",
        });
      } else if (
        responseOrders.status === 200 &&
        (responseCredits.status === 200 || responseCredits.status === 404)
      ) {
        //HAPPY PATH
        let creditItems = [];
        if (responseCredits.status === 200) {
          //at least 1 material credit has been recorded for this job
          creditItems = responseCredits.data.materialCreditItems;
        }
        const items = this.handleCalculateItems(
          responseOrders.data.materialOrderItems,
          creditItems
        );
        this.setState({
          loading: false,
          items: items,
        });
      } else {
        this.setState({
          loading: false,
          error: "An unexpected error occurred",
        });
        if (responseOrders.status !== 200) {
          console.log(responseOrders.data.error.message);
        }
        if (responseCredits.status !== 200) {
          console.log(responseCredits.data.error.message);
        }
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        loading: false,
        error: "An unexpected error occurred",
      });
    }
  }

  async handleSubmit() {
    /*
		1. Create the material credit record itself
		2. Create a new material credit record for each item where selectedQty > 0
		*/
    this.setState({
      loading: true,
      error: null,
    });

    try {
      let response = await this.createMaterialCredit();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.createMaterialCredit();
      }
      if (response.status === 201) {
        //SUCCESS
        const creditNumber = response.data.number;
        for (let i = 0; i < this.state.items.length; i++) {
          if (this.state.items[i].selectedQty > 0) {
            let item = { ...this.state.items[i] };
            item.qty = item.selectedQty;
            let itemResponse = await this.createMaterialCreditItem(
              creditNumber,
              item
            );
            if (itemResponse.status === 401) {
              await this.props.renewAuthenticationToken();
              itemResponse = await this.createMaterialCreditItem(
                creditNumber,
                item
              );
            }
            if (itemResponse.status === 201) {
              //SUCCESS
              this.setState(
                {
                  loading: false,
                  success: `Material Credit ${creditNumber} created!`,
                },
                () => setTimeout(this.handleClose, 1500)
              );
              //after state change, wait 1.5 sec and call handle close
            } else {
              console.log(itemResponse.data.error.message);
              this.setState({
                loading: false,
                error: "An unexpected error occurred",
              });
            }
          }
        }
      } else {
        console.log(response.data.error.message);
        this.setState({
          loading: false,
          error: "An unexpected error occurred",
        });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        loading: false,
        error: "An unexpected error occurred",
      });
    }
  }

  disableSubmit() {
    //Looks at state and returns true or false
    if (
      this.state.loading ||
      this.state.success ||
      this.state.jobNumber === "" ||
      this.state.items.length === 0 ||
      this.state.locationId === -1
    ) {
      return true;
    }
    for (let i = 0; i < this.state.items.length; i++) {
      if (this.state.items[i].selectedQty > 0) {
        return false;
      }
    }
    return true;
  }

  handleCalculateItems(orderItems, creditItems) {
    /*
		Takes an array of order item objects and array of credit item objects

		Returns an array to be added to state as items

		This function serves to calculate the "net" items orders - credits
		*/
    let items = [];
    for (let i = 0; i < orderItems.length; i++) {
      if (orderItems[i].qty <= 0) {
        continue; //don't waste time iterating on an orderitem of qty 0
      }
      orderItems[i].selectedQty = 0; //selectedQty will be the quantity the user selects to return
      delete orderItems[i].costEach; //delete cost each per Roger. this will get assigned by MS Access later

      //check all other order items down the array to see if there's another one with the same inventoryId
      //if so add it's qty to this order item
      for (let k = i + 1; k < orderItems.length; k++) {
        if (orderItems[k].inventoryId === orderItems[i].inventoryId) {
          orderItems[i].qty += orderItems[k].qty;
          orderItems[k].qty = 0;
        }
      }
      for (let j = 0; j < creditItems.length; j++) {
        if (creditItems[j].inventoryId === orderItems[i].inventoryId) {
          //INVENTORY MATCH
          //Decrement from them both until the first one reaches 0
          while (orderItems[i].qty > 0 && creditItems[j].qty > 0) {
            orderItems[i].qty--;
            creditItems[j].qty--;
          }
        }
      }
      if (orderItems[i].qty > 0) {
        items.push(orderItems[i]);
      }
    }

    //we're going to loop back through material credit items to check that nothing has any qty
    //if so it means that someone probably manually added a credit item outside of the app and qtys don't line up
    //we'll push this onto the items array to call it out
    for (let j = 0; j < creditItems.length; j++) {
      if (creditItems[j].qty > 0) {
        creditItems[j].selectedQty = 0;
        creditItems[j].qty = creditItems[j].qty * -1; //making it negative will highlight this issue
        items.push(creditItems[j]);
      }
    }
    return items;
  }

  async createMaterialCredit() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify({
        jobNumber: this.state.jobNumber,
        locationId: parseInt(this.state.locationId),
      }),
    };

    try {
      let response = await fetch(
        process.env.REACT_APP_BASE_URL + `/v2/material-credits/`,
        requestOptions
      );
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
      });
    }
  }

  async createMaterialCreditItem(creditNumber, item) {
    //removes any null or undefind fields
    Object.keys(item).forEach(
      (key) =>
        (item[key] === undefined || item[key] === null) && delete item[key]
    );

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify(item),
    };

    try {
      let response = await fetch(
        process.env.REACT_APP_BASE_URL +
          `/v2/material-credits/${creditNumber}/items`,
        requestOptions
      );
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
      });
    }
  }

  async fetchMaterialOrderItems(jobNumber) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    try {
      let response = await fetch(
        process.env.REACT_APP_BASE_URL +
          `/v2/material-orders/items/?jobNumber=${jobNumber}`,
        requestOptions
      );
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
      });
    }
  }

  async fetchMaterialCreditItems(jobNumber) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    try {
      let response = await fetch(
        process.env.REACT_APP_BASE_URL +
          `/v2/material-credits/items/?jobNumber=${jobNumber}`,
        requestOptions
      );
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
      });
    }
  }

  render() {
    var modalProps = {
      size: "lg",
    };
    if (this.state.loading) {
      modalProps.backdrop = "static";
      modalProps.keyboard = false;
    }
    return (
      <Modal {...modalProps} show={this.props.show} onHide={this.handleClose}>
        <Modal.Header closeButton={!this.state.loading}>
          <Modal.Title>New Material Credit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={this.state.success}>
            {this.state.success}
          </Alert>
          <Alert variant="danger" show={this.state.error}>
            {this.state.error}
          </Alert>
          <JobNumberInput
            renewAuthenticationToken={this.props.renewAuthenticationToken}
            handleJobNumberChange={this.handleChangeJobNumber}
            asCol={false}
          />
          <Form.Group>
            <Form.Label>Warehouse</Form.Label>
            <Form.Control
              as="select"
              value={this.state.locationId}
              onChange={(e) => this.handleChangeLocationId(e.target.value)}
            >
              <option key={-1} value={-1} disabled>
                Select warehouse...
              </option>
              {this.warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {this.state.items.length > 0 ? (
            <MaterialCreditItemList
              items={this.state.items}
              return={true}
              handleIncreaseQty={this.handleIncreaseQty}
              handleDecreaseQty={this.handleDecreaseQty}
              handleQtyTyped={this.handleQtyTyped}
            />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.handleClose}
            disabled={this.state.loading}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={this.handleSubmit}
            disabled={this.disableSubmit()}
          >
            {this.state.loading ? "Loading..." : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CreateMaterialCreditModal;
