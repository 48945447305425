function formatDate(date, humanReadableOutput = true) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  if (humanReadableOutput) {
    year = year.toString().substring(2); //remove the first two characters from year
    return [month, day, year].join("/");
  } else {
    return [year, month, day].join("-");
  }
}

function formatUTCDate(utcDate, humanReadableOutput = true) {
  if (!utcDate || utcDate.length < 1) {
    return "";
  }
  var d = new Date(utcDate),
    month = "" + (d.getUTCMonth() + 1),
    day = "" + d.getUTCDate(),
    year = d.getUTCFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  if (humanReadableOutput) {
    year = year.toString().substring(2); //remove the first two characters from year
    return [month, day, year].join("/");
  } else {
    return [year, month, day].join("-");
  }
}

function formatTime(date) {
  let d = new Date(date),
    minutes = d.getMinutes(),
    hours = d.getHours();
  const hourSuffix = hours > 11 ? "PM" : "AM";
  hours = ((hours + 11) % 12) + 1;

  if (minutes < 10) {
    //less than double digits
    minutes = `0${minutes}`;
  }

  return `${hours}:${minutes}${hourSuffix}`;
}

export { formatDate, formatUTCDate, formatTime };
