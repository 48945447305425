import React from "react";

import SearchListInput from "./searchListInput";

const WAIT_INTERVAL = 300; //delay in MS, we use to prevent concurrent requests while typing
const LIST_LIMIT = 25; //number of inventory items to pull back at a time

class JobNumberInput extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [Function]
	handleJobNumberChange: [Function] callback when a jobNumber has been selected (or on blur / enter)
	asCol: [Bool] whether or not to make the input a boostrap Col

	STATE:
	jobNumber: [String] jobNumber that is typed into the form field
	jobs: [Array [Object]] array of job objects
	nextPage: [Int] next page in pagination to load, indexes from 0
	hasMore: [Bool] whether or not there are more pages to paginate on 
	error: [String] error string
	loading: [Bool] whether loading is in flight

	TODO:
	Handle errors?

	*/

  constructor(props) {
    super(props);

    this.formControlRef = React.createRef(); //this ref is used for calling the onblur later

    this.timer = null; //timer used for managing waiting to send update requests

    this.state = {
      jobNumber: "",
      showList: false,
      jobs: [],
      nextPage: 0,
      hasMore: true,
      error: null,
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleJobSelected = this.handleJobSelected.bind(this);
    this.handleFetchJobs = this.handleFetchJobs.bind(this);
    this.handleFetchMoreJobs = this.handleFetchMoreJobs.bind(this);
    this.fetchJobs = this.fetchJobs.bind(this);
  }

  handleChange(e) {
    clearTimeout(this.timer);

    this.setState(
      {
        jobNumber: e.target.value,
        hasMore: true,
        nextPage: 0,
      },
      () => {
        this.timer = setTimeout(this.handleFetchJobs, WAIT_INTERVAL);
      }
    );
  }

  handleJobSelected(e, jobNumber) {
    //On select it has to prevent page reload, then set the job number state, the call the onBlur of the input
    e.preventDefault(); //prevents page reload
    this.setState(
      {
        jobNumber: jobNumber,
      },
      () => this.formControlRef.current.blur()
    );
  }

  async handleFetchJobs() {
    this.setState({
      error: null,
      loading: true,
    });

    try {
      let response = await this.fetchJobs();

      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchJobs();
      }

      switch (response.status) {
        case 200:
          this.setState(function (state, props) {
            return {
              jobs: this.convertJobsToSearchListInputResults(
                response.data.jobs
              ),
              nextPage: state.nextPage + 1,
              hasMore: response.data.more,
            };
          });
          break;
        case 404:
          this.setState({
            jobs: [],
            hasMore: false,
            error: response.data.error.message,
          });
          break;
        default:
          this.setState({
            hasMore: false,
            error: "An unexpected error occurred",
          });
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      console.log(error.message);
      this.setState({
        loading: false,
        hasMore: false,
        error: error.message,
      });
    }
  }

  async handleFetchMoreJobs() {
    this.setState({
      error: null,
      loading: true,
    });

    try {
      let response = await this.fetchJobs();

      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchJobs();
      }

      switch (response.status) {
        case 200:
          this.setState(function (state, props) {
            let jobs = state.jobs.concat(
              this.convertJobsToSearchListInputResults(response.data.jobs)
            );
            return {
              jobs: jobs,
              nextPage: state.nextPage + 1,
              hasMore: response.data.more,
            };
          });
          break;
        case 404:
          this.setState({
            hasMore: false,
            error: response.data.error.message,
          });
          break;
        default:
          this.setState({
            hasMore: false,
            error: "An unexpected error occurred",
          });
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      console.log(error.message);
      this.setState({
        hasMore: false,
        error: error.message,
      });
    }
  }

  async fetchJobs() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    let queryParams = `?limit=${LIST_LIMIT}&offset=${
      LIST_LIMIT * this.state.nextPage
    }&inactive=false&complete=false`;
    if (
      this.state.jobNumber.charAt(0) >= "0" &&
      this.state.jobNumber.charAt(0) <= "9"
    ) {
      //in this case we can assume the user is trying to search by job number
      queryParams += `&number=${this.state.jobNumber}`;
    } else {
      //we have to assume they're typing in a job name
      queryParams += `&name=${this.state.jobNumber}`;
    }

    let url = process.env.REACT_APP_BASE_URL + "/v2/jobs" + queryParams;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: error.message,
      });
    }
  }

  convertJobsToSearchListInputResults(jobs) {
    //takes a jobs array and returns it into a results array that is needed for the searchlistinput component
    //that component takes an array of key & name property objects
    let results = [];
    jobs.forEach((job) => {
      results.push({ key: job.number, name: job.name });
    });
    return results;
  }

  render() {
    return (
      <SearchListInput
        results={this.state.jobs}
        hasMore={this.state.hasMore}
        loading={this.state.loading}
        label="Job Number"
        placeholder="Enter job number"
        value={this.state.jobNumber}
        asCol={this.props.asCol}
        handleChange={this.handleChange}
        handleItemClicked={this.handleJobSelected}
        handleInputSelected={() =>
          this.props.handleJobNumberChange(this.state.jobNumber)
        }
        handleFetchMoreResults={this.handleFetchMoreJobs}
        formControlRef={this.formControlRef}
      />
    );
  }
}

export default JobNumberInput;
