import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faPlus } from "@fortawesome/free-solid-svg-icons";

import InfiniteScroll from "react-infinite-scroll-component";

import JobNumberInput from "./jobNumberInput";
import MaterialCreditListItem from "./materialCreditListItem";
import CreateMaterialCreditModal from "./createMaterialCreditModal";
import getWarehouses from "../warehouses";

const WAIT_INTERVAL = 300; //ms to wait before sending request to re-filter
const LIST_LIMIT = 25; //number to retrieve per query

class MaterialCreditList extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [Function] renews token

	STATE:
	error: [String] error message if any
	items: [Array[Object]] array of material credits
	loading: [Bool] whether loading is happening
	hasMore: [bool] whether more items could be loaded
	nextPage: [Int] pagination next page
	jobNumber: [String] job number to filter on
	warehouse: [Int] warehouse to filter on
	showAddMaterialCreditModal: [Bool] whether to show create material credit modal

	*/
  constructor(props) {
    super(props);

    this.timer = null; //timer for updating list

    this.state = {
      error: null,
      loading: false,
      items: [],
      hasMore: true,
      nextPage: 0,
      jobNumber: "",
      warehouse: -1,
      showAddMaterialCreditModal: false,
    };

    this.handleChangeJobNumber = this.handleChangeJobNumber.bind(this);
    this.handleAddNew = this.handleAddNew.bind(this);
    this.handleCreateMaterialCreditModalClose =
      this.handleCreateMaterialCreditModalClose.bind(this);
    this.handleFetchMaterialCredits =
      this.handleFetchMaterialCredits.bind(this);
    this.handleFetchMoreMaterialCredits =
      this.handleFetchMoreMaterialCredits.bind(this);

    this.warehouses = getWarehouses();
  }

  componentDidMount() {
    this.handleFetchMaterialCredits();
  }

  handleChangeJobNumber(jobNumber) {
    clearTimeout(this.timer);

    //WAIT to set timer until after state has changed as it's asynchronous
    this.setState(
      {
        jobNumber: jobNumber,
        hasMore: true,
        nextPage: 0,
      },
      () => {
        this.timer = setTimeout(this.handleFetchMaterialCredits, WAIT_INTERVAL);
      }
    );
  }

  handleChangeWarehouse(warehouseId) {
    clearTimeout(this.timer);

    this.setState(
      {
        warehouse: warehouseId,
        hasMore: true,
        nextPage: 0,
      },
      () => {
        this.timer = setTimeout(this.handleFetchMaterialCredits, WAIT_INTERVAL);
      }
    );
  }

  handleAddNew() {
    this.setState({
      showAddMaterialCreditModal: true,
    });
  }

  handleCreateMaterialCreditModalClose() {
    this.setState(
      {
        showAddMaterialCreditModal: false,
        hasMore: true,
        nextPage: 0,
      },
      () => this.handleFetchMaterialCredits()
    ); //wait till state is changed then refresh the list
  }

  async handleFetchMaterialCredits() {
    this.setState({
      error: null,
      loading: true,
    }); //clear out any errors

    try {
      let response = await this.fetchMaterialCredits();

      if (response.status === 401) {
        //probably an auth token issue, retry
        await this.props.renewAuthenticationToken();
        response = await this.fetchMaterialCredits();
      }

      switch (response.status) {
        case 200:
          this.setState({
            items: response.data.materialCredits,
            hasMore: response.data.more,
            nextPage: 1,
            loading: false,
          });
          break;
        case 404:
          this.setState({
            items: [],
            error: response.data.error.message,
            loading: false,
            hasMore: false,
          });
          break;
        default:
          console.log(response.data.error.message);
          this.setState({
            hasMore: false,
            loading: false,
            error: "An unexpected error occurred",
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
        loading: false,
        hasMore: false,
      });
    }
  }

  async handleFetchMoreMaterialCredits() {
    this.setState({
      loading: true,
    });

    try {
      let response = await this.fetchMaterialCredits();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchMaterialCredits();
      }

      switch (response.status) {
        case 200:
          //happy path
          this.setState(function (state, props) {
            let items = state.items.concat(response.data.materialCredits);
            return {
              items: items,
              hasMore: response.data.more,
              nextPage: state.nextPage + 1,
              loading: false,
            };
          });
          break;
        case 404:
          this.setState({
            hasMore: false,
            loading: false,
          });
          break;
        default:
          console.log(response.data.error.message);
          this.setState({
            hasMore: false,
            loading: false,
            error: "An unexpected error occurred",
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        hasMore: false,
        loading: false,
        error: "An unexpected error occurred",
      });
    }
  }

  async fetchMaterialCredits() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    let queryParams = `?limit=${LIST_LIMIT}&offset=${
      LIST_LIMIT * this.state.nextPage
    }`;
    if (this.state.jobNumber.length > 0) {
      queryParams += `&jobNumber=${this.state.jobNumber}`;
    }
    if (this.state.warehouse > -1) {
      queryParams += `&locationId=${parseInt(this.state.warehouse)}`;
    }

    let url =
      process.env.REACT_APP_BASE_URL + "/v2/material-credits" + queryParams;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error ocurred",
      });
    }
  }

  render() {
    return (
      <div>
        <h1>Credits</h1>
        <Form>
          <Form.Row>
            <JobNumberInput
              renewAuthenticationToken={this.props.renewAuthenticationToken}
              handleJobNumberChange={this.handleChangeJobNumber}
              asCol={true}
            />
            <Form.Group as={Col}>
              <Form.Label>Warehouse</Form.Label>
              <Form.Control
                as="select"
                value={this.state.warehouse}
                onChange={(e) => this.handleChangeWarehouse(e.target.value)}
              >
                <option key={-1} value={-1} disabled>
                  Select warehouse...
                </option>
                {this.warehouses.map((warehouse) => (
                  <option key={warehouse.id} value={warehouse.id}>
                    {warehouse.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group style={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon
                icon={faPlus}
                size="3x"
                onClick={this.handleAddNew}
              />
            </Form.Group>
          </Form.Row>
        </Form>
        <Alert variant="danger" show={this.state.error}>
          {this.state.error}
        </Alert>
        {this.state.items.length > 0 ? (
          <InfiniteScroll
            dataLength={this.state.items.length}
            next={this.handleFetchMoreMaterialCredits}
            hasMore={this.state.hasMore}
            loader={<FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
            height={1000}
          >
            <Accordion>
              {this.state.items.map((item) => (
                <MaterialCreditListItem
                  renewAuthenticationToken={this.props.renewAuthenticationToken}
                  credit={item}
                  key={item.number}
                />
              ))}
            </Accordion>
          </InfiniteScroll>
        ) : null}
        {this.state.showAddMaterialCreditModal ? (
          <CreateMaterialCreditModal
            renewAuthenticationToken={this.props.renewAuthenticationToken}
            show={this.state.showAddMaterialCreditModal}
            handleClose={this.handleCreateMaterialCreditModalClose}
          />
        ) : null}
      </div>
    );
  }
}

export default MaterialCreditList;
