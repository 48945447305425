import React, { useState, useEffect, useRef } from "react";
import "./App.css";

import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";

import Sidebar from "react-sidebar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import jwt_decode from "jwt-decode";

import { renewAuthenticationToken, logout } from "./authenticationUtils";

import Logo from "./components/logo";
import MaterialOrderList from "./components/materialOrderList";
import MaterialOrder from "./components/materialOrder";
import UserList from "./components/userList";
import MaterialSaleList from "./components/materialSaleList";
import MaterialCreditList from "./components/materialCreditList";
import LoginScreen from "./components/loginScreen";
import EmployeeList from "./components/employeeList";
import TimesheetList from "./components/timesheetList";
import InventoryTransferList from "./components/inventoryTransferList";
import InventoryTransfer from "./components/inventoryTransfer";
import InventoryLabel from "./components/inventoryLabel";

const modules = [
  {
    name: "Material Orders",
    route: "material-orders",
    adminOnly: false,
  },
  {
    name: "Material Sales",
    route: "material-sales",
    adminOnly: false,
  },
  {
    name: "Material Credits",
    route: "material-credits",
    adminOnly: false,
  },
  {
    name: "Inventory Transfers",
    route: "inventory-transfers",
    adminOnly: false,
  },
  {
    name: "Inventory Labels",
    route: "inventory-labels",
    adminOnly: false,
  },
  {
    name: "Timesheets",
    route: "timesheets",
    adminOnly: true,
  },
  {
    name: "Employees",
    route: "employees",
    adminOnly: true,
  },
  {
    name: "Admin",
    route: "admin",
    adminOnly: true,
  },
];

function isAdmin(token) {
  if (token) {
    const decoded = jwt_decode(token);
    return decoded.admin;
  }
  return false;
}

const CustomSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const filteredModules = modules.filter(
    (module) =>
      !module.adminOnly || isAdmin(window.sessionStorage.getItem("authToken"))
  );
  const pathname = location.pathname.slice(1).split("/")[0];
  const activeKey =
    modules.find((module) => module.route === pathname)?.route ??
    "material-orders"; //if we can't figure out what module is active, make it material orders

  function onClick(e, route) {
    e.preventDefault();
    navigate(route);
  }

  return (
    <div>
      <ListGroup variant="flush" defaultActiveKey={`#${activeKey}`}>
        {filteredModules.map((module) => (
          <ListGroup.Item
            href={`#${module.route}`}
            key={module.route}
            action
            onClick={(e) => onClick(e, module.route)}
          >
            {module.name}
          </ListGroup.Item>
        ))}
        <ListGroup.Item action onClick={logout}>
          Logout
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(
    window.sessionStorage.getItem("renewToken") ? true : false
  );

  /*the reason for this block of code here and not in an effect
	is that we need it to happen on first load and never again but also before children
	useEffect of children happen before the parent
	*/
  const location = useLocation();
  if (!loggedIn && location.pathname !== "/login") {
    console.log("NOT LOGGED IN & PATH NOT LOGIN");
    logout();
  }

  useEffect(() => {
    window.addEventListener("storage", syncLogout);
  }, []);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isFirstRender = useRef(true);
  useEffect(() => {
    //logged in but going to the /login page should redirect you
    if (loggedIn && location.pathname === "/login") {
      navigate("/material-orders");
    }

    /* after the loggedIn status changes and isnt first render (loggedIn changes to default on first render so we need to exclude this infinite loop)
		if loggedIn is now true, then navigate to redirect param or material-orders by default
		*/
    if (loggedIn && !isFirstRender.current) {
      let redirectString = searchParams.get("redirect") ?? "material-orders";
      let index = 0;
      searchParams.forEach((value, key) => {
        if (key === "redirect") {
          return;
        } //dont need to add this since this is a magic key which we've dealt with above
        if (index === 0) {
          redirectString += `?${key}=${value}`;
        } else {
          redirectString += `&${key}=${value}`;
        }
        index++;
      });
      navigate(`/${redirectString}`);
    }

    //set first render over with
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [loggedIn]);

  function syncLogout(event) {
    if (event.key === "logout") {
      console.log("syncing logout from other tab");
      logout();
    }
  }

  function openSidebar(open) {
    //super annoying but the Sidebar component either passes an object or false instead of a boolean in both cases
    setSidebarOpen(open ? true : false);
  }

  return (
    <div className="App">
      <Sidebar
        sidebar={<CustomSidebar />}
        open={sidebarOpen}
        onSetOpen={openSidebar}
        styles={{ sidebar: { background: "white" } }}
      >
        {loggedIn && (
          <FontAwesomeIcon
            icon={faBars}
            size="2x"
            onClick={openSidebar}
            style={{ cursor: "pointer" }}
          />
        )}
        <Logo />
        <Container fluid="md">
          <Routes>
            <Route
              path="/"
              element={
                <MaterialOrderList
                  renewAuthenticationToken={renewAuthenticationToken}
                />
              }
            />
            <Route path="/material-orders/:id" element={<MaterialOrder />} />
            <Route
              path="/material-orders"
              element={
                <MaterialOrderList
                  renewAuthenticationToken={renewAuthenticationToken}
                />
              }
            />
            <Route
              path="*"
              element={
                <MaterialOrderList
                  renewAuthenticationToken={renewAuthenticationToken}
                />
              }
            />
            <Route
              path="/login"
              element={<LoginScreen setLoggedIn={setLoggedIn} />}
            />
            <Route
              path="/material-sales"
              element={
                <MaterialSaleList
                  renewAuthenticationToken={renewAuthenticationToken}
                />
              }
            />
            <Route
              path="/material-credits"
              element={
                <MaterialCreditList
                  renewAuthenticationToken={renewAuthenticationToken}
                />
              }
            />
            <Route
              path="/inventory-transfers/:id"
              element={<InventoryTransfer />}
            />
            <Route
              path="/inventory-transfers"
              element={<InventoryTransferList />}
            />
            <Route path="/inventory-labels" element={<InventoryLabel />} />
            <Route
              path="/timesheets"
              element={
                <TimesheetList
                  renewAuthenticationToken={renewAuthenticationToken}
                />
              }
            />
            <Route
              path="/employees"
              element={
                <EmployeeList
                  renewAuthenticationToken={renewAuthenticationToken}
                />
              }
            />
            <Route
              path="/admin"
              element={
                <UserList renewAuthenticationToken={renewAuthenticationToken} />
              }
            />
          </Routes>
        </Container>
      </Sidebar>
    </div>
  );
};

export default App;
