import { useState } from "react";
import InventoryItemSelectorModal from "./inventoryItemSelectorModal";

import Form from "react-bootstrap/form";
import Col from "react-bootstrap/col";
import Button from "react-bootstrap/button";
import Container from "react-bootstrap/container";
import Alert from "react-bootstrap/alert";

const InventoryLabel = () => {
  //STATE
  const [qty, setQty] = useState("");
  const [inventoryItem, setInventoryItem] = useState(null);
  const [showItemModal, setShowItemModal] = useState(false);
  const [error, setError] = useState(null);

  async function createLabel(inventoryItem, qty) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify({ qty: qty, inventoryId: inventoryItem.id }),
    };

    const url = `${process.env.REACT_APP_BASE_URL}/v2/inventory-labels`;
    try {
      const response = await fetch(url, requestOptions);
      if (response.status === 201) {
        const blob = await response.blob();
        const windowURL = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = windowURL;
        a.download = "inventory-label.pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(windowURL);
      } else {
        const data = await response.json();
        console.log(data.error);
        setError(data.error.message);
      }
    } catch (error) {
      setError("Unknown Error");
      console.log(error);
    }
  }

  return (
    <Container fluid="sm" style={{ maxWidth: "600px" }}>
      <h1>Create Inventory Label</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form>
        {!inventoryItem && (
          <Form.Row>
            <Button variant="primary" onClick={() => setShowItemModal(true)}>
              Choose Material
            </Button>
          </Form.Row>
        )}
        {inventoryItem && (
          <Form.Group>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>
                  {inventoryItem ? `Qty of ${inventoryItem.code}` : "Qty"}
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Type an integer qty"
                  value={qty}
                  step={1}
                  min={1}
                  onChange={(e) => setQty(e.target.value)}
                />
              </Form.Group>
            </Form.Row>
            <Button
              variant="primary"
              onClick={() => createLabel(inventoryItem, qty)}
            >
              Create Label
            </Button>
            <Button
              variant="secondary"
              style={{ marginLeft: "5px" }}
              onClick={() => setInventoryItem(null)}
            >
              Reset
            </Button>
          </Form.Group>
        )}
      </Form>
      {showItemModal && (
        <InventoryItemSelectorModal
          handleClose={() => setShowItemModal(false)}
          callback={(inventoryItem) => {
            setInventoryItem(inventoryItem);
            setShowItemModal(false);
          }}
        />
      )}
    </Container>
  );
};

export default InventoryLabel;
