import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Alert from "react-bootstrap/alert";
import Form from "react-bootstrap/form";
import Col from "react-bootstrap/col";
import Button from "react-bootstrap/button";
import Table from "react-bootstrap/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import InventoryTransferItem from "./inventoryTransferItem";
import InventoryItemSelectorModal from "./inventoryItemSelectorModal";
import ConfirmDeleteModal from "./confirmDeleteModal";

import { renewAuthenticationToken } from "../authenticationUtils";
import getWarehouses from "../warehouses";
import { formatDate } from "../helpers";

const InventoryTransfer = () => {
  const params = useParams();
  const navigate = useNavigate();

  const warehouses = getWarehouses();

  //STATE
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transfer, setTransfer] = useState(null);
  const [requestedByName, setRequestedByName] = useState(null);
  const [checkedByName, setCheckedByName] = useState(null);
  const [receivedByName, setReceivedByName] = useState(null);
  const [changedFromWarehouseId, setChangedFromWarehouseId] = useState(null);
  const [changedToWarehouseId, setChangedToWarehouseId] = useState(null);
  const [changedNote, setChangedNote] = useState(null);
  const [checkedByChanged, setCheckedByChanged] = useState(false);
  const [showAddNewItemModal, setShowAddNewItemModal] = useState(false);
  const [deviatedItems, setDeviatedItems] = useState([]); //collection of id/qtyMatch(bool) objects that represent items that have deviated from initial state
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemCallback, setDeleteItemCallback] = useState(null);

  useEffect(() => {
    async function fetchInventoryTransfer() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
        },
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BASE_URL}/v2/inventory-transfers/${params.id}`;
      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        return { status: response.status, data: data };
      } catch (error) {
        throw error;
      }
    }

    async function fetchUser(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
        },
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BASE_URL}/v2/users/${id}`;
      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        return { status: response.status, data: data };
      } catch (error) {
        throw error;
      }
    }

    async function handleFetchName(id, callback) {
      try {
        let result = await fetchUser(id);
        if (result.status === 401) {
          await renewAuthenticationToken();
          result = await fetchUser(id);
        }

        if (result.status === 200) {
          callback(result.data.name);
        } else {
          //not going to do much error handling for fetching a user's name in the background
          console.log(result);
        }
      } catch (error) {
        //not going to do anything in the UX
        console.log(error.message);
      }
    }

    async function handleFetchInventoryTransfer() {
      setLoading(true);
      setError(null);

      try {
        let result = await fetchInventoryTransfer();
        if (result.status === 401) {
          await renewAuthenticationToken();
          result = await fetchInventoryTransfer();
        }

        switch (result.status) {
          case 200:
            setTransfer(result.data);
            handleFetchName(result.data.requestedBy, setRequestedByName);
            if (result.data.checkedBy) {
              handleFetchName(result.data.checkedBy, setCheckedByName);
            }
            if (result.data.receivedBy) {
              handleFetchName(result.data.receivedBy, setReceivedByName);
            }
            break;
          case 404:
            setError("No inventory transfer found");
            break;
          default:
            setError("An unexpected error occurred");
            break;
        }
      } catch (error) {
        console.log(error.message);
        setError("An unexpected error occurred");
      }
      setLoading(false);
    }
    handleFetchInventoryTransfer();
  }, []); //on first render

  async function handleUpdateInventoryTransfer() {
    //build the object
    const updatedObject = {};
    if (changedFromWarehouseId) {
      updatedObject.fromWarehouseId = changedFromWarehouseId;
    }
    if (changedToWarehouseId) {
      updatedObject.toWarehouseId = changedToWarehouseId;
    }
    if (changedNote) {
      updatedObject.note = changedNote;
    }
    if (checkedByChanged) {
      updatedObject.checked = true;
    }

    setLoading(true);
    setError(null);

    try {
      let result = await updateInventoryTransfer(updatedObject);
      if (result.status === 401) {
        await renewAuthenticationToken();
        result = await updateInventoryTransfer(updatedObject);
      }
      if (result.status === 200) {
        navigate(0); //reload page
      } else {
        console.log(result.data.error.message);
        setError(result.data.error.message);
      }
    } catch (error) {
      console.log(error.message);
      setError("An unexpected error occurred");
    }
    setLoading(false);
  }

  function handleChangeFromWarehouse(newFromWarehoueseId) {
    if (newFromWarehoueseId === transfer.fromWarehouseId) {
      setChangedFromWarehouseId(null);
    } else {
      setChangedFromWarehouseId(newFromWarehoueseId);
    }
  }

  function handleChangeToWarehouse(newToWarehouseId) {
    if (newToWarehouseId === transfer.toWarehouseId) {
      setChangedToWarehouseId(null);
    } else {
      setChangedToWarehouseId(newToWarehouseId);
    }
  }

  function handleChangedNote(newNote) {
    if (newNote === transfer.note) {
      setChangedNote(null);
    } else {
      setChangedNote(newNote);
    }
  }

  function handleShowAddNewItemModal() {
    setShowAddNewItemModal(true);
  }

  function handleCloseAddNewItemModal() {
    setShowAddNewItemModal(false);
  }

  function handleItemDeviated(deviatedItem) {
    const newDeviatedItems = [...deviatedItems];
    const index = newDeviatedItems.findIndex(
      (item) => deviatedItem.id === item.id
    );
    if (index !== -1) {
      newDeviatedItems[index] = deviatedItem;
    } else {
      newDeviatedItems.push(deviatedItem);
    }
    setDeviatedItems(newDeviatedItems);
  }

  async function handleAddNewItemSelected(inventoryItem) {
    //takes an inventory item from the selected item in the modal and coordinates turning it into a new transfer item
    setLoading(true);
    setError(null);

    const transferItem = {
      inventoryId: inventoryItem.id,
      inventoryCode: inventoryItem.code,
      measured: inventoryItem.measured,
      ...(inventoryItem.description
        ? { description: inventoryItem.description }
        : {}), //description could be null
      ...(inventoryItem.type ? { type: inventoryItem.type } : {}), //type could be null
      ...(inventoryItem.pipeSize ? { pipeSize: inventoryItem.pipeSize } : {}),
      ...(inventoryItem.thickness
        ? { thickness: inventoryItem.thickness }
        : {}),
      qty: 0,
      qtyPicked: 0,
    };

    try {
      let result = await createNewInventoryTransferItem(transferItem);
      if (result.status === 401) {
        await renewAuthenticationToken();
        result = await createNewInventoryTransferItem(transferItem);
      }

      switch (result.status) {
        case 201:
          navigate(0); //reload page
          break;
        case 400:
          setError(result.data.error.message);
          break;
        default:
          console.log(result.data.error.message);
          setError("An unexpected error occurred");
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
    setLoading(false);
    setShowAddNewItemModal(false);
  }

  async function handleReceivedTransfer() {
    setLoading(true);
    setError(null);

    try {
      let result = await updateInventoryTransfer({ received: true });
      if (result.status === 401) {
        await renewAuthenticationToken();
        result = await updateInventoryTransfer({ received: true });
      }

      switch (result.status) {
        case 200:
          navigate(0); //reload page
          break;
        case 400:
          setError(result.data.error.message);
          break;
        default:
          console.log(result.data.error?.message);
          setError("An unexpected error occurred");
      }
    } catch (error) {
      console.log(error.message);
      setError("An unexpected error occurred");
    }

    setLoading(false);
  }

  async function handlePrintLabel() {
    //if we already have a label availble for this particular transfer then just show it, else generate it and show the new one
    let pdfId = transfer.pdfs?.find((pdf) => pdf.subType === 1)?.id; //will return the id of the FIRST shipping pdf

    if (!pdfId) {
      //we couldn't find an existing one, so we need to have the server create a new pdf
      try {
        let result = await createPDF();
        if (result.status === 401) {
          await renewAuthenticationToken();
          result = await createPDF();
        }
        switch (result.status) {
          case 201:
            pdfId = result.data.id;
            break;
          case 400:
          case 404:
            setError(result.data.error.message);
            return; //so it doesn't try to open a new tab
          default:
            setError("An unexpected error occurred");
            return;
        }
      } catch (error) {
        console.log(error);
        setError(error.message);
      }
    }

    //now try to show the pdf in a new tab
    const save = document.createElement("a");
    save.href = process.env.REACT_APP_BASE_URL + `/v2/pdfs/${pdfId}`;
    save.click();
    save.remove();
  }

  function transferHasChanged() {
    if (
      changedFromWarehouseId ||
      changedToWarehouseId ||
      changedNote ||
      checkedByChanged
    ) {
      return true;
    }
    return false;
  }

  function allQtyMatch() {
    //checks to make sure the qtyPicked is equal to the requested qty for all items
    for (let i = 0; i < transfer.items.length; i++) {
      //first see if this item is in the collection of items that have deviated
      let deviatedItem = deviatedItems.find(
        (item) => item.id === transfer.items[i].id
      );
      if (deviatedItem?.qtyMatch) {
        continue;
      } else if (deviatedItem) {
        //there was a deviatedItem that matched but the qtyMatch was false
        return false;
      }
      if (transfer.items[i].qty !== transfer.items[i].qtyPicked) {
        return false;
      }
    }
    return true;
  }

  function checkDisabled() {
    if (transfer.items.length === 0) {
      return true;
    }
    return !allQtyMatch();
  }

  async function updateInventoryTransfer(updatedObject) {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify(updatedObject),
    };

    const url = `${process.env.REACT_APP_BASE_URL}/v2/inventory-transfers/${transfer.id}`;
    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      throw error;
    }
  }

  async function createNewInventoryTransferItem(transferItem) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify(transferItem),
    };

    const url = `${process.env.REACT_APP_BASE_URL}/v2/inventory-transfers/${transfer.id}/items`;
    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      throw error;
    }
  }

  async function createPDF() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    const url = `${process.env.REACT_APP_BASE_URL}/v2/inventory-transfers/${transfer.id}/pdfs`;
    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      throw error;
    }
  }

  function handleItemDeleteConfirmed() {
    setShowDeleteModal(false);
    deleteItemCallback();
  }

  function handleTrashIconPressed(callback) {
    setDeleteItemCallback(() => callback);
    setShowDeleteModal(true);
  }

  return (
    <div>
      <h1>Inventory Transfer #{params.id}</h1>
      {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
      {error && <Alert variant="danger">{error}</Alert>}
      {transfer && (
        <div>
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Created On</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={formatDate(transfer.createdOn)}
                  readOnly
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Created By</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={requestedByName ?? "Loading name..."}
                  readOnly
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>From Warehouse</Form.Label>
                <Form.Control
                  as="select"
                  value={changedFromWarehouseId ?? transfer.fromWarehouseId}
                  onChange={(e) =>
                    handleChangeFromWarehouse(parseInt(e.target.value))
                  }
                  disabled={transfer.checkedBy ?? checkedByChanged}
                >
                  {warehouses.map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>To Warehouse</Form.Label>
                <Form.Control
                  as="select"
                  value={changedToWarehouseId ?? transfer.toWarehouseId}
                  onChange={(e) =>
                    handleChangeToWarehouse(parseInt(e.target.value))
                  }
                  disabled={transfer.checkedBy ?? checkedByChanged}
                >
                  {warehouses.map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <Form.Label>Note</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={changedNote ?? transfer.note ?? ""}
                onChange={(e) => handleChangedNote(e.target.value)}
              />
            </Form.Group>
            {!transfer.checkedBy && (
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Checked"
                  disabled={checkDisabled()}
                  checked={checkedByChanged}
                  onChange={(e) => setCheckedByChanged(e.target.checked)}
                />
              </Form.Group>
            )}
            {transfer.checkedBy && (
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Checked By</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={checkedByName ?? "Loading name..."}
                    readOnly
                  />
                </Form.Group>
              </Form.Row>
            )}
            {transfer.receivedBy && (
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Received By</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={receivedByName ?? "Loading name..."}
                    readOnly
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Received On</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={formatDate(transfer.receivedOn)}
                    readOnly
                  />
                </Form.Group>
              </Form.Row>
            )}
          </Form>
          <Button
            disabled={!transferHasChanged()}
            onClick={handleUpdateInventoryTransfer}
          >
            Update
          </Button>
          {transfer.checkedBy && (
            <Button
              variant="secondary"
              style={{ marginLeft: "5px" }}
              onClick={handlePrintLabel}
            >
              Print Label
            </Button>
          )}
          <hr></hr>
          <div>
            <h2>Items</h2>
            <Table striped>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Description</th>
                  <th>Size</th>
                  <th>Unit</th>
                  <th>#</th>
                  <th># Picked</th>
                  <th># Available</th>
                  {!transfer.checkedBy && !checkedByChanged && <th></th>}
                </tr>
              </thead>
              <tbody>
                {transfer.items.map((item) => (
                  <InventoryTransferItem
                    key={item.id}
                    item={item}
                    locationId={transfer.fromWarehouseId}
                    checked={transfer.checkedBy ?? checkedByChanged}
                    itemDeviatedCallback={handleItemDeviated}
                    handleDeleteIconPressed={handleTrashIconPressed}
                  />
                ))}
              </tbody>
            </Table>
            {!transfer.checkedBy && !checkedByChanged && (
              <Button onClick={handleShowAddNewItemModal}>Add New Item</Button>
            )}
            {!transfer.receivedBy && transfer.checkedBy && (
              <Button variant="secondary" onClick={handleReceivedTransfer}>
                Receive Transfer
              </Button>
            )}
            {showAddNewItemModal && (
              <InventoryItemSelectorModal
                handleClose={handleCloseAddNewItemModal}
                callback={(inventoryItem) => {
                  handleAddNewItemSelected(inventoryItem);
                  setShowAddNewItemModal(false);
                }}
              />
            )}
            {showDeleteModal && (
              <ConfirmDeleteModal
                handleClose={() => setShowDeleteModal(false)}
                handleDelete={handleItemDeleteConfirmed}
              />
            )}
          </div>
        </div>
      )}
      {!transfer && loading && <p>Loading inventory transfer...</p>}
    </div>
  );
};

export default InventoryTransfer;
