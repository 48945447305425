import React from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faLock,
  faLockOpen,
} from "@fortawesome/free-solid-svg-icons";

import TimesheetItemListItem from "./timesheetItemListItem";
import CreateTimesheetItemModal from "./createTimesheetItemModal";

class TimesheetListItem extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [Function]
	timesheet: [Object] timesheet object

	STATE:
	expanded: [Bool] whether accordion is expanded
	loading: [Bool] whether any loading is in progress
	error: [String] string that contains an error message
	items: [Array [Object]] array of timesheetItem objects
	suggestedItems: [Array [Object]] copied from props.timesheet.suggestedItems
	showModal: [Bool] whether to show the modal that allows users to add a new item
	suggested: [Bool] copied from props.timesheet.suggested, this tells us if this object is purely a suggestion or if in trinty yet
	id: [Int] copied from props.timesheet.id, but can get updated on a new timesheet from suggested creation,
	showSuggestionTable: [Bool] whether to show the suggestion table or not

	TODO:
	*/

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      loading: false,
      error: null,
      items: this.props.timesheet.suggested
        ? this.props.timesheet.suggestedItems
        : [],
      suggestedItems: this.props.timesheet.suggestedItems || [],
      showModal: false,
      suggested: this.props.timesheet.suggested,
      id: this.props.timesheet.id,
      showSuggestionTable: false,
    };

    this.handleAccordionToggle = this.handleAccordionToggle.bind(this);
    this.handleViewSuggestion = this.handleViewSuggestion.bind(this);
    this.handleAddNewClicked = this.handleAddNewClicked.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleNewTimesheetItemSuccess =
      this.handleNewTimesheetItemSuccess.bind(this);
    this.handleItemDeleted = this.handleItemDeleted.bind(this);
    this.handleSaveNewClicked = this.handleSaveNewClicked.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return { suggestedItems: props.timesheet.suggestedItems || [] };
  }

  handleAccordionToggle() {
    if (!this.state.expanded && !this.state.suggested) {
      this.loadTimeSheetItems();
    }
    this.setState((state, props) => {
      return { expanded: !state.expanded };
    });
  }

  handleViewSuggestion() {
    this.setState(function (state, props) {
      return { showSuggestionTable: !state.showSuggestionTable };
    });
  }

  handleAddNewClicked() {
    this.setState({
      showModal: true,
    });
  }

  handleModalClose() {
    this.setState({
      showModal: false,
    });
  }

  handleNewTimesheetItemSuccess(timesheetItem) {
    this.setState(function (state, props) {
      let items = [timesheetItem].concat(state.items);
      return { items: items };
    });
  }

  handleItemDeleted(itemId) {
    this.setState(function (state, props) {
      let originalItems = state.items;

      let newItems = originalItems.filter(function (value, index, array) {
        return value.id !== itemId;
      });

      return { items: newItems };
    });
  }

  async handleSaveNewClicked() {
    this.setState({
      loading: true,
      error: null,
    });

    //first create a new timesheet object
    //then create new timesheet items for each suggested item

    try {
      let response = await this.createNewTimesheet();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.createNewTimesheet();
      }

      switch (response.status) {
        case 201:
          //happy path
          this.setState({
            id: response.data.id,
          });
          break;

        case 404:
        case 400:
          this.setState({
            loading: false,
            error: response.data.error.message,
          });
          return;

        default:
          this.setState({
            loading: false,
            error: "An unexpected error occurred",
          });
          return;
      }

      //now try to iteratively create the timesheet items
      let promises = [];
      for (let i = 0; i < this.state.suggestedItems.length; i++) {
        promises.push(
          this.createNewTimesheetItem(
            response.data.id,
            this.state.suggestedItems[i]
          )
        );
      }
      let results = await Promise.all(promises);

      let items = [];

      for (let i = 0; i < results.length; i++) {
        switch (results[i].status) {
          case 201:
            //happy path
            this.state.suggestedItems[i].id = results[i].data.id;
            items.push(this.state.suggestedItems[i]);
            break;

          case 400:
          case 404:
            this.setState({
              loading: false,
              error: results[i].data.error.message,
              suggested: false,
            });
            return;

          default:
            this.setState({
              loading: false,
              error: "An unexpected error occurred",
              suggested: false,
            });
            return;
        }
      }
      this.setState({
        loading: false,
        items: items,
        suggestedItems: [],
        suggested: false,
      }); //this only gets fired if all items return 201
    } catch (error) {
      console.log(error.message);
      this.setState({
        loading: false,
        error: "An unexpected error occurred",
      });
    }
  }

  async loadTimeSheetItems() {
    this.setState({
      error: null,
      loading: true,
    });
    try {
      let response = await this.fetchTimeSheet();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchTimeSheet();
      }

      if (response.status === 200) {
        this.setState({
          loading: false,
          items: response.data.items,
        });
      } else {
        console.log(response.data.error.message);
        this.setState({
          loading: false,
          error: "An unexpected error occurred",
        });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        loading: false,
        error: "An unexpected error occurred",
      });
    }
  }

  async fetchTimeSheet() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    const url = `${process.env.REACT_APP_BASE_URL}/v2/timesheets/${this.state.id}`;
    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error ocurred",
      });
    }
  }

  async createNewTimesheet() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify({
        weekEndingDateTime: this.props.timesheet.weekEndingDateTime,
        employeeId: this.props.timesheet.employeeId,
      }),
    };

    const url = `${process.env.REACT_APP_BASE_URL}/v2/timesheets`;
    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error ocurred",
      });
    }
  }

  async createNewTimesheetItem(id, item) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify(item),
    };

    const url = `${process.env.REACT_APP_BASE_URL}/v2/timesheets/${id}/items`;
    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error ocurred",
      });
    }
  }

  renderBadge() {
    const style = { marginLeft: 2 };
    if (this.state.suggested) {
      return (
        <Badge variant="primary" style={style}>
          New
        </Badge>
      );
    } else if (this.state.suggestedItems?.length > 0) {
      return (
        <Badge variant="secondary" style={style}>
          Updates
        </Badge>
      );
    } else {
      return;
    }
  }

  renderActionButton() {
    if (this.state.suggested) {
      return (
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            padding: 0,
          }}
        >
          <Button
            variant="primary"
            onClick={this.handleSaveNewClicked}
            disabled={this.state.loading}
          >
            {this.state.loading ? "Loading..." : "Save Timesheet"}
          </Button>
        </Container>
      );
    } else {
      return (
        <Container
          style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
        >
          {this.state.suggestedItems.length > 0 ? (
            <Button variant="secondary" onClick={this.handleViewSuggestion}>
              View Suggestion
            </Button>
          ) : null}
          <Button
            style={{ marginLeft: 2 }}
            variant="primary"
            onClick={this.handleAddNewClicked}
            disabled={this.props.timesheet.locked}
          >
            Add New Item
          </Button>
        </Container>
      );
    }
  }

  renderSuggestionTable() {
    return (
      <div>
        <h3>Suggested Entries</h3>
        <Table striped bordered hover size="sm" responsive="md">
          <thead>
            <tr>
              <th>Date</th>
              <th>Job</th>
              <th>Hours</th>
              <th>Overtime</th>
              <th>Travel</th>
              <th>Bonus</th>
            </tr>
          </thead>
          <tbody>
            {this.state.suggestedItems.map((item) => (
              <TimesheetItemListItem
                renewAuthenticationToken={this.props.renewAuthenticationToken}
                handleDeleteItem={this.handleItemDeleted}
                key={item.id}
                item={item}
                editable={false}
              />
            ))}
          </tbody>
        </Table>
        {this.state.loading ? (
          <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
        ) : null}
      </div>
    );
  }

  renderDetails() {
    if (this.state.items.length === 0) {
      if (this.state.loading) {
        return <FontAwesomeIcon icon={faSpinner} className="fa-spin" />;
      }
      return <p>No timesheet items</p>;
    } else {
      return (
        <div>
          <Table striped bordered hover size="sm" responsive="md">
            <thead>
              <tr>
                <th>Date</th>
                <th>Job</th>
                <th>Hours</th>
                <th>Overtime</th>
                <th>Travel</th>
                <th>Bonus</th>
              </tr>
            </thead>
            <tbody>
              {this.state.items.map((item) => (
                <TimesheetItemListItem
                  renewAuthenticationToken={this.props.renewAuthenticationToken}
                  handleDeleteItem={this.handleItemDeleted}
                  key={item.id}
                  item={item}
                  editable={
                    !this.props.timesheet.locked && !this.state.suggested
                  }
                />
              ))}
              <tr>
                <td style={{ fontWeight: "bold" }}>Totals</td>
                <td></td>
                <td style={{ fontWeight: "bold" }}>
                  {this.state.items.reduce((total, item) => {
                    return total + item.hours;
                  }, 0)}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {this.state.items.reduce((total, item) => {
                    return total + item.overtimeHours;
                  }, 0)}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  $
                  {this.state.items.reduce((total, item) => {
                    return total + item.travel;
                  }, 0)}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  $
                  {this.state.items.reduce((total, item) => {
                    return total + item.bonus;
                  }, 0)}
                </td>
              </tr>
            </tbody>
          </Table>
          {this.state.loading ? (
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          ) : null}
        </div>
      );
    }
  }

  render() {
    return (
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={this.props.timesheet.id}
          onClick={this.handleAccordionToggle}
        >
          <Container fluid>
            <Row>
              <Col>
                {this.props.timesheet.employeeName}
                {this.renderBadge()}
              </Col>
              {this.props.timesheet.locked ? (
                <FontAwesomeIcon icon={faLock} />
              ) : (
                <FontAwesomeIcon icon={faLockOpen} />
              )}
            </Row>
          </Container>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={this.props.timesheet.id}>
          <Card.Body>
            <Alert variant="danger" show={this.state.error}>
              {this.state.error}
            </Alert>
            {this.renderDetails()}
            {this.state.showSuggestionTable
              ? this.renderSuggestionTable()
              : null}
            {this.renderActionButton()}
          </Card.Body>
        </Accordion.Collapse>
        {this.state.showModal ? (
          <CreateTimesheetItemModal
            renewAuthenticationToken={this.props.renewAuthenticationToken}
            show={this.state.showModal}
            timesheetId={this.state.id}
            handleClose={this.handleModalClose}
            handleSuccess={this.handleNewTimesheetItemSuccess}
          />
        ) : null}
      </Card>
    );
  }
}

export default TimesheetListItem;
