import React from "react";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import UserForm from "./userForm";

class CreateUserModal extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [Function] renews auth token
	show: [Bool] whether or not to show
	handleClose: [Function] callback on close
	handleSuccess: [Function] callback on success

	STATE:
	loading: [Bool] whether or not loading is occurring
	success: [Bool] true when one is successfully created
	error: [String] error message if one is returned
	name: [String] name of new user
	email: [String] email of new user
	password: [String] password of new user
	password2: [String] password copy of new user (used to double check)
	admin: [Bool] whether or not new user is an admin

	TODO:
	*/

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      success: false,
      error: null,
      name: "",
      email: "",
      password: "",
      password2: "",
      admin: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose() {
    this.setState({
      loading: false,
      success: false,
      error: null,
      name: "",
      email: "",
      password: "",
      password2: "",
      admin: false,
    }); //reset state

    this.props.handleClose(); //call parents callback
  }

  handleSuccess(userId) {
    //need to send the new user object to the parent then handle close

    this.props.handleSuccess({
      id: userId,
      name: this.state.name,
      email: this.state.email,
      admin: this.state.admin,
    });

    this.handleClose();
  }

  handleChange(key, e) {
    let value = e.target.value;
    if (key === "admin") {
      value = e.target.checked;
    }

    let returnObject = {};
    returnObject[key] = value;
    this.setState(returnObject);
  }

  async handleSubmit() {
    this.setState({
      error: null,
      loading: true,
    });

    try {
      let response = await this.createNewUser();
      if (response.status === 401) {
        //try renewing token
        await this.props.renewAuthenticationToken();
        response = await this.createNewUser();
      }

      if (response.status === 201) {
        //successful happy path
        this.setState({
          loading: false,
          success: true,
        });
        setTimeout(() => this.handleSuccess(response.data.id), 1500);
      } else {
        this.setState({
          loading: false,
          error: response.data.error.message,
        });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        loading: false,
        error: "An unexpected error occurred",
      });
    }
  }

  async createNewUser() {
    let body = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      admin: this.state.admin,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify(body),
    };

    let url = process.env.REACT_APP_BASE_URL + "/v2/users/";

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: error.message,
      });
    }
  }

  render() {
    var modalProps = {
      size: "lg",
    };
    if (this.state.loading) {
      modalProps.backdrop = "static";
      modalProps.keyboard = false;
    }
    return (
      <Modal {...modalProps} show={this.props.show} onHide={this.handleClose}>
        <Modal.Header closeButton={!this.state.loading}>
          <Modal.Title>New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={this.state.success}>
            User Added!
          </Alert>
          <Alert variant="danger" show={this.state.error}>
            {this.state.error}
          </Alert>
          <UserForm
            name={this.state.name}
            email={this.state.email}
            emailReadOnly={false}
            password={this.state.password}
            password2={this.state.password2}
            admin={this.state.admin}
            handleChange={this.handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.handleClose}
            disabled={this.state.loading}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={this.handleSubmit}
            disabled={
              this.state.name === "" ||
              this.state.email === "" ||
              this.state.password === "" ||
              this.state.password2 === "" ||
              this.state.password !== this.state.password2 ||
              this.state.loading ||
              this.state.success
            }
          >
            {this.state.loading ? "Loading..." : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CreateUserModal;
