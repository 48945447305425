import React from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import EmployeeForm from "./employeeForm";

class EmployeeListItem extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [Function]
	employee: [Object] employee object

	STATE:
	tSheetsUserId: [Int or Null] tSheetsUserId can be null (value typed in)
	confirmedTSheetsUserId: [Int or null] tSheetsUserId that matches the most recent update
	loading: [Bool] whether any loading is in progress
	error: [String] string that contains an error message

	TODO:
	*/

  constructor(props) {
    super(props);

    this.state = {
      tSheetsUserId: this.props.employee.tSheetsUserId || "",
      confirmedTSheetsUserId: this.props.employee.tSheetsUserId || "",
      loading: false,
      error: null,
    };

    this.handleChangeTSheetsUserId = this.handleChangeTSheetsUserId.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  handleChangeTSheetsUserId(tSheetsUserId) {
    this.setState({
      tSheetsUserId: tSheetsUserId,
    });
  }

  async handleUpdate() {
    this.setState({ loading: true, error: null });

    try {
      let response = await this.updateEmployee();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.updateEmployee();
      }

      switch (response.status) {
        case 200:
          this.setState(function (state, props) {
            return {
              loading: false,
              confirmedTSheetsUserId: state.tSheetsUserId,
            };
          });
          break;
        case 404:
          this.setState({
            error: response.data.error.message,
            loading: false,
          });
          break;
        default:
          console.log(response.data.error.message);
          this.setState({
            error: "An unexpected error occurred",
            loading: false,
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
        loading: false,
      });
    }
  }

  async updateEmployee() {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify({ tSheetsUserId: this.state.tSheetsUserId }),
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_BASE_URL +
          `/v2/employees/${this.props.employee.id}`,
        requestOptions
      );
      const data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
      });
    }
  }

  updateButtonDisabled() {
    if (
      this.state.loading ||
      this.state.tSheetsUserId === this.state.confirmedTSheetsUserId ||
      this.state.tSheetsUserId === ""
    ) {
      return true;
    }
    return isNaN(this.state.tSheetsUserId); //this requires the tSheetsUserId to be an int before updating
  }

  render() {
    return (
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey={this.props.employee.id}>
          <Container fluid>
            <Row>
              <Col>{this.props.employee.name}</Col>
            </Row>
          </Container>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={this.props.employee.id}>
          <Card.Body>
            <Alert variant="danger" show={this.state.error}>
              {this.state.error}
            </Alert>
            <EmployeeForm
              renewAuthenticationToken={this.props.renewAuthenticationToken}
              name={this.props.employee.name}
              email={this.props.employee.email}
              tSheetsUserId={this.state.tSheetsUserId}
              handleChangeTSheetsUserId={this.handleChangeTSheetsUserId}
            />
            <Button
              variant="primary"
              onClick={this.handleUpdate}
              disabled={this.updateButtonDisabled()}
            >
              {this.state.loading ? "Loading..." : "Update"}
            </Button>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

export default EmployeeListItem;
