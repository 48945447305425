import React from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import UserForm from "./userForm";

class UserListItem extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [Function] renews auth token
	user: [Object] user object, gets copied to state

	STATE:
	error: [String] error message if any
	user: [Object] user object, copied from props on construction
	password: [String] plaintext string of user entered password for changing password flow
	password2: [String] plaintext string copy of user entered password for changing password flow
	changedKeyValuePairs: [Array [Object]] array of key/value pairs that are changed
	loading: [Bool] whether or not an update is in progress with the API

	TODO:
	*/

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      user: this.props.user,
      password: "",
      password2: "",
      changedKeyValuePairs: [],
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  handleChange(key, e) {
    if (key === "password2") {
      this.setState({
        password2: e.target.value,
      });
    } else {
      this.setState(function (state, props) {
        let changedKeyValuePairs = state.changedKeyValuePairs;

        let indexOfKey = changedKeyValuePairs.findIndex(
          (obj) => obj.name === key
        );

        let returnObj = {};

        let value;
        switch (key) {
          case "admin":
            value = e.target.checked;
            break;

          case "password":
            value = e.target.value;
            returnObj.password = e.target.value;
            break;

          default:
            value = e.target.value;
        }

        if (indexOfKey === -1) {
          //key is not already in list of changed key values
          changedKeyValuePairs.push({
            name: key,
            value: value,
          });
        } else {
          //update it
          changedKeyValuePairs[indexOfKey].value = value;
        }

        let user = state.user;
        user[key] = value;

        returnObj.user = user;
        returnObj.changedKeyValuePairs = changedKeyValuePairs;

        return returnObj;
      });
    }
  }

  async handleUpdate() {
    this.setState({
      error: null,
      loading: true,
    });

    const updatedObject = {};
    this.state.changedKeyValuePairs.forEach((pair) => {
      updatedObject[pair.name] = pair.value;
    });

    try {
      let response = await this.updateUser(updatedObject);
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.updateUser(updatedObject);
      }

      switch (response.status) {
        case 200:
          this.setState({
            changedKeyValuePairs: [],
            loading: false,
            password: "",
            password2: "",
          });
          break;

        case 400:
          this.setState({
            error: response.data.error.message,
            loading: false,
          });
          break;

        default:
          this.setState({
            error: "An unexpected error occurred",
            loading: false,
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
        loading: false,
      });
    }
  }

  async updateUser(updatedObject) {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify(updatedObject),
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + `/v2/users/${this.props.user.id}`,
        requestOptions
      );
      const data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
      });
    }
  }

  getBadge() {
    let style = {};
    if (this.state.user.admin) {
      return (
        <Badge variant="primary" style={style}>
          Admin
        </Badge>
      );
    } else {
      return;
    }
  }

  render() {
    return (
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey={this.props.user.id}>
          <Container fluid>
            <Row>
              <Col>
                {this.props.user.name} {this.getBadge()}
              </Col>
            </Row>
          </Container>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={this.props.user.id}>
          <Card.Body>
            <Alert variant="danger" show={this.state.error}>
              {this.state.error}
            </Alert>
            <UserForm
              name={this.state.user.name}
              email={this.state.user.email}
              emailReadOnly={true}
              password={this.state.password}
              password2={this.state.password2}
              admin={this.state.user.admin}
              handleChange={this.handleChange}
            />
            <Button
              variant="primary"
              onClick={this.handleUpdate}
              disabled={
                this.state.changedKeyValuePairs.length === 0 ||
                this.state.loading ||
                this.state.password !== this.state.password2
              }
            >
              {this.state.loading ? "Loading..." : "Update"}
            </Button>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

export default UserListItem;
