import React from "react";
import Alert from "react-bootstrap/Alert";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import InfiniteScroll from "react-infinite-scroll-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import EmployeeListItem from "./employeeListItem";

const LIST_LIMIT = 25; //number to retrieve per query

class EmployeeList extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [Function]

	STATE:
	error: [String] whether or not an error message is present
	loading: [Bool] whether or not the list is loading
	employees: [Array [Object]] array of employee objects
	nextPage: [Int] index for what the next page of the paginated list to load is (indexes from 0)
	hasMore: [Bool] whether or not there are more employees to be loaded
	needsTSheetsUserId: [Bool] if true then employee does NOT have a tsheets user id assigned

	TODO:
	*/

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      employees: [],
      nextPage: 0,
      hasMore: true,
      needsTSheetsUserId: true,
    };

    this.handleChangeNeedsTSheetsUserId =
      this.handleChangeNeedsTSheetsUserId.bind(this);
    this.handleFetchEmployees = this.handleFetchEmployees.bind(this);
    this.handleFetchMoreEmployees = this.handleFetchMoreEmployees.bind(this);
  }

  componentDidMount() {
    this.handleFetchEmployees();
  }

  handleChangeNeedsTSheetsUserId() {
    this.setState(function (state, props) {
      return {
        needsTSheetsUserId: !state.needsTSheetsUserId,
        nextPage: 0,
        hasMore: true,
      };
    }, this.handleFetchEmployees);
  }

  async handleFetchEmployees() {
    this.setState({
      error: null,
      loading: true,
    });

    try {
      let response = await this.fetchEmployees();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchEmployees();
      }
      switch (response.status) {
        case 200:
          this.setState(function (state, props) {
            return {
              employees: response.data.employees,
              hasMore: response.data.more,
              nextPage: state.nextPage + 1,
              loading: false,
            };
          });
          break;
        case 404:
          this.setState({
            hasMore: false,
            loading: false,
            error: response.data.error.message,
            employees: [],
          });
          break;
        default:
          console.log(response.data.error.message);
          this.setState({
            hasMore: false,
            loading: false,
            error: "An unexpected error occurred",
          });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        hasMore: false,
        loading: false,
        error: "An unexpected error occurred",
      });
    }
  }

  async handleFetchMoreEmployees() {
    this.setState({
      error: null,
      loading: true,
    });

    try {
      let response = await this.fetchEmployees();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchEmployees();
      }

      switch (response.status) {
        case 200:
          //happy path
          this.setState(function (state, props) {
            let employees = state.employees.concat(response.data.employees);
            return {
              employees: employees,
              hasMore: response.data.more,
              nextPage: state.nextPage + 1,
              loading: false,
            };
          });
          break;
        case 404:
          this.setState({
            hasMore: false,
            loading: false,
          });
          break;
        default:
          console.log(response.data.error.message);
          this.setState({
            hasMore: false,
            loading: false,
            error: "An unexpected error occurred",
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        hasMore: false,
        loading: false,
        error: "An unexpected error occurred",
      });
    }
  }

  async fetchEmployees() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    const url =
      `${process.env.REACT_APP_BASE_URL}/v2/employees` +
      `?active=true&tSheetsUserId=${!this.state
        .needsTSheetsUserId}&limit=${LIST_LIMIT}&offset=${
        LIST_LIMIT * this.state.nextPage
      }`;
    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error ocurred",
      });
    }
  }

  calculateScrollHeight() {
    //Calculates height of infinite scroll, MAX: 1000, MIN:300
    let height = this.state.employees.length * 50 + 300;
    if (height > 1000) {
      height = 1000;
    }
    return height;
  }

  render() {
    return (
      <div>
        <h1>Employees</h1>
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Check
                type="checkbox"
                label="Needs TSheets User Id"
                checked={this.state.needsTSheetsUserId}
                onChange={this.handleChangeNeedsTSheetsUserId}
              />
            </Form.Group>
          </Form.Row>
        </Form>
        <Alert variant="danger" show={this.state.error}>
          {this.state.error}
        </Alert>
        {this.state.employees.length > 0 ? (
          <InfiniteScroll
            dataLength={this.state.employees.length}
            next={this.handleFetchMoreEmployees}
            hasMore={this.state.hasMore}
            loader={<FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
            height={this.calculateScrollHeight()}
          >
            <Accordion>
              {this.state.employees.map((employee) => (
                <EmployeeListItem
                  key={employee.id}
                  renewAuthenticationToken={this.props.renewAuthenticationToken}
                  employee={employee}
                />
              ))}
            </Accordion>
          </InfiniteScroll>
        ) : null}
      </div>
    );
  }
}

export default EmployeeList;
