import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faCheckSquare,
  faSpinner,
  faTimesCircle,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";

const WAIT_INTERVAL = 1000; //delay in MS: we use this to wait to send update request to server if user clicks multiple times

const statusIcons = {
  good: "good",
  loading: "loading",
  error: "error",
};

class MaterialSaleItemListItem extends React.Component {
  /*
	PROPS:
	item: [Object] item object
	checked: [Bool] whether sale is checked or not
	renewAuthenticationToken: [Function] renews token
	handleUpdateQuantiesMatch: [Function] parent's function to say whether the qty matches picked

	STATE:
	qtyPicked: [Int] qty picked for delivery
	changedKeyValuePairs: [Array [Object]] Array of objects of name/value pairs to be updated
	statusIcon: [String] can either be null, good, loading, or error

	TODO:
	*/

  constructor(props) {
    super(props);

    this.timer = null; //timer for staging updates to server

    this.state = {
      qtyPicked: this.props.item.qtyPicked,
      changedKeyValuePairs: [],
      statusIcon: null,
    };

    this.increasePicked = this.increasePicked.bind(this);
    this.decreasePicked = this.decreasePicked.bind(this);
    this.handlePickedTyped = this.handlePickedTyped.bind(this);
    this.handleUpdateItem = this.handleUpdateItem.bind(this);
  }

  increasePicked() {
    clearTimeout(this.timer);

    this.setState(function (state, props) {
      let key = "qtyPicked";
      let value = state.qtyPicked + 1;
      let changedKeyValuePairs = state.changedKeyValuePairs;
      let indexOfKey = changedKeyValuePairs.findIndex(
        (obj) => obj.name === key
      );

      if (indexOfKey === -1) {
        //if key is not already in list of changed keys
        changedKeyValuePairs.push({
          name: key,
          value: value,
        }); //add it to the list of keys
      } else {
        //key is already in list of changed keys, so we need to change that one
        changedKeyValuePairs[indexOfKey].value = value;
      }

      return {
        qtyPicked: value,
        changedKeyValuePairs: changedKeyValuePairs,
      };
    });

    this.timer = setTimeout(this.handleUpdateItem, WAIT_INTERVAL);
  }

  decreasePicked() {
    clearTimeout(this.timer);

    this.setState(function (state, props) {
      if (state.qtyPicked === 0) {
        //in this case we just need to return to prevent qty going less than 0
        return;
      }

      let key = "qtyPicked";
      let value = state.qtyPicked - 1;

      let changedKeyValuePairs = state.changedKeyValuePairs;
      let indexOfKey = changedKeyValuePairs.findIndex(
        (obj) => obj.name === key
      );

      if (indexOfKey === -1) {
        //key not already in list of keys
        changedKeyValuePairs.push({
          name: key,
          value: value,
        });
      } else {
        //key is already in list of keys
        changedKeyValuePairs[indexOfKey].value = value;
      }

      return {
        qtyPicked: value,
        changedKeyValuePairs: changedKeyValuePairs,
      };
    });

    this.timer = setTimeout(this.handleUpdateItem, WAIT_INTERVAL);
  }

  handlePickedTyped(e) {
    clearTimeout(this.timer);

    this.setState(function (state, props) {
      let key = "qtyPicked";
      let value = parseInt(e.target.value) || 0;
      if (value < 0) {
        value = 0;
      }

      let changedKeyValuePairs = state.changedKeyValuePairs;
      let indexOfKey = changedKeyValuePairs.findIndex(
        (obj) => obj.name === key
      );

      if (indexOfKey === -1) {
        //key not already in list of keys
        changedKeyValuePairs.push({
          name: key,
          value: value,
        });
      } else {
        //key is already in list of keys
        changedKeyValuePairs[indexOfKey].value = value;
      }

      return {
        qtyPicked: value,
        changedKeyValuePairs: changedKeyValuePairs,
      };
    });

    this.timer = setTimeout(this.handleUpdateItem, WAIT_INTERVAL);
  }

  handleError(errorMessage) {
    console.log(errorMessage);
    this.setState({
      statusIcon: statusIcons.error,
    });
  }

  async handleUpdateItem() {
    this.setState({
      statusIcon: statusIcons.loading,
    });

    const updatedObject = {};
    this.state.changedKeyValuePairs.forEach((pair) => {
      updatedObject[pair.name] = pair.value;
    });

    try {
      let response = await this.updateItem(updatedObject);

      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.updateItem(updatedObject);
      }

      if (response.status === 200) {
        this.setState({
          changedKeyValuePairs: [],
          statusIcon: statusIcons.good,
        });
        this.props.handleUpdateQuantities(
          this.props.item.number,
          this.state.qtyPicked
        );
      } else {
        //Error from server
        this.handleError(response.data.error.message);
      }
    } catch (error) {
      this.handleError(error.message);
    }
  }

  async updateItem(updatedObject) {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify(updatedObject),
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_BASE_URL +
          `/v2/material-sales/items/${this.props.item.number}`,
        requestOptions
      );
      const data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      this.handleError(error.message);
    }
  }

  renderNote() {
    let popover = (
      <Popover>
        <Popover.Title>Note</Popover.Title>
        <Popover.Content>{this.props.item.note}</Popover.Content>
      </Popover>
    );
    return (
      <OverlayTrigger trigger="click" placement="right" overlay={popover}>
        <FontAwesomeIcon icon={faClipboard} style={{ color: "red" }} />
      </OverlayTrigger>
    );
  }

  renderStatusIcon() {
    switch (this.state.statusIcon) {
      case statusIcons.good:
        return (
          <FontAwesomeIcon icon={faCheckSquare} style={{ color: "green" }} />
        );
      case statusIcons.loading:
        return <FontAwesomeIcon icon={faSpinner} className="fa-spin" />;
      case statusIcons.error:
        return (
          <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red" }} />
        );
      default:
        return;
    }
  }

  render() {
    return (
      <tr>
        <td>
          {this.props.item.inventoryCode} {this.renderStatusIcon()}
        </td>
        <td>
          {this.props.item.description}{" "}
          {this.props.item.note ? this.renderNote() : null}
        </td>
        <td>
          {this.props.item.pipeSize?.length > 1
            ? `${this.props.item.pipeSize} x ${this.props.item.thickness}`
            : ""}
        </td>
        <td>{this.props.item.measured}</td>
        <td>{this.props.item.qty || 0}</td>
        <td>
          {this.props.checked ? (
            this.state.qtyPicked || 0
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon onClick={this.decreasePicked} icon={faMinus} />
              <input
                type="text"
                value={this.state.qtyPicked ? this.state.qtyPicked : 0}
                onChange={this.handlePickedTyped}
                size="3"
                pattern="[0-9]*"
                inputMode="numeric"
              />
              <FontAwesomeIcon onClick={this.increasePicked} icon={faPlus} />
            </div>
          )}
        </td>
      </tr>
    );
  }
}

export default MaterialSaleItemListItem;
