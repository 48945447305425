import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import SignatureCanvas from "react-signature-canvas";

class ReceptionSignatureModal extends React.Component {
  /*
	PROPS:
	show: [Bool] whether or not to show
	urlExtension: [String] extension for urls for the object type (ex. material-orders)
	title: [String] modal title
	objectId: [Int] material order/sale object primary key
	onClose: [Function] callback to parent on close
	onSuccess: [Function] parent callback on success
	renewAuthenticationToken: [Function] renews the auth token

	STATE:
	error: [String] error message if any
	success: [Bool] whether or not its in a successfully complete condition
	loading: [Bool] if background processing is happening
	receivedBy: [String] name of who received the object (order / sale)
	hasDrawn: [Bool] whether or not any drawing on signature has happened

	TODO:
	*/

  constructor(props) {
    super(props);

    this.sigCanvasRef = React.createRef();

    this.state = {
      error: "",
      success: false,
      loading: false,
      receivedBy: "",
      hasDrawn: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChangeReceivedBy = this.handleChangeReceivedBy.bind(this);
    this.handleDrawBegin = this.handleDrawBegin.bind(this);
    this.handleClearCanvas = this.handleClearCanvas.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose() {
    this.props.onClose();
    this.setState({
      error: "",
      success: false,
      loading: false,
      receivedBy: "",
      hasDrawn: false,
    });
  }

  handleChangeReceivedBy(receivedBy) {
    this.setState({
      receivedBy: receivedBy,
    });
  }

  handleDrawBegin() {
    this.setState({
      hasDrawn: true,
    });
  }

  handleClearCanvas() {
    this.sigCanvasRef.clear();
    this.setState({
      hasDrawn: false,
    });
  }

  async handleSubmit() {
    /*
		Steps:

		1. Set state: clear errors and set to loading
		2. Update receivedBy
		3. Add signature
		4. Create PDF
		5. Get PDF
		6. Callback success
		*/
    this.setState({
      error: "",
      loading: true,
    });

    try {
      let response = await this.updateReceivedBy();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.updateReceivedBy();
      }
      if (response.status !== 200) {
        this.setState({
          loading: false,
          error: "An unexpected error occurred",
        });
        return;
      }

      response = await this.addSignature();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.addSignature();
      }
      if (response.status !== 201) {
        this.setState({
          loading: false,
          error: "An unexpected error occurred",
        });
        return;
      }

      response = await this.createPDF();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.createPDF();
      }
      if (response.status !== 201) {
        this.setState({
          loading: false,
          error: "An unexpected error occurred",
        });
        return;
      }

      let pdfId = response.data.id;

      this.setState({
        success: true,
        loading: false,
      });

      this.props.onSuccess(this.state.receivedBy); //callback

      let save = document.createElement("a");
      save.href = process.env.REACT_APP_BASE_URL + `/v2/pdfs/${pdfId}`;
      save.click();
      save.remove();
    } catch (error) {
      console.log(error.message);
      this.setState({
        loading: false,
        error: "An unexpected error occurred",
      });
    }
  }

  async updateReceivedBy() {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify({ receivedBy: this.state.receivedBy }),
    };

    const url =
      process.env.REACT_APP_BASE_URL +
      `/v2/${this.props.urlExtension}/${this.props.objectId}`;

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
      });
    }
  }

  async addSignature() {
    let signature = this.sigCanvasRef.toDataURL();
    signature = signature.split(",").pop();
    let body = {
      signature: signature,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify(body),
    };

    const url =
      process.env.REACT_APP_BASE_URL +
      `/v2/${this.props.urlExtension}/${this.props.objectId}/signature`;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
      });
    }
  }

  async createPDF() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    const url =
      process.env.REACT_APP_BASE_URL +
      `/v2/${this.props.urlExtension}/${this.props.objectId}/pdfs`;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
      });
    }
  }

  async getPDF(id) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    const url = process.env.REACT_APP_BASE_URL + `/v2/pdfs/${id}`;

    try {
      let response = await fetch(url, requestOptions);
      let blob = await response.blob();

      //get the filename from the content-disposition header
      //result: attachment; filename="5668_1613138795409.pdf"
      //need to strip off the first part and get just the filename
      let filename = response.headers.get("Content-Disposition");
      filename = filename.split('"');
      filename = filename.slice(1, -1);

      return { status: response.status, blob: blob, filename: filename };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
      });
    }
  }

  render() {
    var modalProps = {
      size: "lg",
    };
    if (this.state.loading) {
      modalProps.backdrop = "static";
      modalProps.keyboard = false;
    }
    return (
      <Modal {...modalProps} show={this.props.show} onHide={this.handleClose}>
        <Modal.Header closeButton={!this.state.loading}>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={this.state.success}>
            Success
          </Alert>
          <Alert variant="danger" show={this.state.error}>
            {this.state.error}
          </Alert>
          <Form>
            <Form.Group>
              <Form.Label>Received By</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name here..."
                value={this.state.receivedBy}
                onChange={(e) => this.handleChangeReceivedBy(e.target.value)}
              />
            </Form.Group>
          </Form>
          <div style={{ overflow: "auto" }}>
            <div>
              <SignatureCanvas
                canvasProps={{
                  height: 270,
                  width: 480,
                  style: { borderStyle: "solid", borderWidth: "1px" },
                }}
                ref={(ref) => (this.sigCanvasRef = ref)}
                onBegin={this.handleDrawBegin}
              />
            </div>
            <div>
              <Button
                variant="danger"
                onClick={this.handleClearCanvas}
                disabled={!this.state.hasDrawn}
              >
                Clear
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.handleClose}
            disabled={this.state.loading}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={this.handleSubmit}
            disabled={
              !this.state.receivedBy ||
              !this.state.hasDrawn ||
              this.state.loading ||
              this.state.success
            }
          >
            {this.state.loading ? "Loading..." : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ReceptionSignatureModal;
