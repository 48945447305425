export async function renewAuthenticationToken() {
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.sessionStorage.getItem("renewToken")}`,
    },
    credentials: "include",
  };

  try {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "/v2/users/tokens",
      requestOptions
    );
    const data = await response.json();

    if (response.status === 201) {
      //Successful login

      window.sessionStorage.setItem("authToken", data.authToken);

      return true;
    } else {
      logout();
    }
  } catch (error) {
    throw error;
  }
}

/** Logs a user in and sets the JWT tokens in sessionStorage
 * @async
 *
 * @param {String} email
 * @param {String} password
 *
 * @throws {Error}
 * @returns {Boolean}
 */
export async function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ email: email, password: password }),
  };

  try {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "/v2/users/tokens",
      requestOptions
    );
    const data = await response.json();

    if (response.status === 201) {
      //Successful login
      window.sessionStorage.setItem("authToken", data.authToken);
      window.sessionStorage.setItem("renewToken", data.renewToken);
      return true;
    } else {
      const err = new Error(data.error.message);
      err.otherErrors = data.errors;
      if (response.status === 401) {
        err.showMessage = true;
      }
      throw err;
    }
  } catch (error) {
    throw error;
  }
}

export function logout() {
  //removes tokens
  window.sessionStorage.removeItem("authToken");
  window.sessionStorage.removeItem("renewToken");

  window.localStorage.setItem("logout", new Date()); //this creates an event that syncs other open tabs to logout

  const params = new URL(window.location).searchParams;
  window.location.assign(
    `${window.location.origin}/login?redirect=${window.location.pathname.slice(
      1
    )}${params.toString() !== "" ? `&${params.toString()}` : ""}`
  );
}
