import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Table from "react-bootstrap/table";
import Alert from "react-bootstrap/alert";
import Form from "react-bootstrap/form";
import Col from "react-bootstrap/col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faPlus } from "@fortawesome/free-solid-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";

import CreateInventoryTransferModal from "./createInventoryTransferModal";

import { formatDate } from "../helpers";
import { renewAuthenticationToken } from "../authenticationUtils";
import getWarehouses from "../warehouses";

const WAIT_INTERVAL = 500; //ms to wait before sending request to re-filter
const LIMIT = 25;

const InventoryTransferList = () => {
  const [transfers, setTransfers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fromWarehouseId, setFromWarehouseId] = useState(-1);
  const [toWarehouseId, setToWarehouseId] = useState(-1);
  const [checked, setChecked] = useState(false);
  const [received, setReceived] = useState(false);
  const [
    showCreateInventoryTransferModal,
    setShowCreateInventoryTransferModal,
  ] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const navigate = useNavigate();

  const warehouses = getWarehouses();

  /*
	managing these as refs and not state, because while we need a mutable piece of memory
	we aren't rendering these values directly to screen, we only need a shallow re-render
	of the InfiniteScroll
	These are really used to manage pagination in the http requests
	*/
  const beginningAfterId = useRef(null);

  const isFirstRender = useRef(true);
  const timer = useRef(null); //timer will be used for staging requests
  useEffect(() => {
    setHasMore(false); //this is to keep it from hitting fetch more while waiting for timer
    if (!isFirstRender.current) {
      clearTimeout(timer.current);

      beginningAfterId.current = null;

      timer.current = setTimeout(handleFetchInventoryTransfers, WAIT_INTERVAL);
    } else {
      //on component mount, first time this has rendered
      handleFetchInventoryTransfers();
      isFirstRender.current = false; //set to false to not enter this loop
    }
    return () => clearTimeout(timer.current); //need to clear the timer on component unmount
  }, [fromWarehouseId, toWarehouseId, checked, received]);

  async function handleFetchInventoryTransfers() {
    setLoading(true);
    setError(null);
    try {
      let result = await fetchInventoryTransfers();
      if (result.status === 401) {
        await renewAuthenticationToken();
        result = await fetchInventoryTransfers();
      }

      switch (result.status) {
        case 200:
          setTransfers(result.data.inventoryTransfers);
          beginningAfterId.current = result.data.inventoryTransfers.at(-1).id;
          setHasMore(result.data.more);
          break;
        case 404:
          setTransfers([]);
          setHasMore(false);
          setError("No results found");
          break;
        case 400:
          setTransfers([]);
          setHasMore(false);
          setError(result.data.error.message);
          break;
        default:
          setHasMore(false);
          setError("An unexpected error occurred");
          break;
      }
    } catch (error) {
      console.log(error.message);
      setHasMore(false);
      setError("An unexpected error occurred");
    }
    setLoading(false);
  }

  async function handleFetchMoreInventoryTransfers() {
    setLoading(true);
    setError(null);
    try {
      let result = await fetchInventoryTransfers();
      if (result.status === 401) {
        await renewAuthenticationToken();
        result = await fetchInventoryTransfers();
      }

      switch (result.status) {
        case 200:
          setTransfers(transfers.concat(result.data.inventoryTransfers));
          beginningAfterId.current = result.data.inventoryTransfers.at(-1).id;
          setHasMore(result.data.more);
          break;
        default:
          setHasMore(false);
          setError("An unexpected error occurred");
          break;
      }
    } catch (error) {
      console.log(error.message);
      setHasMore(false);
      setError("An unexpected error occurred");
    }
    setLoading(false);
  }

  async function fetchInventoryTransfers() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    const url = `${
      process.env.REACT_APP_BASE_URL
    }/v2/inventory-transfers?limit=${LIMIT}${
      beginningAfterId.current
        ? `&beginningAfterId=${beginningAfterId.current}`
        : ""
    }&checked=${checked}&received=${received}${
      fromWarehouseId !== -1 ? `&fromWarehouseId=${fromWarehouseId}` : ""
    }${toWarehouseId !== -1 ? `&toWarehouseId=${toWarehouseId}` : ""}`;
    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      throw error;
    }
  }

  function calculateScrollHeight() {
    //Calculates height of infinite scroll, MAX: 1000, MIN:300
    let height = transfers.length * 50 + 300;
    if (height > 1000) {
      height = 1000;
    }
    return height;
  }

  return (
    <div>
      <h1>
        Inventory Transfers
        {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
      </h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>From Warehouse</Form.Label>
            <Form.Control
              as="select"
              value={fromWarehouseId}
              onChange={(e) => {
                setFromWarehouseId(e.target.value);
              }}
            >
              <option key={-1} value={-1} disabled>
                Select warehouse...
              </option>
              {warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>To Warehouse</Form.Label>
            <Form.Control
              as="select"
              value={toWarehouseId}
              onChange={(e) => {
                setToWarehouseId(e.target.value);
              }}
            >
              <option key={-1} value={-1} disabled>
                Select warehouse...
              </option>
              {warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon
              icon={faPlus}
              size="3x"
              onClick={() => setShowCreateInventoryTransferModal(true)}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Check
              type="checkbox"
              label="Checked"
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
              }}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Check
              type="checkbox"
              label="Received"
              checked={received}
              onChange={(e) => {
                setReceived(e.target.checked);
              }}
            />
          </Form.Group>
        </Form.Row>
      </Form>
      <InfiniteScroll
        dataLength={transfers.length}
        next={handleFetchMoreInventoryTransfers}
        hasMore={hasMore}
        loader={<FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
        height={calculateScrollHeight()}
      >
        <Table striped>
          <thead>
            <tr>
              <th>ID</th>
              <th>From</th>
              <th>To</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {transfers.map((transfer) => (
              <tr
                key={transfer.id}
                onClick={() => {
                  navigate(`/inventory-transfers/${transfer.id}`);
                }}
              >
                <td>{transfer.id}</td>
                <td>
                  {
                    warehouses.find(
                      (warehouse) => warehouse.id === transfer.fromWarehouseId
                    ).name
                  }
                </td>
                <td>
                  {
                    warehouses.find(
                      (warehouse) => warehouse.id === transfer.toWarehouseId
                    ).name
                  }
                </td>
                <td>{formatDate(transfer.createdOn)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </InfiniteScroll>
      {showCreateInventoryTransferModal && (
        <CreateInventoryTransferModal
          closeCallback={() => setShowCreateInventoryTransferModal(false)}
        />
      )}
    </div>
  );
};

export default InventoryTransferList;
