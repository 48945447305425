import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faCheckSquare,
  faSpinner,
  faTimesCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { renewAuthenticationToken } from "../authenticationUtils";

const WAIT_INTERVAL = 1000;

const MaterialOrderItem = (props) => {
  const item = props.item;
  const checked = props.checked;
  const itemDeviatedCallback = props.itemDeviatedCallback;
  const handleDeleteIconPressed = props.handleDeleteIconPressed;

  const navigate = useNavigate();

  const [changedQty, setChangedQty] = useState(null);
  const [changedQtyPicked, setChangeQtyPicked] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successfulUpdate, setSuccessfullUpdate] = useState(false);

  const isFirstRender = useRef(true);
  const timer = useRef(null); //timer will be used for staging requests
  useEffect(() => {
    if (!isFirstRender.current) {
      clearTimeout(timer.current);

      timer.current = setTimeout(handleUpdateOrderItem, WAIT_INTERVAL);
    } else {
      isFirstRender.current = false;
    }

    return () => clearTimeout(timer.current);
  }, [changedQty, changedQtyPicked]);

  async function handleUpdateOrderItem() {
    setLoading(true);
    setError(null);
    setSuccessfullUpdate(false);

    const updatedObject = {};
    if (changedQty !== null) {
      updatedObject.qty = changedQty;
    }
    if (changedQtyPicked !== null) {
      updatedObject.qtyPicked = changedQtyPicked;
    }

    try {
      let result = await updateOrderItem(updatedObject);
      if (result.status === 401) {
        await renewAuthenticationToken();
        result = await updateOrderItem(updatedObject);
      }
      if (result.status === 200) {
        setSuccessfullUpdate(true);
        itemDeviatedCallback({
          id: item.id,
          qtyMatch:
            (changedQty ?? item.qty) === (changedQtyPicked ?? item.qtyPicked),
        });
      } else {
        console.log(result.data.error.message);
        setError(result.data.error.message);
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
    setLoading(false);
  }

  async function handleDeleteItem() {
    setLoading(true);
    setError(null);
    setSuccessfullUpdate(false);

    try {
      let result = await deleteOrderItem();
      if (result.status === 401) {
        await renewAuthenticationToken();
        result = await deleteOrderItem();
      }
      if (result.status === 204) {
        //happy path
        navigate(0); //reloads page
      } else {
        console.log(result.data.error.message);
        setError(result.data.error.message);
      }
    } catch (error) {
      console.log(error.message);
      setError(error.message);
    }
    setLoading(false);
  }

  function handleQtyTyped(newQty) {
    if (!newQty || newQty < 0) {
      newQty = 0;
    }

    if (newQty === item.qty) {
      setChangedQty(null);
    } else {
      setChangedQty(newQty);
    }
  }

  function increaseQty() {
    let newQty = (changedQty ?? item.qty) + 1;
    if (newQty === item.qty) {
      setChangedQty(null);
    } else {
      setChangedQty(newQty);
    }
  }

  function decreaseQty() {
    let newQty = (changedQty ?? item.qty) - 1;
    if (newQty < 0) {
      newQty = 0;
    }
    if (newQty === item.qty) {
      setChangedQty(null);
    } else {
      setChangedQty(newQty);
    }
  }

  function handleQtyPickedTyped(newQty) {
    if (!newQty || newQty < 0) {
      newQty = 0;
    }

    if (newQty === item.qtyPicked) {
      setChangeQtyPicked(null);
    } else {
      setChangeQtyPicked(newQty);
    }
  }

  function increaseQtyPicked() {
    let newQty = (changedQtyPicked ?? item.qtyPicked) + 1;
    if (newQty === item.qtyPicked) {
      setChangeQtyPicked(null);
    } else {
      setChangeQtyPicked(newQty);
    }
  }

  function decreaseQtyPicked() {
    let newQty = (changedQtyPicked ?? item.qtyPicked) - 1;
    if (newQty < 0) {
      newQty = 0;
    }
    if (newQty === item.qtyPicked) {
      setChangeQtyPicked(null);
    } else {
      setChangeQtyPicked(newQty);
    }
  }

  async function updateOrderItem(updatedObject) {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify(updatedObject),
    };

    const url = `${process.env.REACT_APP_BASE_URL}/v2/material-orders/items/${item.id}`;
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    return { status: response.status, data: data };
  }

  async function deleteOrderItem() {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    const url = `${process.env.REACT_APP_BASE_URL}/v2/material-orders/items/${item.id}`;
    const response = await fetch(url, requestOptions);
    let data = null;
    if (response.status !== 204) {
      data = await response.json();
    }
    return { status: response.status, data: data };
  }

  function renderStatusIcon() {
    if (loading) {
      return <FontAwesomeIcon icon={faSpinner} className="fa-spin" />;
    } else if (error) {
      return <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red" }} />;
    } else if (successfulUpdate) {
      return (
        <FontAwesomeIcon icon={faCheckSquare} style={{ color: "green" }} />
      );
    }
  }

  return (
    <tr>
      <td>
        {item.inventoryCode} {renderStatusIcon()}
      </td>
      <td>{item.description}</td>
      <td>
        {item.pipeSize?.length > 0
          ? `${item.pipeSize} x ${item.thickness}`
          : ""}
      </td>
      <td>{item.measured}</td>
      <td>
        {!checked && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              onClick={decreaseQty}
              className={(changedQty ?? item.qty) <= 0 ? "fa-disabled" : ""}
              icon={faMinus}
            />
            <input
              type="text"
              value={changedQty ?? item.qty ?? 0}
              onChange={(e) => handleQtyTyped(parseInt(e.target.value))}
              size="3"
              pattern="[0-9]*"
              inputMode="numeric"
            />
            <FontAwesomeIcon onClick={increaseQty} icon={faPlus} />
          </div>
        )}
        {checked && (changedQty ?? item.qty ?? 0)}
      </td>
      <td>
        {!checked && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              onClick={decreaseQtyPicked}
              className={
                (changedQtyPicked ?? item.qtyPicked) <= 0 ? "fa-disabled" : ""
              }
              icon={faMinus}
            />
            <input
              type="text"
              value={changedQtyPicked ?? item.qtyPicked ?? 0}
              onChange={(e) => handleQtyPickedTyped(parseInt(e.target.value))}
              size="3"
              pattern="[0-9]*"
              inputMode="numeric"
            />
            <FontAwesomeIcon onClick={increaseQtyPicked} icon={faPlus} />
          </div>
        )}
        {checked && (changedQtyPicked ?? item.qtyPicked ?? 0)}
      </td>
      {!checked && (
        <td>
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => handleDeleteIconPressed(handleDeleteItem)}
          />
        </td>
      )}
    </tr>
  );
};

export default MaterialOrderItem;
