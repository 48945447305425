import React from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

import MaterialCreditItemListItem from "./materialCreditItemListItem";

class MaterialCreditItemList extends React.Component {
  /*
	PROPS:
	items: [array [object]] array of material credit item objects
	return: [Bool] whether or not the list should have a column for returns
	handleIncreaseQty:[Function] callback for handling increase selected qty
	handleDecreaseQty: [Function] callback for handling decrease selected qty
	handleQtyTyped: [function] callback for handling selected qty typed

	STATE:
	*/

  render() {
    return (
      <Card className="my-3">
        <Card.Header>Credit Items</Card.Header>
        <Card.Body>
          <Table striped bordered hover size="sm" responsive="md">
            <thead>
              <tr>
                <th>Code</th>
                <th>Description</th>
                <th>Size</th>
                <th>Unit</th>
                <th>#</th>
                {this.props.return ? <th>Return</th> : null}
              </tr>
            </thead>
            <tbody>
              {this.props.items?.map((item) => (
                <MaterialCreditItemListItem
                  key={item.id || `${item.inventoryId}${item.creditNumber}`}
                  item={item}
                  return={this.props.return}
                  handleIncreaseQty={this.props.handleIncreaseQty}
                  handleDecreaseQty={this.props.handleDecreaseQty}
                  handleQtyTyped={this.props.handleQtyTyped}
                />
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  }
}

export default MaterialCreditItemList;
