import React from "react";
import Alert from "react-bootstrap/Alert";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import InfiniteScroll from "react-infinite-scroll-component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faPlus } from "@fortawesome/free-solid-svg-icons";

import UserListItem from "./userListItem";
import CreateUserModal from "./createUserModal";

const LIST_LIMIT = 25; //number to retrieve per query

const WAIT_INTERVAL = 300; //ms to wait between queries

class UserList extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [Function]

	STATE:
	error: [String] whether or not an error message is present
	loading: [Bool] whether or not the list is loading
	users: [Array [Object]] array of user objects
	nextPage: [Int] index for what the next page of the paginated list to load is (indexes from 0)
	hasMore: [Bool] whether or not there are more users to be loaded
	showCreateUserModal: [Bool] whether or not to show create user modal
	name: [String] name string to filter on
	email: [String] email string to filter on

	TODO:
	*/

  constructor(props) {
    super(props);

    this.timer = null; //timer used for fetching new users

    this.state = {
      error: null,
      loading: false,
      users: [],
      nextPage: 0,
      hasMore: true,
      showCreateUserModal: false,
      name: "",
      email: "",
    };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleAddNew = this.handleAddNew.bind(this);
    this.handleCloseUserModal = this.handleCloseUserModal.bind(this);
    this.handleNewUserSuccess = this.handleNewUserSuccess.bind(this);
    this.handleFetchUsers = this.handleFetchUsers.bind(this);
  }

  componentDidMount() {
    this.handleFetchUsers();
  }

  handleChangeName(e) {
    clearTimeout(this.timer);

    this.setState(
      {
        name: e.target.value,
        hasMore: true,
        nextPage: 0,
      },
      () => {
        this.timer = setTimeout(this.handleFetchUsers, WAIT_INTERVAL);
      }
    );
  }

  handleAddNew() {
    this.setState({ showCreateUserModal: true });
  }

  handleCloseUserModal() {
    this.setState({ showCreateUserModal: false });
  }

  handleNewUserSuccess(user) {
    this.setState(function (state, props) {
      let users = [user].concat(state.users);
      return {
        users: users,
      };
    });
  }

  async handleFetchUsers() {
    this.setState({
      error: null,
      loading: true,
    });

    try {
      let response = await this.fetchUsers();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchUsers();
      }

      switch (response.status) {
        case 200:
          //happy path
          this.setState({
            users: response.data.users,
            hasMore: response.data.more,
            nextPage: 1,
            loading: false,
          });
          break;
        case 404:
          this.setState({
            hasMore: false,
            loading: false,
            error: response.data?.error?.message,
            users: [],
          });
          break;
        default:
          console.log(response.data?.error?.message);
          this.setState({
            hasMore: false,
            loading: false,
            error: "An unexpected error occurred",
            users: [],
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        hasMore: false,
        loading: false,
        error: "An unexpected error occurred",
        users: [],
      });
    }
  }

  async handleFetchMoreUsers() {
    this.setState({
      error: null,
      loading: true,
    });

    try {
      let response = await this.fetchUsers();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchUsers();
      }

      switch (response.status) {
        case 200:
          //happy path
          this.setState(function (state, props) {
            let users = state.users.concat(response.data.users);
            return {
              users: users,
              hasMore: response.data.more,
              nextPage: state.nextPage + 1,
              loading: false,
            };
          });
          break;
        case 404:
          this.setState({
            hasMore: false,
            loading: false,
            error: response.data?.error?.message,
          });
          break;
        default:
          console.log(response.data?.error?.message);
          this.setState({
            hasMore: false,
            loading: false,
            error: "An unexpected error occurred",
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        hasMore: false,
        loading: false,
        error: "An unexpected error occurred",
      });
    }
  }

  async fetchUsers() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    let queryParams = `?limit=${LIST_LIMIT}&offset=${
      LIST_LIMIT * this.state.nextPage
    }`;
    if (this.state.name.length > 0) {
      queryParams += `&name=${this.state.name}`;
    }
    let url = process.env.REACT_APP_BASE_URL + "/v2/users" + queryParams;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error ocurred",
      });
    }
  }

  calculateScrollHeight() {
    //Calculates height of infinite scroll, MAX: 1000, MIN:300
    let height = this.state.users.length * 50 + 300;
    if (height > 1000) {
      height = 1000;
    }
    return height;
  }

  render() {
    return (
      <div>
        <h1>Users</h1>
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={this.state.name}
                placeholder="Filter by name..."
                onChange={this.handleChangeName}
              />
            </Form.Group>
            <Form.Group style={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon
                icon={faPlus}
                size="3x"
                onClick={this.handleAddNew}
              />
            </Form.Group>
          </Form.Row>
        </Form>
        <Alert variant="danger" show={this.state.error}>
          {this.state.error}
        </Alert>
        {this.state.users.length > 0 ? (
          <InfiniteScroll
            dataLength={this.state.users.length}
            next={this.handleFetchMoreUsers}
            hasMore={this.state.hasMore}
            loader={<FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
            height={this.calculateScrollHeight()}
          >
            <Accordion>
              {this.state.users.map((user) => (
                <UserListItem
                  key={user.id}
                  renewAuthenticationToken={this.props.renewAuthenticationToken}
                  user={user}
                />
              ))}
            </Accordion>
          </InfiniteScroll>
        ) : null}
        {this.state.showCreateUserModal ? (
          <CreateUserModal
            show={true}
            handleClose={this.handleCloseUserModal}
            renewAuthenticationToken={this.props.renewAuthenticationToken}
            handleSuccess={this.handleNewUserSuccess}
          />
        ) : null}
      </div>
    );
  }
}

export default UserList;
