import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Modal from "react-bootstrap/modal";
import Button from "react-bootstrap/button";
import Form from "react-bootstrap/form";
import Alert from "react-bootstrap/alert";

import getWarehouses from "../warehouses";
import { renewAuthenticationToken } from "../authenticationUtils";

const CreateInventoryTransferModal = (props) => {
  const closeCallback = props.closeCallback;

  const navigate = useNavigate();

  const warehouses = getWarehouses();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [fromWarehouseId, setFromWarehouseId] = useState(-1);
  const [toWarehouseId, setToWarehouseId] = useState(-1);

  async function handleCreateInventoryTransfer() {
    setLoading(true);
    setError(null);

    try {
      let result = await createInventoryTransfer();
      if (result.status === 401) {
        await renewAuthenticationToken();
        result = await createInventoryTransfer();
      }

      switch (result.status) {
        case 201:
          setSuccess(`Transfer #${result.data.id} created!`);
          setTimeout(() => {
            navigate(0);
          }, 1500); //wait 1500ms and then reload page
          break;
        case 400:
          setError(result.data.error.message);
          break;
        default:
          setError("An unexpected error occurred");
          console.log(result.data.error?.message);
      }
    } catch (error) {
      console.log(error.message);
      setError("An unexpected error occurred");
    }
    setLoading(false);
  }

  async function createInventoryTransfer() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify({
        fromWarehouseId: fromWarehouseId,
        toWarehouseId: toWarehouseId,
      }),
    };

    const url = `${process.env.REACT_APP_BASE_URL}/v2/inventory-transfers`;
    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      throw error;
    }
  }

  function disableSubmit() {
    if (
      fromWarehouseId !== -1 &&
      toWarehouseId !== -1 &&
      fromWarehouseId !== toWarehouseId &&
      !loading
    ) {
      return false;
    }
    return true;
  }

  const modalProps = {
    show: true,
    size: "md",
    onHide: closeCallback,
  };

  if (loading) {
    modalProps.backdrop = "static";
    modalProps.keyboard = false;
  }

  return (
    <Modal {...modalProps}>
      <Modal.Header closeButton={!loading}>
        <Modal.Title>Create Inventory Transfer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="success" show={success}>
          {success}
        </Alert>
        <Alert variant="danger" show={error}>
          {error}
        </Alert>
        <Form.Group>
          <Form.Label>From Warehouse</Form.Label>
          <Form.Control
            as="select"
            value={fromWarehouseId}
            onChange={(e) => {
              setFromWarehouseId(e.target.value);
            }}
          >
            <option key={-1} value={-1} disabled>
              Select warehouse...
            </option>
            {warehouses.map((warehouse) => (
              <option key={warehouse.id} value={warehouse.id}>
                {warehouse.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>To Warehouse</Form.Label>
          <Form.Control
            as="select"
            value={toWarehouseId}
            onChange={(e) => {
              setToWarehouseId(e.target.value);
            }}
          >
            <option key={-1} value={-1} disabled>
              Select warehouse...
            </option>
            {warehouses.map((warehouse) => (
              <option key={warehouse.id} value={warehouse.id}>
                {warehouse.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeCallback} disabled={!loading}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleCreateInventoryTransfer}
          disabled={disableSubmit()}
        >
          {loading ? "Loading..." : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateInventoryTransferModal;
