import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";

import InfiniteScroll from "react-infinite-scroll-component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faPlus } from "@fortawesome/free-solid-svg-icons";

import CreateMaterialOrderModal from "./createMaterialOrderModal";
import JobNumberInput from "./jobNumberInput";

import { renewAuthenticationToken } from "../authenticationUtils";
import { formatDate, formatUTCDate } from "../helpers";
import getWarehouses from "../warehouses";

const WAIT_INTERVAL = 300; //ms to wait before sending request to re-filter
const LIST_LIMIT = 25; //number to retrieve per query

const MaterialOrderList = () => {
  const [jobNumber, setJobNumber] = useState("");
  const [warehouseId, setWarehouseId] = useState(-1);
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [received, setReceived] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const navigate = useNavigate();

  const warehouses = getWarehouses();

  const beginningAfterId = useRef(null);

  const isFirstRender = useRef(true);
  const timer = useRef(null); //timer will be used for staging requests

  useEffect(() => {
    async function handleFetchMaterialOrders() {
      setLoading(true);
      setError(null);
      try {
        let result = await fetchMaterialOrders(
          beginningAfterId.current,
          checked,
          received,
          warehouseId,
          jobNumber
        );
        if (result.status === 401) {
          await renewAuthenticationToken();
          result = await fetchMaterialOrders(
            beginningAfterId.current,
            checked,
            received,
            warehouseId,
            jobNumber
          );
        }

        switch (result.status) {
          case 200:
            setOrders(result.data.materialOrders);
            beginningAfterId.current = result.data.materialOrders.at(-1).number;
            setHasMore(result.data.more);
            break;
          case 404:
            setOrders([]);
            setHasMore(false);
            setError("No results found");
            break;
          case 400:
            setOrders([]);
            setHasMore(false);
            setError(result.data.error.message);
            break;
          default:
            setHasMore(false);
            setError("An unexpected error occurred");
            break;
        }
      } catch (error) {
        console.log(error.message);
        setHasMore(false);
        setError("An unexpected error occurred");
      }
      setLoading(false);
    }

    setHasMore(false); //this is to keep it from hitting fetch more while waiting for timer

    if (!isFirstRender.current) {
      clearTimeout(timer.current);

      beginningAfterId.current = null;

      timer.current = setTimeout(handleFetchMaterialOrders, WAIT_INTERVAL);
    } else {
      //on component mount, first time this has rendered
      handleFetchMaterialOrders();
      isFirstRender.current = false; //set to false to not enter this loop
    }
    return () => clearTimeout(timer.current); //need to clear the timer on component unmount
  }, [warehouseId, jobNumber, checked, received]);

  async function handleFetchMoreMaterialOrders() {
    setLoading(true);
    setError(null);
    try {
      let result = await fetchMaterialOrders(
        beginningAfterId.current,
        checked,
        received,
        warehouseId,
        jobNumber
      );
      if (result.status === 401) {
        await renewAuthenticationToken();
        result = await fetchMaterialOrders(
          beginningAfterId.current,
          checked,
          received,
          warehouseId,
          jobNumber
        );
      }

      switch (result.status) {
        case 200:
          setOrders(orders.concat(result.data.materialOrders));
          beginningAfterId.current = result.data.materialOrders.at(-1).id;
          setHasMore(result.data.more);
          break;
        default:
          setHasMore(false);
          setError("An unexpected error occurred");
          break;
      }
    } catch (error) {
      console.log(error.message);
      setHasMore(false);
      setError("An unexpected error occurred");
    }
    setLoading(false);
  }

  function getStatus(order) {
    if (order.receivedBy) {
      return "delivered";
    } else if (new Date(order.dateDesired).getTime() < new Date().getTime()) {
      return "late";
    } else if (order.checkedBy) {
      return "progress";
    } else {
      return "new";
    }
  }

  function getBadge(status) {
    const style = {
      // position: "absolute",
      // top: "-15px",
      // right: "-32px"
    };
    if (status === "new") {
      return (
        <Badge variant="primary" style={style}>
          New
        </Badge>
      );
    } else if (status === "late") {
      return (
        <Badge variant="danger" style={style}>
          Late
        </Badge>
      );
    } else {
      return;
    }
  }

  return (
    <div>
      <h1>
        Orders
        {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
      </h1>
      <Form>
        <Form.Row>
          <JobNumberInput
            renewAuthenticationToken={renewAuthenticationToken}
            handleJobNumberChange={setJobNumber}
            asCol={true}
          />
          <Form.Group as={Col}>
            <Form.Label>Warehouse</Form.Label>
            <Form.Control
              as="select"
              value={warehouseId}
              onChange={(e) => setWarehouseId(e.target.value)}
            >
              <option key={-1} value={-1} disabled>
                Select warehouse...
              </option>
              {warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon
              icon={faPlus}
              size="3x"
              onClick={() => setShowAddNewModal(true)}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Check
              type="checkbox"
              label="Checked"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Check
              type="checkbox"
              label="Received"
              checked={received}
              onChange={(e) => setReceived(e.target.checked)}
            />
          </Form.Group>
        </Form.Row>
      </Form>
      <Alert variant="danger" show={error}>
        {error}
      </Alert>
      {orders.length > 0 && (
        <InfiniteScroll
          dataLength={orders.length}
          next={handleFetchMoreMaterialOrders}
          hasMore={hasMore}
          loader={<FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
          height={1000}
        >
          <Table striped>
            <thead>
              <tr>
                <th>#</th>
                <th>Created</th>
                <th>Desired</th>
                <th>Warehouse</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr
                  key={order.number}
                  onClick={() => {
                    navigate(`/material-orders/${order.number}`);
                  }}
                >
                  <td>
                    <strong>{order.number}</strong> {getBadge(getStatus(order))}
                  </td>
                  <td>{formatDate(order.dateEntered)}</td>
                  <td>{formatUTCDate(order.dateDesired)}</td>
                  <td>
                    {
                      warehouses.find(
                        (warehouse) => warehouse.id === order.locationId
                      ).name
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </InfiniteScroll>
      )}
      {showAddNewModal && (
        <CreateMaterialOrderModal
          show={showAddNewModal}
          handleClose={() => setShowAddNewModal(false)}
          onSuccess={(order) => {
            navigate(`/material-orders/${order.number}`);
          }}
          renewAuthenticationToken={renewAuthenticationToken}
        />
      )}
    </div>
  );
};

async function fetchMaterialOrders(
  beginningAfterId,
  checked,
  received,
  warehouseId,
  jobNumber
) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
    credentials: "include",
  };

  const url = `${
    process.env.REACT_APP_BASE_URL
  }/v2/material-orders?limit=${LIST_LIMIT}${
    beginningAfterId ? `&beginningAfterId=${beginningAfterId}` : ""
  }&checked=${checked}&received=${received}${
    warehouseId !== -1 ? `&locationId=${warehouseId}` : ""
  }${jobNumber ? `&jobNumber=${jobNumber}` : ""}`;

  const response = await fetch(url, requestOptions);
  const data = await response.json();
  return { status: response.status, data: data };
}

export default MaterialOrderList;
