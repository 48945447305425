import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

class MaterialCreditItemListItem extends React.Component {
  /*
	PROPS:
	item:[Object] item to display
	return: [Bool] whether the table is displaying return qtys
	handleQtyTyped: [Function] handles change in selected qty
	handleIncreaseQty: [Function] handles increase in qty selected
	handleDecreaseQty: [Function] handles decrease in qty selected
	*/
  render() {
    return (
      <tr>
        <td>{this.props.item.inventoryCode}</td>
        <td>{this.props.item.description}</td>
        <td>
          {this.props.item.pipeSize?.length > 1
            ? `${this.props.item.pipeSize} x ${this.props.item.thickness}`
            : ""}
        </td>
        <td>{this.props.item.measured}</td>
        <td>{this.props.item.qty || 0}</td>
        {this.props.return ? (
          <th>
            <FontAwesomeIcon
              onClick={() =>
                this.props.handleDecreaseQty(
                  this.props.item.id ||
                    `${this.props.item.inventoryId}${this.props.item.creditNumber}`
                )
              }
              icon={faMinus}
            />
            <input
              type="text"
              value={this.props.item.selectedQty}
              onChange={(e) =>
                this.props.handleQtyTyped(
                  this.props.item.id ||
                    `${this.props.item.inventoryId}${this.props.item.creditNumber}`,
                  parseInt(e.target.value) || 0
                )
              }
              size="3"
              pattern="[0-9]*"
              inputMode="numeric"
            />
            <FontAwesomeIcon
              onClick={() =>
                this.props.handleIncreaseQty(
                  this.props.item.id ||
                    `${this.props.item.inventoryId}${this.props.item.creditNumber}`
                )
              }
              icon={faPlus}
            />
          </th>
        ) : null}
      </tr>
    );
  }
}

export default MaterialCreditItemListItem;
