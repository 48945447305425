import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import EmployeeIdInput from "./employeeIdInput";

class EmployeeForm extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [Function]
	name: [String]
	email: [String Nullable] some cases where this is null in the DB
	tSheetsUserId: [Int Nullable] corresponding tSheetsUserId can be null if there hasn't been one assigned yet
	handleChangeTSheetsUserId: [Function]
	*/

  render() {
    return (
      <Form>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" value={this.props.name} readOnly={true} />
          </Form.Group>
          <EmployeeIdInput
            renewAuthenticationToken={this.props.renewAuthenticationToken}
            handleEmployeeIdConfirmed={this.props.handleChangeTSheetsUserId}
            asCol={true}
            employeeId={this.props.tSheetsUserId}
            tSheets={true}
          />
        </Form.Row>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            value={this.props.email || ""}
            readOnly={true}
          />
        </Form.Group>
      </Form>
    );
  }
}

export default EmployeeForm;
