import React from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { formatDate, formatTime } from "../helpers";

import MaterialCreditItemList from "./materialCreditItemList";
import getWarehouses from "../warehouses";

class MaterialCreditListItem extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [Function] renews token
	credit: [Object] material credit object

	STATE:
	error: [String] whether or not theres an error message
	expanded: [Bool] whether accordion is expanded or not
	loading: [Bool] whether details are loading or not
	credit: [Object] material credit object ONLY USED FOR CREDIT ITEMS
	*/

  constructor(props) {
    super(props);

    this.state = {
      error: "",
      expanded: false,
      loading: false,
      credit: null,
    };

    this.handleAccordionToggle = this.handleAccordionToggle.bind(this);

    this.warehouses = getWarehouses();
  }

  handleAccordionToggle() {
    this.setState(function (state, props) {
      if (!state.expanded) {
        this.handleFetchMaterialCredit();
      }
      return { expanded: !state.expanded };
    });
  }

  async handleFetchMaterialCredit() {
    this.setState({
      loading: true,
    });

    try {
      let response = await this.fetchMaterialCredit();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchMaterialCredit();
      }

      switch (response.status) {
        case 200:
          //happy path
          this.setState({
            loading: false,
            credit: response.data,
          });
          if (response.data.jobNumber) {
            this.loadJobDetails(response.data.jobNumber);
          }
          break;

        case 404:
          this.setState({
            loading: false,
            error: response.data.error.message,
          });
          break;

        default:
          this.setState({
            loading: false,
            error: "An unexpected error occurred",
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        loading: false,
        error: "An unexpected error occurred",
      });
    }
  }

  async loadJobDetails(jobNumber) {
    try {
      let result = await this.fetchJob(jobNumber);
      if (result.status === 401) {
        await this.props.renewAuthenticationToken();
        result = await this.fetchJob(jobNumber);
      }

      switch (result.status) {
        case 200:
          this.setState(function (state, props) {
            let credit = state.credit;
            credit.jobName = result.data.name;
            return { credit: credit };
          });
          break;
        default:
          this.setState({
            error: "An unexpected error occurred",
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
      });
    }
  }

  async fetchMaterialCredit() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    let url =
      process.env.REACT_APP_BASE_URL +
      `/v2/material-credits/${this.props.credit.number}`;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error ocurred",
      });
    }
  }

  async fetchJob(jobNumber) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    try {
      let response = await fetch(
        process.env.REACT_APP_BASE_URL + `/v2/jobs/${jobNumber}`,
        requestOptions
      );
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
      });
    }
  }

  renderMaterialCreditDetails() {
    if (this.state.loading) {
      return <FontAwesomeIcon icon={faSpinner} className="fa-spin" />;
    } else {
      return (
        <div>
          <Alert variant="danger" show={this.state.error}>
            {this.state.error}
          </Alert>
          <Form>
            {this.state.credit?.jobName ? (
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Job Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={this.props.credit.jobNumber}
                    readOnly
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Job Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={this.state.credit.jobName}
                    readOnly
                  />
                </Form.Group>
              </Form.Row>
            ) : (
              <Form.Group as={Col}>
                <Form.Label>Job Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.props.credit.jobNumber}
                  readOnly
                />
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="text"
                placeholder={formatDate(this.props.credit.date)}
                readOnly
              />
            </Form.Group>
          </Form>
          <MaterialCreditItemList items={this.state.credit?.items} />
        </div>
      );
    }
  }

  render() {
    return (
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={this.props.credit.number}
          onClick={this.handleAccordionToggle}
        >
          <Container fluid style={{ padding: 0 }}>
            <Row>
              <Col
                xs={3}
                className="px-1"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ position: "relative" }}>
                  <strong>{this.props.credit.number}</strong>
                </div>
              </Col>
              <Col className="px-1">
                <strong>Date:</strong>
                {formatDate(this.props.credit.date) +
                  " " +
                  formatTime(this.props.credit.date)}
                <br />
                <strong>Warehouse: </strong>
                {
                  this.warehouses.find(
                    (warehouse) => warehouse.id === this.props.credit.locationId
                  ).name
                }
              </Col>
              <Col className="px-1">
                <p>{this.props.credit.jobNumber}</p>
              </Col>
            </Row>
          </Container>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={this.props.credit.number}>
          <Card.Body>{this.renderMaterialCreditDetails()}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

export default MaterialCreditListItem;
