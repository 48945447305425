import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import JobNumberInput from "./jobNumberInput";

import { formatUTCDate } from "../helpers";

class CreateTimesheetItemModal extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [Function]
	show: [Bool] whether this should be showing or not
	timesheetId: [Int] timesheetId that this goes with
	handleClose: [Function] callback for when the modal closes
	handleSuccess: [Function] callback for successful add takes a stub timesheetItem object

	STATE:
	loading: [Bool] whether any async loading is in flight
	error: [String] error message if any
	jobNumber: [String] jobNumber of form
	date: [Date] date of form
	success: [String] true when a new item is successfully created and is the id of the new item

	TODO:
	*/

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: "",
      jobNumber: "",
      date: "",
      success: "",
    };

    this.handleChangeJobNumber = this.handleChangeJobNumber.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleChangeJobNumber(jobNumber) {
    this.setState({
      jobNumber: jobNumber,
    });
  }

  handleChangeDate(e) {
    this.setState({
      date: e.target.valueAsDate,
    });
  }

  async handleSubmit() {
    this.setState({
      error: "",
      loading: true,
    });

    try {
      let result = await this.createNewTimesheetItem();
      if (result.status === 401) {
        await this.props.renewAuthenticationToken();
        result = await this.createNewTimesheetItem();
      }
      switch (result.status) {
        case 201:
          this.setState(
            {
              loading: false,
              success: result.data.id,
            },
            () => {
              setTimeout(() => {
                this.handleSuccess(result.data.id);
              }, 1500);
            }
          );
          break;

        case 404:
        case 400:
          this.setState({
            loading: false,
            error: result.data.error.message,
          });
          break;

        default:
          this.setState({
            loading: false,
            error: "An unexpected error occurred",
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error ocurred",
        loading: false,
      });
    }
  }

  handleClose() {
    this.setState(
      {
        loading: false,
        error: "",
        jobNumber: "",
        date: "",
        success: "",
      },
      this.props.handleClose
    );
  }

  handleSuccess(id) {
    this.props.handleSuccess({
      id: id,
      jobNumber: this.state.jobNumber,
      dateTime: this.state.date.toISOString(),
      hours: null,
      overtimeHours: null,
      rate: null,
      travel: null,
      timesheetId: this.props.timesheetId,
      bonus: null,
      employeeId: null,
      posted: false,
      phaseId: null,
      overTimeRate: null,
    });

    this.handleClose();
  }

  async createNewTimesheetItem() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify({
        jobNumber: this.state.jobNumber,
        dateTime: this.state.date.toISOString(),
      }),
    };

    const url = `${process.env.REACT_APP_BASE_URL}/v2/timesheets/${this.props.timesheetId}/items`;
    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error ocurred",
      });
    }
  }

  render() {
    let modalProps = {
      size: "md",
    };
    if (this.state.loading) {
      modalProps.backdrop = "static";
      modalProps.keyboard = false;
    }
    return (
      <Modal {...modalProps} show={this.props.show} onHide={this.handleClose}>
        <Modal.Header closeButton={!this.state.loading}>
          <Modal.Title>New Timesheet Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={this.state.success}>
            {this.state.success} Added!
          </Alert>
          <Alert variant="danger" show={this.state.error}>
            {this.state.error}
          </Alert>
          <Form>
            <JobNumberInput
              renewAuthenticationToken={this.props.renewAuthenticationToken}
              handleJobNumberChange={this.handleChangeJobNumber}
              asCol={false}
            />
            <Form.Group>
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                value={formatUTCDate(this.state.date, false)}
                onChange={this.handleChangeDate}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.handleClose}
            disabled={this.state.loading}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={this.handleSubmit}
            disabled={
              this.state.jobNumber === "" ||
              this.state.date === "" ||
              this.state.loading ||
              this.state.success
            }
          >
            {this.state.loading ? "Loading..." : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CreateTimesheetItemModal;
