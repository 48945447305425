import React from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import MaterialSaleItemListItem from "./materialSaleItemListItem";
import getWarehouses from "../warehouses";

import { formatDate, formatTime } from "../helpers";

class MaterialSaleListItem extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [Function] renews token
	sale: [Object] material sale USE STATE, this is copied to state
	setReceivedModal: [Function] sets (shows/hides) receive flow modal

	STATE:
	loading: [bool] whether details are loading
	error: [String] error message if any
	expanded: [Bool] whether or not accordion is expanded
	sale: [Object] material sale
	updating: [Bool] update in progress
	changedKeyValuePairs: [Array[Object]] Array of key/value pair objects of things that have changed and their current value

	TODO:
	*/

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      expanded: false,
      sale: this.props.sale,
      updating: false,
      changedKeyValuePairs: [],
    };

    this.handleAccordionToggle = this.handleAccordionToggle.bind(this);
    this.handleMaterialSaleReceived =
      this.handleMaterialSaleReceived.bind(this);
    this.handleChangeInstructions = this.handleChangeInstructions.bind(this);
    this.handleChangeCheckedBy = this.handleChangeCheckedBy.bind(this);
    this.handlePrintLabel = this.handlePrintLabel.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleUpdateQuantities = this.handleUpdateQuantities.bind(this);

    this.warehouses = getWarehouses();
  }

  handleAccordionToggle() {
    if (!this.state.expanded) {
      this.handleFetchMaterialSale();
    }
    this.setState((state, props) => {
      return { expanded: !state.expanded };
    });
  }

  handleMaterialSaleReceived() {
    this.setState(function (state, props) {
      let sale = state.sale;

      sale.receivedBy = true;
      return { sale: sale };
    });
  }

  handleChangeInstructions(e) {
    this.setState(function (state, props) {
      let sale = state.sale;
      sale.instructions = e.target.value;

      let changedKeyValuePairs = state.changedKeyValuePairs;
      //get the index of the object whose name matches the key
      let indexOfKey = changedKeyValuePairs.findIndex(
        (obj) => obj.name === "instructions"
      );
      if (indexOfKey === -1) {
        //if key is not already in list of changed keys
        changedKeyValuePairs.push({
          name: "instructions",
          value: e.target.value,
        }); //add it to the list of keys
      } else {
        //key is already in list of changed keys, so we need to change that one
        changedKeyValuePairs[indexOfKey].value = e.target.value;
      }
      return { sale: sale, changedKeyValuePairs: changedKeyValuePairs };
    });
  }

  handleChangeCheckedBy(e) {
    this.setState(function (state, props) {
      let sale = state.sale;
      sale.checkedBy = e.target.checked;

      let changedKeyValuePairs = state.changedKeyValuePairs;
      //get the index of the object whose name matches the key
      let indexOfKey = changedKeyValuePairs.findIndex(
        (obj) => obj.name === "checkedBy"
      );
      if (indexOfKey === -1) {
        //if key is not already in list of changed keys
        changedKeyValuePairs.push({
          name: "checkedBy",
          value: e.target.checked,
        }); //add it to the list of keys
      } else {
        //key is already in list of changed keys, so we need to change that one
        changedKeyValuePairs[indexOfKey].value = e.target.checked;
      }
      return { sale: sale, changedKeyValuePairs: changedKeyValuePairs };
    });
  }

  async handlePrintLabel() {
    //if we already have a label availble for this particular sale then just show it, else generate it and show the new one
    let firstShippingPDF = this.state.sale.pdfs?.find(
      (pdf) => pdf.subType === 1
    );
    let pdfId;
    if (firstShippingPDF) {
      pdfId = firstShippingPDF.id;
    } else {
      //first go fire off a command to generate the pdf
      try {
        let response = await this.createPDF();
        if (response.status === 401) {
          await this.props.renewAuthenticationToken();
          response = await this.createPDF();
        }

        switch (response.status) {
          case 201:
            pdfId = response.data.id; //set the id
            break;

          case 404:
          case 400:
            console.log(response.data.error);
            this.setState({
              error: response.data.error.message,
            });
            return; //putting this here so that we don't try to open an empty pdf

          default:
            console.log(response.data.error?.message);
            this.setState({
              error: "An unexpected error occurred",
            });
            return;
        }
      } catch (error) {
        console.log(error);
        this.setState({
          error: "An unexpected error occurred",
        });
        return;
      }
    }

    //now try to show the pdf in a new tab
    let save = document.createElement("a");
    save.href = process.env.REACT_APP_BASE_URL + `/v2/pdfs/${pdfId}`;
    save.click();
    save.remove();
  }

  async handleUpdate() {
    this.setState({
      updating: true,
      error: null,
    });

    const updatedObject = {};
    this.state.changedKeyValuePairs.forEach((pair) => {
      if (pair.name === "checkedBy") {
        updatedObject["checked"] = pair.value;
      } else {
        updatedObject[pair.name] = pair.value;
      }
    });

    try {
      let response = await this.updateMaterialSale(updatedObject);
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.updateMaterialSale(updatedObject);
      }

      if (response.status === 200) {
        this.setState({
          updating: false,
          changedKeyValuePairs: [],
        });
      } else {
        this.setState({
          updating: false,
          error: "An unexpected error occurred",
        });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        updating: false,
        error: "An unexpected error occurred",
      });
    }
  }

  async handleFetchMaterialSale() {
    this.setState({
      loading: true,
    });

    try {
      let response = await this.fetchMaterialSale();
      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchMaterialSale();
      }

      switch (response.status) {
        case 200:
          //happy path
          this.setState({
            loading: false,
            sale: response.data,
          });
          break;

        case 404:
          this.setState({
            loading: false,
            error: response.data.error.message,
          });
          break;

        default:
          this.setState({
            loading: false,
            error: "An unexpected error occurred",
          });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        loading: false,
        error: "An unexpected error occurred",
      });
    }
  }

  handleUpdateQuantities(number, qtyPicked) {
    this.setState(function (state, props) {
      let items = [...state.sale.items];
      for (let i = 0; i < items.length; i++) {
        if (items[i].number === number) {
          items[i].qtyPicked = qtyPicked;
          break;
        }
      }
      let sale = state.sale;
      sale.items = items;
      return { sale: sale };
    });
  }

  checkAllMatched() {
    if (!this.state.sale.items) {
      //if not items then of course they all match
      return true;
    }
    let count = 0;
    for (let i = 0; i < this.state.sale.items.length; i++) {
      if (this.state.sale.items[i].qty === this.state.sale.items[i].qtyPicked) {
        count++;
      }
    }

    return count === this.state.sale.items.length;
  }

  handleRemoveItem(itemNumber) {
    this.setState(function (state, props) {
      let originalItems = state.sale.items;

      let newItems = originalItems.filter(function (value, index, array) {
        return value.number !== itemNumber;
      });

      let sale = state.sale;
      sale.items = newItems;

      return { sale: sale };
    });
  }

  async updateMaterialSale(updatedObject) {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify(updatedObject),
    };

    const url =
      process.env.REACT_APP_BASE_URL +
      `/v2/material-sales/${this.state.sale.number}`;

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error ocurred",
      });
    }
  }

  async fetchMaterialSale() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    let url =
      process.env.REACT_APP_BASE_URL +
      `/v2/material-sales/${this.state.sale.number}`;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error ocurred",
      });
    }
  }

  async createPDF() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
      body: JSON.stringify({ type: 1 }),
    };

    const url =
      process.env.REACT_APP_BASE_URL +
      `/v2/material-sales/${this.props.sale.number}/pdfs`;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: "An unexpected error occurred",
      });
    }
  }

  renderMaterialSaleDetails() {
    if (this.state.loading) {
      return <FontAwesomeIcon icon={faSpinner} className="fa-spin" />;
    } else {
      return (
        <div>
          <Alert variant="danger" show={this.state.error}>
            {this.state.error}
          </Alert>
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Customer Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.state.sale.customerName}
                  readOnly
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Job Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.state.sale.jobName}
                  readOnly
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Order Date</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={formatDate(this.state.sale.createdOn)}
                  readOnly
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Created By</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.state.sale.createdBy}
                  readOnly
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Shipping Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.state.sale.shipToName}
                  readOnly
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Shipping Attn.</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.state.sale.shipToAttention}
                  readOnly
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Shipping Address 1</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.state.sale.shipToAddress1}
                  readOnly
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Shipping Address 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.state.sale.shipToAddress2}
                  readOnly
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Shipping City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.state.sale.shipToCity}
                  readOnly
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Shipping State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.state.sale.shipToState}
                  readOnly
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Shipping Address Zip</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.state.sale.shipToPostalCode}
                  readOnly
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Shipping Address County</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={this.state.sale.shipToCounty}
                  readOnly
                />
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <Form.Label>Instructions</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={this.state.sale.instructions || ""}
                onChange={this.handleChangeInstructions}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Checked"
                disabled={
                  (this.state.sale.checkedBy &&
                    !this.state.changedKeyValuePairs.some(
                      (el) => el.name === "checkedBy"
                    )) ||
                  !this.checkAllMatched()
                }
                checked={this.state.sale.checkedBy || false}
                onChange={this.handleChangeCheckedBy}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Received"
                disabled={
                  this.state.sale.receivedBy ||
                  !this.state.sale.checkedBy ||
                  this.state.changedKeyValuePairs.some(
                    (el) => el.name === "checkedBy"
                  )
                }
                checked={this.state.sale.receivedBy || false}
                onChange={() =>
                  this.props.setReceivedModal(
                    this.handleMaterialSaleReceived,
                    this.props.sale.number
                  )
                }
              />
            </Form.Group>
          </Form>
          <Button
            variant="primary"
            onClick={this.handleUpdate}
            disabled={
              this.state.changedKeyValuePairs.length === 0 ||
              this.state.updating
            }
          >
            {this.state.updating ? "Loading..." : "Update"}
          </Button>
          {this.state.sale.checkedBy &&
            !this.state.changedKeyValuePairs.some(
              (el) => el.name === "checkedBy"
            ) && (
              <Button
                style={{ marginLeft: 5 }}
                variant="secondary"
                onClick={this.handlePrintLabel}
              >
                Print Label
              </Button>
            )}
          <Card className="my-3">
            <Card.Header>Sale Items</Card.Header>
            <Card.Body>
              <Table striped bordered hover size="sm" responsive="md">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Description</th>
                    <th>Size</th>
                    <th>Unit</th>
                    <th>#</th>
                    <th># Picked</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.sale.items?.map((item) => (
                    <MaterialSaleItemListItem
                      key={item.number}
                      item={item}
                      checked={this.state.sale.checkedBy}
                      renewAuthenticationToken={
                        this.props.renewAuthenticationToken
                      }
                      handleRemoveItem={this.handleRemoveItem}
                      handleUpdateQuantities={this.handleUpdateQuantities}
                    />
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          {this.state.sale.pdfs?.map((pdf) => {
            if (pdf.subType === 0) {
              //filter out only the sale pdfs
              return (
                <div key={pdf.id}>
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}/v2/pdfs/${pdf.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {pdf.fileName}
                  </a>
                </div>
              );
            }
          })}
        </div>
      );
    }
  }

  render() {
    return (
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={this.state.sale.number}
          onClick={this.handleAccordionToggle}
        >
          <Container fluid style={{ padding: 0 }}>
            <Row>
              <Col
                xs={3}
                className="px-1"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ position: "relative" }}>
                  <strong>{this.state.sale.number}</strong>
                </div>
              </Col>
              <Col className="px-1">
                <strong>Ordered:</strong>
                {formatDate(this.state.sale.createdOn) +
                  " " +
                  formatTime(this.state.sale.createdOn)}
                <br />
                <strong>Warehouse: </strong>
                {
                  this.warehouses.find(
                    (warehouse) => warehouse.id === this.state.sale.locationId
                  ).name
                }
              </Col>
              <Col className="px-1">
                <p>{this.state.sale.customerName}</p>
              </Col>
            </Row>
          </Container>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={this.state.sale.number}>
          <Card.Body>{this.renderMaterialSaleDetails()}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

export default MaterialSaleListItem;
