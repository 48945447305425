import React from "react";

import SearchListInput from "./searchListInput";

const WAIT_INTERVAL = 300; //delay in MS, we use to prevent concurrent requests while typing
const LIST_LIMIT = 25;

class EmployeeIdInput extends React.Component {
  /*
	PROPS:
	renewAuthenticationToken: [Function]
	handleEmployeeIdConfirmed: [Function] callback when an employeeId has been selected (or on blur / enter / selected)
	asCol: [Bool] whether or not to make the input a boostrap Col
	employeeId: [Int] nullable, gets copied to state
	tSheets: [Bool] if true we search for and return tSheetsUserIds, if false we're working with trinity employees

	STATE:
	employeeId: [String] employeeId that is typed into the form field
	employees: [Array [Object]] array of employees objects
	nextPage: [Int] next page in pagination to load, indexes from 0
	hasMore: [Bool] whether or not there are more pages to paginate on 
	error: [String] error string
	loading: [Bool] whether loading is in flight

	TODO:
	Z INDEX
	Handle errors?

	*/

  constructor(props) {
    super(props);

    this.formControlRef = React.createRef(); //this ref is used for calling the onblur later

    this.timer = null;

    this.state = {
      employeeId: this.props.employeeId,
      employees: [],
      nextPage: 0,
      hasMore: true,
      error: null,
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFetchEmployees = this.handleFetchEmployees.bind(this);
    this.handleFetchMoreEmployees = this.handleFetchMoreEmployees.bind(this);
    this.handleEmployeeSelected = this.handleEmployeeSelected.bind(this);
    this.handleInputFocused = this.handleInputFocused.bind(this);
  }

  handleChange(e) {
    clearTimeout(this.timer);

    this.setState(
      {
        employeeId: e.target.value,
        hasMore: true,
        nextPage: 0,
      },
      () => {
        this.timer = setTimeout(this.handleFetchEmployees, WAIT_INTERVAL);
      }
    );
  }

  handleEmployeeSelected(e, employeeId) {
    e.preventDefault(); //prevent page reload
    //then change state, then blur the input
    this.setState({ employeeId: employeeId }, () =>
      this.formControlRef.current.blur()
    );
  }

  handleInputFocused() {
    this.setState({ employeeId: "" });
  }

  async handleFetchEmployees() {
    this.setState({
      error: null,
      loading: true,
    });

    try {
      let response = await this.fetchEmployees();

      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchEmployees();
      }

      switch (response.status) {
        case 200:
          this.setState(function (state, props) {
            return {
              employees: this.convertEmployeesToSearchListInputResults(
                response.data.employees
              ),
              nextPage: state.nextPage + 1,
              hasMore: response.data.more,
            };
          });
          break;
        case 404:
          this.setState({
            employees: [],
            error: response.data.error.message,
          });
          break;
        default:
          this.setState({
            error: "An unexpected error occurred",
          });
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: error.message,
      });
    }
  }

  async handleFetchMoreEmployees() {
    this.setState({
      error: null,
      loading: true,
    });

    try {
      let response = await this.fetchEmployees();

      if (response.status === 401) {
        await this.props.renewAuthenticationToken();
        response = await this.fetchEmployees();
      }

      switch (response.status) {
        case 200:
          this.setState(function (state, props) {
            let employees = state.employees.concat(
              this.convertEmployeesToSearchListInputResults(
                response.data.employees
              )
            );
            return {
              employees: employees,
              nextPage: state.nextPage + 1,
              hasMore: response.data.more,
            };
          });
          break;
        case 404:
          this.setState({
            error: response.data.error.message,
          });
          break;
        default:
          this.setState({
            error: "An unexpected error occurred",
          });
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: error.message,
      });
    }
  }

  async fetchEmployees() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
      },
      credentials: "include",
    };

    let queryParams = `?limit=${LIST_LIMIT}&offset=${
      LIST_LIMIT * this.state.nextPage
    }&active=true&tSheets=${this.props.tSheets}`;
    if (this.state.employeeId !== "") {
      queryParams += `&name=${this.state.employeeId}`;
    }

    const url = process.env.REACT_APP_BASE_URL + "/v2/employees" + queryParams;

    try {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      return { status: response.status, data: data };
    } catch (error) {
      console.log(error.message);
      this.setState({
        error: error.message,
      });
    }
  }

  convertEmployeesToSearchListInputResults(employees) {
    let results = [];
    employees.forEach((employee) => {
      if (this.props.tSheets) {
        results.push({
          key: employee.tSheetsUserId,
          name: `${employee.lastName}, ${employee.firstName}`,
        });
      } else {
        results.push({ key: employee.id, name: employee.name });
      }
    });
    return results;
  }

  render() {
    const label = this.props.tSheets
      ? "TSheets User ID"
      : "Trinity Employee ID";
    return (
      <SearchListInput
        results={this.state.employees}
        hasMore={this.state.hasMore}
        loading={this.state.loading}
        label={label}
        placeholder="Type a last name to search for an ID"
        height={150}
        value={this.state.employeeId}
        asCol={this.props.asCol}
        handleChange={this.handleChange}
        handleItemClicked={this.handleEmployeeSelected}
        handleInputSelected={() =>
          this.props.handleEmployeeIdConfirmed(this.state.employeeId)
        }
        handleFetchMoreResults={this.handleFetchMoreEmployees}
        formControlRef={this.formControlRef}
        handleFocusCallback={this.handleInputFocused}
      />
    );
  }
}

export default EmployeeIdInput;
