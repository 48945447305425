import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

class UserForm extends React.Component {
  /*
	PROPS:
	name: [String]
	email: [String]
	emailReadOnly: [Bool] whether or not the email field should be readonly
	password: [String]
	password2: [String]
	handleChange: [Function] parent function for handling change
	*/

  passwordRegexMatches(password) {
    //takes a plaintext password and checks that it matches the password policy

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;

    let pattern = new RegExp(passwordRegex);
    return pattern.test(password);
  }

  render() {
    const passwordHelpString = `Must be at least 10 characters, 1 lowercase character, 1 uppercase character, 
		1 number, and 1 special character.`;

    return (
      <Form>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => this.props.handleChange("name", e)}
              placeholder="Enter first and last name..."
              value={this.props.name}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => this.props.handleChange("email", e)}
              placeholder="Enter email..."
              value={this.props.email}
              readOnly={this.props.emailReadOnly}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Change Password</Form.Label>
            <Form.Control
              type="password"
              onChange={(e) => this.props.handleChange("password", e)}
              placeholder="New password..."
              value={this.props.password}
            />
            {this.props.password !== "" &&
            !this.passwordRegexMatches(this.props.password) ? (
              <Form.Text muted>{passwordHelpString}</Form.Text>
            ) : null}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              onChange={(e) => this.props.handleChange("password2", e)}
              placeholder="Re-enter password..."
              value={this.props.password2}
            />
            {this.props.password !== this.props.password2 ? (
              <Form.Text muted>Passwords must match</Form.Text>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Group>
          <Form.Check
            type="checkbox"
            label="Admin"
            onChange={(e) => this.props.handleChange("admin", e)}
            checked={this.props.admin}
          />
        </Form.Group>
      </Form>
    );
  }
}

export default UserForm;
