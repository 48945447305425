import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";

import { renewAuthenticationToken } from "../authenticationUtils";

import InfiniteScroll from "react-infinite-scroll-component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faTimesCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

const WAIT_INTERVAL = 300; //delay in MS, we use to prevent concurrent requests while typing
const LIST_LIMIT = 25; //number of inventory items to pull back at a time

const types = [
  "PIPE",
  "FTTG",
  "ACCS",
  "ADH",
  "FSNR",
  "C&R",
  "SADL",
  "WRAP",
  "BORD",
  "BLCK",
  "JCKT",
  "FIRE",
  "TOOL",
  "JANTOOLS",
  "ACST",
  "CHEMICAL",
  "HITMP",
  "SAFE",
  "PAPER",
];

const InventoryItemSelectorModal = (props) => {
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [code, setCode] = useState("");
  const [type, setType] = useState(-1);
  const [active, setActive] = useState(true);
  const [selected, setSelected] = useState(null);
  const [hasMore, setHasMore] = useState(false);

  /*
	managing these as refs and not state, because while we need a mutable piece of memory
	we aren't rendering these values directly to screen, we only need a shallow re-render
	of the InfiniteScroll
	These are really used to manage pagination in the http requests
	*/
  const typeCursor = useRef(null);
  const subTypeCursor = useRef(null);
  const pipeMatrixCursor = useRef(null);
  const idCursor = useRef(null);

  const isFirstRender = useRef(true);
  const timer = useRef(null); //timer will be used for staging requests
  useEffect(() => {
    setHasMore(false); //this keeps having fetchMore from firing until after this timer resolves

    async function handleFetchInventoryItems() {
      setError(null);

      try {
        let result = await fetchInventoryItems(
          code,
          type,
          active,
          typeCursor.current,
          subTypeCursor.current,
          pipeMatrixCursor.current,
          idCursor.current
        );
        if (result.status === 401) {
          await renewAuthenticationToken();
          result = await fetchInventoryItems(
            code,
            type,
            active,
            typeCursor.current,
            subTypeCursor.current,
            pipeMatrixCursor.current,
            idCursor.current
          );
        }

        switch (result.status) {
          case 200:
            setItems(result.data.items);
            typeCursor.current = result.data.items.at(-1).type;
            subTypeCursor.current = result.data.items.at(-1).subType;
            pipeMatrixCursor.current = result.data.items.at(-1).pipeMatrix;
            idCursor.current = result.data.items.at(-1).id;
            setHasMore(result.data.more);
            break;
          case 400:
            setItems([]);
            setHasMore(false);
            setError(result.data.error.message);
            break;
          case 404:
            setItems([]);
            setHasMore(false);
            setError("No results found");
            break;
          default:
            setHasMore(false);
            setError("An unexpected error occurred");
            break;
        }
      } catch (error) {
        console.log(error);
        setHasMore(false);
        setError("An unexpected error occurred");
      }
    }

    if (!isFirstRender.current) {
      clearTimeout(timer.current);

      typeCursor.current = null;
      subTypeCursor.current = null;
      pipeMatrixCursor.current = null;
      idCursor.current = null;

      timer.current = setTimeout(handleFetchInventoryItems, WAIT_INTERVAL);
    } else {
      //on component mount, first time this has rendered
      handleFetchInventoryItems();
      isFirstRender.current = false; //set to false to not enter this loop
    }
    return () => clearTimeout(timer.current); //need to clear the timer on component unmount
  }, [code, type, active]);

  function handleConfirm() {
    props.callback(selected); //call the callback function and input the selected item
  }

  async function handleFetchMoreInventoryItems() {
    setError(null);

    try {
      let result = await fetchInventoryItems(
        code,
        type,
        active,
        typeCursor.current,
        subTypeCursor.current,
        pipeMatrixCursor.current,
        idCursor.current
      );
      if (result.status === 401) {
        await renewAuthenticationToken();
        result = await fetchInventoryItems(
          code,
          type,
          active,
          typeCursor.current,
          subTypeCursor.current,
          pipeMatrixCursor.current,
          idCursor.current
        );
      }

      switch (result.status) {
        case 200:
          setItems(items.concat(result.data.items));
          typeCursor.current = result.data.items.at(-1).type;
          subTypeCursor.current = result.data.items.at(-1).subType;
          pipeMatrixCursor.current = result.data.items.at(-1).pipeMatrix;
          idCursor.current = result.data.items.at(-1).id;
          setHasMore(result.data.more);
          break;
        default:
          setHasMore(false);
          setError("An unexpected error occurred");
          break;
      }
    } catch (error) {
      console.log(error);
      setHasMore(false);
      setError("An unexpected error occurred");
    }
  }

  function renderActiveStatusIcon(a) {
    if (a) {
      return (
        <FontAwesomeIcon icon={faCheckSquare} style={{ color: "green" }} />
      );
    }
    return <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red" }} />;
  }

  function renderInventoryItemList() {
    if (items.length > 0) {
      return (
        <Card>
          <InfiniteScroll
            dataLength={items.length}
            next={handleFetchMoreInventoryItems}
            hasMore={hasMore}
            loader={<FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
            height={400}
          >
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Type</th>
                  <th>Description</th>
                  <th>Size</th>
                  <th>Thick</th>
                  <th>Active</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <tr
                    key={item.id}
                    onClick={() => setSelected(item)}
                    style={
                      selected?.id === item.id
                        ? { backgroundColor: "rgba(0,0,0,0.2)" }
                        : {}
                    }
                  >
                    <td>{item.code}</td>
                    <td>{item.type}</td>
                    <td>{item.description}</td>
                    <td>{item.pipeSize}</td>
                    <td>{item.thickness}</td>
                    <td>{renderActiveStatusIcon(item.active)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </InfiniteScroll>
        </Card>
      );
    }
  }

  return (
    <Modal size="lg" show={true} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select Inventory Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={error}>
          {error}
        </Alert>
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Inventory Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Type</Form.Label>
              <Form.Control
                as="select"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option key={-1} value={-1} disabled>
                  Select type...
                </option>
                {types.map((t, index) => (
                  <option key={index} value={t}>
                    {t}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Active"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </Form.Group>
        </Form>
        {renderInventoryItemList()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleConfirm} disabled={!selected}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

async function fetchInventoryItems(
  code,
  type,
  active,
  typeCursor,
  subTypeCursor,
  pipeMatrixCursor,
  idCursor
) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
    credentials: "include",
  };

  const url = `${
    process.env.REACT_APP_BASE_URL
  }/v2/inventory-items/?limit=${LIST_LIMIT}${
    code.length > 0 ? `&code=${code}` : ""
  }${type.length > 0 ? `&type=${type}` : ""}${active ? `&active=true` : ""}${
    typeCursor ? `&typeCursor=${typeCursor}` : ""
  }${subTypeCursor ? `&subTypeCursor=${subTypeCursor}` : ""}${
    pipeMatrixCursor !== null ? `&pipeMatrixCursor=${pipeMatrixCursor}` : ""
  }${idCursor ? `&idCursor=${idCursor}` : ""}`;

  const response = await fetch(url, requestOptions);
  const data = await response.json();
  return { status: response.status, data: data };
}

export default InventoryItemSelectorModal;
