import logo from "../image001.png";
const Logo = () => {
  return (
    <div
      style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}
    >
      <img src={logo} className="App-logo" alt="logo" />
    </div>
  );
};

export default Logo;
