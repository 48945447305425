import React from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";

import InfiniteScroll from "react-infinite-scroll-component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

class SearchListInput extends React.Component {
  /*
	PROPS:
	results: [Array [Object]] array of objects to display (currently only use key and name props)
	hasMore: [Bool] whether or not we could load more
	loading: [Bool] whether loading is occurring or not
	label: [String] label
	placeholder: [String] placeholder text for input box
	height: [Int] optional height of the infinite scroll width - defaults to 200px
	value: [String] value for the input field
	asCol: [Bool] whether or not to make the input a boostrap Col
	handleChange: [Function] function to call on change
	handleItemClicked: [Function] called when an item is clicked
	handleInputSelected: [Function] when an item is selected. Called on blur and on click
	handleFetchMoreResults: [Function] function to call to get more results
	handleFocusCallback: [Function] optional callback that lets the parent know focus occurred

	STATE:
	showList: [Bool] whether or not to show dropdown list to select from

	TODO:

	*/

  constructor(props) {
    super(props);

    this.state = {
      showList: false,
    };

    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  handleOnFocus() {
    this.setState({ showList: true });
    if (this.props.handleFocusCallback) {
      this.props.handleFocusCallback();
    }
  }

  handleOnBlur() {
    this.setState({ showList: false });
    this.props.handleInputSelected();
  }

  renderResultsCardBody() {
    if (this.props.results.length > 0) {
      //show infinite scroll HAPPY PATH
      return (
        <InfiniteScroll
          dataLength={this.props.results.length}
          next={this.props.handleFetchMoreResults}
          hasMore={this.props.hasMore}
          loader={<FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
          height={this.props.height || 200}
        >
          <ListGroup variant="flush">
            {this.props.results.map((result) => (
              <ListGroup.Item
                style={{ padding: "10px" }}
                action
                key={result.key}
                onMouseDown={
                  (e) =>
                    e.preventDefault() /*this is to prevent blur from firing first*/
                }
                onClick={(e) => this.props.handleItemClicked(e, result.key)}
              >
                <div>
                  <strong>{result.key}</strong>
                </div>
                <div>{result.name}</div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </InfiniteScroll>
      );
    } else if (this.props.loading) {
      return <FontAwesomeIcon icon={faSpinner} className="fa-spin" />;
    } else {
      return <p>No joy...</p>;
    }
  }

  renderResultListCard() {
    if (this.state.showList) {
      return (
        <Card
          border="dark"
          text="dark"
          style={{ position: "absolute", zIndex: 3, width: "97%" }}
        >
          <Card.Body style={{ padding: "0px" }}>
            {this.renderResultsCardBody()}
          </Card.Body>
        </Card>
      );
    }
  }

  render() {
    let formGroupProps = {};
    if (this.props.asCol) {
      formGroupProps.as = Col;
    }
    return (
      <Form.Group {...formGroupProps}>
        <Form.Label>{this.props.label}</Form.Label>
        <Form.Control
          type="text"
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.props.handleChange}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
          ref={this.props.formControlRef}
        />
        {this.renderResultListCard()}
      </Form.Group>
    );
  }
}

export default SearchListInput;
