let warehouses = [
  {
    id: 1,
    name: "Delray",
  },
  {
    id: 2,
    name: "Stuart",
  },
  {
    id: 3,
    name: "Orlando",
  },
]; //set default, WARNING: DB indexes from 1
const MAX_WAREHOUSE_AGE_IN_SEC = 86400; //24 hours
const LOCAL_STORAGE_WAREHOUSES_KEY = "trinity-warehouses";

/**A warehouse object - maps to location object in trinityAPI
 * @typedef {Object} Warehouse
 * @property {Number} id - INT
 * @property {String} name
 */
/**Fetches warehouses from TrinityAPI
 * @async
 * @throws {Error}
 * @returns {Warehouse[]}
 */
async function fetchWarehouses() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.sessionStorage.getItem("authToken")}`,
    },
    credentials: "include",
  };
  const url = `${process.env.REACT_APP_BASE_URL}/v2/locations?limit=100`;
  const response = await fetch(url, requestOptions);
  if (response.status === 200) {
    const data = await response.json();
    return data?.locations;
  } else {
    throw new Error("Fetching warehouses did not return 200");
  }
}

/**Manages retrieving and caching warehouses
 * @async
 */
async function manageWarehouseCache() {
  const warehouseCache = getWarehouseCacheFromCache();
  try {
    if (
      !warehouseCache?.setAtTime ||
      Date.now() - new Date(warehouseCache.setAtTime).getTime() >
        MAX_WAREHOUSE_AGE_IN_SEC * 1000
    ) {
      const tempWarehouses = await fetchWarehouses();
      setWarehouseCache(tempWarehouses, new Date());
    }
  } catch (error) {
    console.log(error);
  }
}

/**Sets a warehouse cache (localStorage) */
function setWarehouseCache(warehouses, setAtTime) {
  const cacheObject = {
    warehouses: warehouses,
    setAtTime: setAtTime,
  };
  localStorage.setItem(
    LOCAL_STORAGE_WAREHOUSES_KEY,
    JSON.stringify(cacheObject)
  );
  return;
}

/** Gets warehouse cache from cache (localStorage) */
function getWarehouseCacheFromCache() {
  const localWarehouseCache = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_WAREHOUSES_KEY)
  );
  return localWarehouseCache;
}

/**Get warehouses from cache */
function getWarehousesFromCache() {
  const localWarehouseCache = getWarehouseCacheFromCache();
  return localWarehouseCache?.warehouses;
}

function getWarehouses() {
  const cachedWarehouses = getWarehousesFromCache();
  if (cachedWarehouses) {
    //if cache is set then update in memory version to the cached version
    warehouses = cachedWarehouses;
  }
  manageWarehouseCache();
  return warehouses;
}

export default getWarehouses;
